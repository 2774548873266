import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Menu, MenuItem } from 'src/@omnial/_models/navigation/menu.model';
import { environment } from 'src/environments/environment';
import { RepositoryStaticService } from '../repository-static.service';
import { RepositoryService } from '../repository.service';
import { ActivatedRoute } from '@angular/router';


@Injectable()
export class MenuService {
  public megaMenu: BehaviorSubject<Menu> = new BehaviorSubject<Menu>(new Menu());
  private $megaMenu: Menu = null;
  public menuItem: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private $menuItem: string = null;
  private subscriptions: Subscription[] = [];
  private useCache = environment.useCache;

  constructor(
    private repoService: RepositoryService,
    private staticService: RepositoryStaticService,
    private location: Location,
    private route: ActivatedRoute) {
    this.route.fragment.subscribe((fragment: string) => {
      if (fragment && fragment === 'CacheBust') {
        this.useCache = false;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe(); });
    }
  }

  public load(init?: Menu, bypassCache?: boolean): void {
    if (init && !bypassCache) {
      this.$megaMenu = init;
      this.megaMenu.next(this.$megaMenu);
      return;
    }
    if (environment.megaMenu) {
      if (this.useCache && !bypassCache) {
        this.subscriptions.push(this.staticService.get('Menu/Mega').subscribe({
          next: (staticRes) => {
            this.$megaMenu = staticRes as Menu;
            if (!this.$megaMenu) {
              this.subscriptions.push(this.repoService.getData('SevenSpikes/MegaMenu').subscribe({
                next: (apiRes) => {
                  this.$megaMenu = apiRes as Menu;
                  this.megaMenu.next(this.$megaMenu);
                }
              }));
            } else {
              this.megaMenu.next(this.$megaMenu);
            }
          },
          error: () => {
            this.subscriptions.push(this.repoService.getData('SevenSpikes/MegaMenu').subscribe({
              next: (apiRes) => {
                this.$megaMenu = apiRes as Menu;
                this.megaMenu.next(this.$megaMenu);
              }
            }));
          }
        }));
      } else {
        this.subscriptions.push(this.repoService.getData('SevenSpikes/MegaMenu').subscribe({
          next: (apiRes) => {
            this.$megaMenu = apiRes as Menu;
            this.megaMenu.next(this.$megaMenu);
          }
        }));
      }
    }
    else {
      if (this.useCache && !bypassCache) {
        this.subscriptions.push(this.staticService.getById('Menu/Mega', environment.rootCatId).subscribe({
          next: (staticRes) => {
            this.$megaMenu = staticRes as Menu;
            if (!this.$megaMenu) {
              this.subscriptions.push(this.repoService.getData(`Menu/Mega`).subscribe({
                next: (apiRes) => {
                  this.$megaMenu = apiRes as Menu;
                  this.megaMenu.next(this.$megaMenu);
                }
              }));
            } else {
              this.megaMenu.next(this.$megaMenu);
            }
          },
          error: () => {
            this.subscriptions.push(this.repoService.getData(`Menu/Mega`).subscribe({
              next: (apiRes) => {
                this.$megaMenu = apiRes as Menu;
                this.megaMenu.next(this.$megaMenu);
              }
            }));
          }
        }));
      } else {
        this.subscriptions.push(this.repoService.getData(`Menu/Mega`).subscribe({
          next: (apiRes) => {
            this.$megaMenu = apiRes as Menu;
            this.megaMenu.next(this.$megaMenu);
          }
        }));
      }
    }
  }

  public expandActiveSubMenu(): void {
    this.closeAllSubMenus();
    const url = this.location.path();
    let routerLink = decodeURIComponent(url);
    if (!url || !routerLink || !this.$megaMenu || routerLink === '/') {
      return
    }
    let activeMenuItem = this.$megaMenu.items.find(item => routerLink == item.link);
    if (!activeMenuItem) {
      activeMenuItem = this.checkMegaChild(routerLink, this.$megaMenu.items);
    }
    if (activeMenuItem) {
      this.openMenuItem(activeMenuItem.id);
    } else { // Look up one level in case we are at a Product page
      if (this.location.path()) {
        const parentArray = this.location.path().split('/');
        parentArray.pop();
        routerLink = parentArray.join('/');
        let activeMenuItem = this.$megaMenu.items.find(item => routerLink == item.link);
        if (!activeMenuItem) {
          activeMenuItem = this.checkMegaChild(routerLink, this.$megaMenu.items);
        }
        if (activeMenuItem) {
          this.openMenuItem(activeMenuItem.id);
        }
      }
    }
  }

  private checkMegaChild(routerLink: string, parents: MenuItem[]): MenuItem {
    const activeParent = parents.find(item => routerLink.startsWith(item.link));
    if (activeParent) {
      this.openMenuItem(activeParent.id);
      if (activeParent.children) {
        const activeMenuItem = activeParent.children.find(item => routerLink === item.link);
        if (!activeMenuItem) {
          return this.checkMegaChild(routerLink, activeParent.children)
        } else {
          return activeMenuItem;
        }
      } else return null;
    } else {
      return null;
    }
  }

  public openMenuItem(menuId: string): void {
    const menuItem = document.getElementById('menu-item-' + menuId);
    const subMenu = document.getElementById('sub-menu-' + menuId);
    if (subMenu) {
      subMenu.classList.add('show');
      menuItem.classList.add('expanded');
    }
  }

  public toggleMenuItem(menuId: string): void {
    this.$menuItem = menuId;
    const menuItem = document.getElementById('menu-item-' + menuId);
    const subMenu = document.getElementById('sub-menu-' + menuId);
    if (subMenu) {
      if (subMenu.classList.contains('show')) {
        subMenu.classList.remove('show');
        menuItem.classList.remove('expanded');
        this.$menuItem = null;
      } else {
        subMenu.classList.add('show');
        menuItem.classList.add('expanded');
        this.menuItem.next(this.$menuItem);
      }
    }
    this.menuItem.next(this.$menuItem);
  }

  public closeOtherSubMenus(menu: Array<MenuItem>, menuId: string): void {
    const currentMenuItem = menu.filter(item => item.id === menuId)[0];
    menu.forEach(item => {
      if (item && currentMenuItem) {
        if ((item.id !== menuId && item.parentId === currentMenuItem.parentId) ||
          (currentMenuItem.parentId === 'root' && item.id !== menuId)) {
          const subMenu = document.getElementById('sub-menu-' + item.id);
          const menuItem = document.getElementById('menu-item-' + item.id);
          if (subMenu) {
            if (subMenu.classList.contains('show')) {
              subMenu.classList.remove('show');
              menuItem.classList.remove('expanded');
            }
          }
        }
      }
    });
  }

  public closeAllSubMenus(): void {
    this.$megaMenu?.items.forEach(item => {
      const subMenu = document.getElementById('sub-menu-' + item.id);
      const menuItem = document.getElementById('menu-item-' + item.id);
      if (subMenu) {
        if (subMenu.classList.contains('show')) {
          subMenu.classList.remove('show');
          menuItem.classList.remove('expanded');
        }
      }
    });
  }
}
