import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialBaseModule } from 'src/@omnial/material.base.module';
import { RatingModule } from 'src/@omnial/shared/rating/rating.module';
import { ControlsDetailModule } from 'src/@omnial/product-controls/controls-detail/controls-detail.module';
import { ControlsMiniModule } from 'src/@omnial/product-controls/controls-mini/controls-mini.module';
import { BreadcrumbModule } from 'src/@omnial/navigation/breadcrumbs/breadcrumb.module';
import { ProductReviewModule } from 'src/@omnial/shared/product-review/product-review.module';
import { MaterialExtendModule } from 'src/@omnial/material.extend.module';
import { PipesModule } from 'src/@omnial/_pipes/pipes.module';
import { DealerLocatorModule } from 'src/@omnial/shared/dealer-locator/dealer-locator.module';
import { ProductSwiperModule } from 'src/@omnial/pages/product/product-swiper/product-swiper.module';
import { BannersStaticModule } from 'src/@omnial/shared/banners-static/banners-static.module';
import { BannersModule } from 'src/@omnial/shared/banners/banners.module';
import { ProductDetailComponent } from './product-detail.component';
import { TopicContentModule } from 'src/@omnial/shared/topic-content/topic-content.module';
import { ProductReviewSubmitModule } from 'src/@omnial/shared/product-review-submit/product-review-submit.module';
import { ProductReviewSubmitDialogModule } from 'src/@omnial/shared/product-review-submit-dialog/product-review-submit-dialog.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialBaseModule,
    MaterialExtendModule,
    ControlsDetailModule,
    ControlsMiniModule,
    BreadcrumbModule,
    RatingModule,
    BannersModule,
    BannersStaticModule,
    ProductReviewModule,
    ProductReviewSubmitModule,
    ProductReviewSubmitDialogModule,
    PipesModule,
    DealerLocatorModule,
    ProductSwiperModule,
    TopicContentModule
  ],
  declarations: [ProductDetailComponent],
  exports: [ProductDetailComponent]
})
export class ProductDetailModule { }
