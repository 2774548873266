import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { RepositoryService } from 'src/@omnial/_services/repository.service';
import { AfterpayConfig, AfterpayLimits } from 'src/@omnial/_models/external/afterpay-config.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RepositoryStaticService } from 'src/@omnial/_services/repository-static.service';
import { Customer } from 'src/@omnial/_models/customer/customer.model';
import { PaymentResult } from 'src/@omnial/_models/order/payment-result.model';
import { CustomerService } from 'src/@omnial/_services/customer/customer.service';
import { KlaviyoService } from 'src/@omnial/_services/external/klaviyo.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AfterpayService implements OnDestroy {
  private customer: Customer;
  public limits: BehaviorSubject<AfterpayLimits> = new BehaviorSubject<AfterpayLimits>(null);
  private $limits: AfterpayLimits = null;
  private subscriptions: Subscription[] = [];

  afterpayLimits = new Observable<AfterpayLimits>((observer) => {
    if (environment.useCache) {
      this.subscriptions.push(this.staticService.get("Checkout/AfterpayLimits").subscribe({
        next: (staticRes) => {
          const topic = staticRes as AfterpayLimits;
          if (topic) {
            observer.next(topic);
            observer.complete();
          } else {
            this.subscriptions.push(this.repoService.getData(`PaymentAfterpay/Limits`).subscribe({
              next: (apiRes) => {
                observer.next(apiRes as AfterpayLimits);
                observer.complete();
              },
              error: (msg) => {
                observer.error(msg);
                observer.complete();
              }
            }));
          }
        },
        error: () => {
          this.subscriptions.push(this.repoService.getData(`PaymentAfterpay/Limits`).subscribe({
            next: (apiRes) => {
              observer.next(apiRes as AfterpayLimits);
              observer.complete();
            },
            error: (msg) => {
              observer.error(msg);
              observer.complete();
            }
          }));
        }
      }));
    } else {
      this.repoService.getData(`PaymentAfterpay/Limits`).subscribe({
        next: (res) => {
          const limits = res as AfterpayLimits;
          observer.next(limits);
          observer.complete();
        },
        error: (msg) => {
          observer.error(msg);
          observer.complete();
        }
      });
    }
  });

  afterpayActive = new Observable<AfterpayConfig>((observer) => {
    this.repoService.getData(`PaymentAfterpay/Active/${this.customer.customerGuid}`).subscribe({
      next: (res) => {
        const config = res as AfterpayConfig;
        observer.next(config);
        observer.complete();
      },
      error: (msg) => {
        observer.error(msg);
        observer.complete();
      }
    });
  });

  afterpayToken = new Observable<AfterpayConfig>((observer) => {
    this.repoService.getData(`PaymentAfterpay/Token/${this.customer.customerGuid}`).subscribe({
      next: (res) => {
        observer.next(res as AfterpayConfig);
        observer.complete();
      },
      error: (msg) => {
        observer.error(msg);
        observer.complete();
      }
    });
  });

  constructor(
    public staticService: RepositoryStaticService,
    public repoService: RepositoryService,
    public customerService: CustomerService,
    public snackBar: MatSnackBar,
    public klaviyoService: KlaviyoService) {
    this.customerService.customer.subscribe({ next: (res) => { this.customer = res as Customer; } });
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe(); });
    }
  }

  public load(init?: AfterpayLimits): void {
    if (init) {
      this.$limits = init;
      this.limits.next(this.$limits);
      return;
    }
    if (this.$limits) {
      this.limits.next(this.$limits);
    } else {
      if (environment.useCache) {
        this.subscriptions.push(this.staticService.get("Checkout/AfterpayLimits").subscribe({
          next: (staticRes) => {
            const afterpayLimits = staticRes as AfterpayLimits;
            if (afterpayLimits) {
              this.$limits = staticRes as AfterpayLimits;
              this.limits.next(staticRes as AfterpayLimits);
            } else {
              this.subscriptions.push(this.repoService.getData(`PaymentAfterpay/Limits`).subscribe({
                next: (apiRes) => {
                  this.$limits = apiRes as AfterpayLimits;
                  this.limits.next(apiRes as AfterpayLimits);
                }
              }));
            }
          },
          error: () => {
            this.subscriptions.push(this.repoService.getData(`PaymentAfterpay/Limits`).subscribe({
              next: (apiRes) => {
                this.$limits = apiRes as AfterpayLimits;
                this.limits.next(apiRes as AfterpayLimits);
              }
            }));
          }
        }));
      } else {
        this.repoService.getData(`PaymentAfterpay/Limits`).subscribe({
          next: (res) => {
            this.$limits = res as AfterpayLimits;
            this.limits.next(res as AfterpayLimits);
          },
          error: () => {
            this.$limits = null;
            this.limits.next(null);
          }
        });
      }
    }
  }

  public afterpayCapture(orderToken: string): Observable<PaymentResult> {
    return new Observable((observer) => {
      this.repoService.create(`PaymentAfterpay/Capture/${this.customer.customerGuid}`, JSON.stringify(orderToken)).subscribe({
        next: (res) => {
          const result = res as PaymentResult;
          if (result.success && result.order) {
            this.klaviyoService.placedOrder(result.order);
          }
          observer.next(result);
          observer.complete();
        },
        error: (msg) => {
          observer.error(msg);
          observer.complete();
        }
      });
    });
  }
}
