import { State, Country } from "../order/country.model";

export class KlaviyoAddToCartInformation {
    $value: number;
    AddedItemProductName: string;
    AddedItemProductID: number;
    AddedItemSKU: string;
    AddedItemCategories: string[];
    AddedItemImageURL: string;
    AddedItemURL: string;
    AddedItemPrice: number;
    AddedItemQuantity: number;
    ItemNames: string[];
    CheckoutURL: string;
    Items: string[];
}

export class KlaviyoCustomer {
    $email: string;
    $first_name: string;
    $last_name: string;
    $phone_number: string;
    $address1: string;
    $address2: string;
    $city: string;
    $region: State;
    $country: Country;
    $organization: string;
    $zip: string;
}

export class KlaviyoStartedCheckout {
    $event_id: number;
    $value: number;
    ItemNames: string[];
    CheckoutURL: string;
    Categories: string[];
    Items: string[];
}

export class KlaviyoTrackViewedItem {
    Title: string;
    ItemId: number;
    Categories: string[];
    ImageUrl: string;
    Url: string;
    Metadata: KlaviyoTrackViewedItemMeta;
}

export class KlaviyoTrackViewedItemMeta {
    Brand: string;
    Price: number;
    CompareAtPrice: number;
}

export class KlaviyoProductDetails {
    ProductName: string;
    ProductID: number;
    Categories: string[];
    ImageURL: string;
    URL: string;
    Brand: string;
    Price: number;
    CompareAtPrice: number;
}

export class KlaviyoCartItem {
    ProductID: string;
    SKU: string;
    ProductName: string;
    Quantity: number;
    ItemPrice: number;
    RowTotal: number;
    ProductURL: string;
    ImageURL: string;
    Categories: string[];
    Brand: string;
}
