import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { SiteMapItem } from 'src/@omnial/_models/navigation/site-map.model';
import { BreadCrumbService } from 'src/@omnial/_services/navigation/breadcrumb.service';
import { SiteMapService } from 'src/@omnial/_services/navigation/sitemap.service';
import { Subscription } from 'rxjs';
import { fadeInLeftAnimation } from 'src/@omnial/_animations/fade-in-left.animation';
import { fadeInRightAnimation } from 'src/@omnial/_animations/fade-in-right.animation';
import { fadeInUpAnimation } from 'src/@omnial/_animations/fade-in-up.animation';
import { scaleInAnimation } from 'src/@omnial/_animations/scale-in.animation';
import { AppSettings } from 'src/app/app.settings';


@Component({
  selector: 'app-breadcrumb',
  templateUrl: './../../../app/navigation/breadcrumbs/breadcrumb.component.html',
  styleUrls: ['./../../../app/navigation/breadcrumbs/breadcrumb.component.scss'],
  providers: [SiteMapService],
  animations: [fadeInUpAnimation, fadeInLeftAnimation, fadeInRightAnimation, scaleInAnimation],
})
export class BreadCrumbComponent implements OnInit, OnDestroy {
  @Input() breadcrumbItems: SiteMapItem[];
  public localEndNode: string = null;
  public breadcrumbs: SiteMapItem[] = [];
  public subscriptions: Subscription[] = [];

  constructor(
    public router: Router,
    public location: Location,
    public sitemapService: SiteMapService,
    public breadCrumbService: BreadCrumbService,
    public settings: AppSettings) {
    this.subscriptions.push(this.breadCrumbService.currentEndNode.subscribe(endNode => this.localEndNode = endNode));
    this.subscriptions.push(this.router.events.subscribe(
      event => {
        if (event instanceof NavigationEnd) {
          this.getCrumbs(event.url);
        }
      }));
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe(); });
    }
  }

  ngOnInit(): void {
    this.getCrumbs(this.router.url);
  }

  getCrumbs(url: string): void {
    if (url?.indexOf("sign-in") != -1) {
      this.accountCrumbs(url);
    } else if (url?.indexOf("account") != -1) {
      this.accountCrumbs(url);
    } else if (url?.indexOf("passwordrecovery") != -1) {
      this.accountCrumbs(url);
    } else {
      this.breadcrumbs = [];
      this.doCrumbs();
    }
  }

  doCrumbs(): void {
    if (this.breadcrumbItems) {
      let navItem = this.breadcrumbItems.find(i => i.link === this.location.path() || i.routerLink === this.location.path());
      if (!navItem && this.location.path()) { // Could be a product so check the parent
        const parentArray = this.location.path().split('/');
        parentArray.pop();
        const parentPath = parentArray.join('/');
        navItem = this.breadcrumbItems.find(i => (i.link  && i.link.endsWith(parentPath) || (i.routerLink && i.routerLink.endsWith(parentPath))));
        if (navItem) {
          this.breadcrumbs.unshift(navItem);
        }
      }
      if (navItem) {
        this.findParent(navItem);
      }
    }
  }

  findParent(navItem: SiteMapItem): void {
    const parent = this.breadcrumbItems.find(i => i.id === navItem.parentId);
    if (parent) {
      this.breadcrumbs.unshift(parent);
      this.findParent(parent);
    }
  }

  accountCrumbs(url: string) {
    this.breadcrumbs = [];
    this.localEndNode = null;
    const accountCrumbs = new SiteMapItem();
    accountCrumbs.link = '/account';
    accountCrumbs.href = '/account';
    accountCrumbs.slug = 'account';
    accountCrumbs.title = this.settings.accountTitle;
    this.breadcrumbs.push(accountCrumbs);
    this.settings.accountLinks.forEach((section => {
      if (section?.children) {
        section.children.forEach((link => {
          if (url === link.routerLink) {
            this.localEndNode = link.name;
          }
        }));
      }
    }));
  }

  public closeSubMenus(): void {
    if (window.innerWidth < 960) {
      this.sitemapService.closeAllSubMenus();
    }
  }
}
