import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { StoreSettings } from "src/@omnial/_models/settings.model";
import { RepositoryStaticService } from "../repository-static.service";
import { RepositoryService } from "../repository.service";
import { environment } from "src/environments/environment";

@Injectable()
export class StoreService implements OnDestroy {
  public storeSettings: BehaviorSubject<StoreSettings> = new BehaviorSubject<StoreSettings>(null);
  private $storeSettings: StoreSettings = null;
  private subscriptions: Subscription[] = [];
  private useCache = environment.useCache;

  constructor(public staticService: RepositoryStaticService,
    public repoService: RepositoryService) { }

  public load(init?: StoreSettings): void {
    if (init) {
      this.$storeSettings = init;
      this.storeSettings.next(this.$storeSettings);
      return;
    }
    if (!this.$storeSettings) {
      this.subscriptions.push(this.getStoreSettings().subscribe({
        next: (res: StoreSettings) => {
          this.$storeSettings = res;
          this.storeSettings.next(this.$storeSettings);
        },
        error: () => {
          this.$storeSettings = null;
          this.storeSettings.next(this.$storeSettings);
        }
      }));
    } else {
      this.storeSettings.next(this.$storeSettings);
    }
  }

  public getStoreSettings(bypassCache?: boolean): Observable<StoreSettings> {
    return new Observable((observer) => {
      if (this.useCache && !bypassCache) {
        this.subscriptions.push(this.staticService.get('Store').subscribe({
          next: (staticRes) => {
            const settings = staticRes as StoreSettings;
            if (settings) {
              observer.next(settings);
              observer.complete();
            } else {
              this.subscriptions.push(this.repoService.getData(`Store`).subscribe({
                next: (res) => {
                  observer.next(res as StoreSettings);
                  observer.complete();
                },
                error: (e) => {
                  observer.error(e);
                  observer.complete();
                }
              }));
            }
          },
          error: () => {
            this.subscriptions.push(this.repoService.getData(`Store`).subscribe({
              next: (res) => {
                observer.next(res as StoreSettings);
                observer.complete();
              },
              error: (e) => {
                observer.error(e);
                observer.complete();
              }
            }));
          }
        }));
      } else {
        this.subscriptions.push(this.repoService.getData(`Store`).subscribe({
          next: (res) => {
            observer.next(res as StoreSettings);
            observer.complete();
          },
          error: (e) => {
            observer.error(e);
            observer.complete();
          }
        }));
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe() });
    }
  }
}
