import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { PayPalConfig } from 'src/@omnial/_models/external/paypal.config.model';
import { ScriptExt } from 'src/@omnial/_models/scripts.ext.model';
import { environment } from 'src/environments/environment';

@Injectable()
export class ScriptLoaderService {

  private $scripts: ScriptExt[] = [
    { ref: 'Afterpay Sandbox', source: 'https://portal.sandbox.afterpay.com/afterpay.js', id: 'afterPayScriptTag', loaded: false , loading: false },
    { ref: 'Afterpay Live', source: 'https://portal.afterpay.com/afterpay.js', id: 'afterPayScriptTag', loaded: false , loading: false },
    { ref: 'SecurePay Sandbox', source: 'https://payments-stest.npe.auspost.zone/v3/ui/client/securepay-ui.min.js', id: 'securePayUIScriptTag', loaded: false , loading: false },
    { ref: 'SecurePay 3DS Sandbox', source: 'https://test.api.securepay.com.au/threeds-js/securepay-threeds.js', id: 'securePay3DSScriptTag', loaded: false , loading: false },
    { ref: 'SecurePay Live', source: 'https://payments.auspost.net.au/v3/ui/client/securepay-ui.min.js', id: 'securePayUIScriptTag', loaded: false , loading: false },
    { ref: 'SecurePay 3DS Live', source: 'https://api.securepay.com.au/threeds-js/securepay-threeds.js', id: 'securePay3DSScriptTag', loaded: false , loading: false },
    { ref: 'eWay Encrypt', source: 'https://secure.ewaypayments.com/scripts/eCrypt.min.js', id: 'eWayEncryptScriptTag', loaded: false , loading: false },
    { ref: 'Klayvio', source: `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${environment.klaviyoApiKey}`, id: 'klayvioScriptTagScriptTag', loaded: false , loading: false },
    { ref: 'PayPal', source: 'https://www.paypal.com/sdk/js', id: 'payPalScriptTag', loaded: false , loading: false },
    { ref: 'Stripe', source: 'https://js.stripe.com/v3/', id: 'stripeScriptTag', loaded: false , loading: false },
    { ref: 'Google Maps', source: 'https://maps.googleapis.com/maps/api/js?v=weekly&key=AIzaSyAsZFwrsLRvS-BSsGP7rTudul-OaoOEKXk&libraries=places&language=en&callback=googleMapsLoaded', id: 'mapsScriptTag', loaded: false , loading: false },
    { ref: 'Google Maps Cluster', source: 'https://unpkg.com/@googlemaps/markerclustererplus/dist/index.min.js', id: 'mapsClusterScriptTag', loaded: false , loading: false },
    { ref: 'Youtube', source: 'https://www.youtube.com/iframe_api', id: 'youtubeScriptTag', loaded: false , loading: false },
    { ref: 'Facebook', source: 'https://connect.facebook.net/en_US/sdk.js', id: 'facebookScriptTag', loaded: false , loading: false }
  ];
  public scripts: BehaviorSubject<ScriptExt[]> = new BehaviorSubject<ScriptExt[]>(null);
  payPalConfig: PayPalConfig;

  constructor(public snackBar: MatSnackBar) { }

  public load(ref: string, config?: any): void {
    const script = this.$scripts.find(s => s.ref === ref);
    if (!script)  {
      return;
    }
    if (script && config && ref === 'PayPal') {
      script.source = this.addPayPalParameters(script.source, config);
    }
    // Complete if already loaded or loading
    if (script?.loading) {
      this.scripts?.next(this.$scripts);
      return;
    }
    script.loading = true;
    if (script && script.loaded) {
      this.scripts.next(this.$scripts);
    }
    else if (script) {
      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.src = script.source;
      scriptElement.id = script.id;
      document.getElementsByTagName('body')[0].appendChild(scriptElement);
      scriptElement.onload = () => {
        script.loading = false;
        script.loaded = true;
        this.scripts.next(this.$scripts);
      };
      scriptElement.onerror = (error: any) => {
        // Couldn't load it
        console.log(error);
        script.loading = false;
        script.loaded = false;
        const errMessage = `Sorry there was a problem loading the ${ref} script.`;
        this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 9000 });
        this.scripts.next(this.$scripts);
      };
    } else {
      // Script ref is wrong
      const errMessage = `Sorry there was a problem loading the ${ref} script.`;
      script.loading = false;
      script.loaded = false;
      this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 9000 });
      this.scripts.next(this.$scripts);
    }
  }

  public addPayPalParameters(source: string, config: PayPalConfig): string {
    return source += `?client-id=${config.clientId}&intent=${config?.intent?.toLowerCase()}&currency=${config?.currency}`;
  }

  public addSourceParameters(source: string, config: any): string {
    const hyphenize = (camel: string) => {
      return camel.replace(/([A-Z])/g, $1 => {
        return '-' + $1.toLowerCase();
      });
    };
    return Object.keys(config).reduce((url, token, idx) => {
      return url + (idx && '&' || '') + hyphenize(token) + '=' + config[token];
    }, `${source}?`);
  }
}
