import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subscription } from 'rxjs';
import { RepositoryService } from '../repository.service';
import { RepositoryStaticService } from '../repository-static.service';
import { ProductCategoryService } from './product-category.service';
import { ProductManufacturerService } from './product-manufacturer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Category } from 'src/@omnial/_models/catalog/category.model';
import { Manufacturer } from 'src/@omnial/_models/catalog/manufacturer.model';
import { Product, ProductDetail } from 'src/@omnial/_models/catalog/product.model';
import { Customer } from 'src/@omnial/_models/customer/customer.model';
import { environment } from 'src/environments/environment';
import { KlaviyoService } from '../external/klaviyo.service';
import { ProductsPaged } from 'src/@omnial/_models/catalog/products.paged.model';

@Injectable()
export class CatalogService implements OnDestroy {
  public customer: Customer = null;
  public categoryPageIndex = 0;
  public currentCategory: string;
  public manufacturerPageIndex = 0;
  public currentManufacturer: string;
  private subscriptions: Subscription[] = [];
  private useCache = environment.useCache;

  constructor(
    public staticService: RepositoryStaticService,
    public repoService: RepositoryService,
    public snackBar: MatSnackBar,
    public klaviyoService: KlaviyoService,
    public router: Router,
    public productCategoryService: ProductCategoryService,
    public productManufacturerService: ProductManufacturerService,
    private route: ActivatedRoute) {
    this.route.fragment.subscribe((fragment: string) => {
      if (fragment && fragment === 'CacheBust') {
        this.useCache = false;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe() });
    }
  }

  public getProduct(id: number, bypassCache?: boolean): Observable<Product> {
    return new Observable((observer) => {
      if (this.useCache && !bypassCache) {
        let product: Product = null;
        this.subscriptions.push(this.staticService.getById('Product', id).subscribe({
          next: (staticRes) => {
            product = staticRes as Product;
            if (product) {
              observer.next(staticRes as Product);
              observer.complete();
            } else {
              this.subscriptions.push(this.repoService.getData(`Product/${id}`).subscribe({
                next: (apiRes) => {
                  observer.next(apiRes as Product);
                  observer.complete();
                },
                error: (e) => {
                  const errMessage = 'Sorry we could get the product. Please try again later or hit refresh.';
                  this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 3000 });
                  observer.error(e);
                  observer.complete();
                }
              }));
            }
          },
          error: (e) => {
            this.subscriptions.push(this.repoService.getData(`Product/${id}`).subscribe({
              next: (apiRes) => {
                observer.next(apiRes as Product);
                observer.complete();
              },
              error: (e) => {
                const errMessage = 'Sorry we could get the product. Please try again later or hit refresh.';
                this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 3000 });
                observer.error(e);
                observer.complete();
              }
            }));
          }
        }));
      } else {
        this.subscriptions.push(this.repoService.getData(`Product/${id}`).subscribe({
          next: (res) => {
            observer.next(res as Product);
            observer.complete();
          },
          error: (e) => {
            const errMessage = 'Sorry we could get the product. Please try again later or hit refresh.';
            this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 3000 });
            observer.error(e);
            observer.complete();
          }
        }));
      }
    });
  }

  public getProductDetail(id: number, bypassCache?: boolean): Observable<ProductDetail> {
    return new Observable((observer) => {
      if (this.useCache && !bypassCache) {
        this.subscriptions.push(this.staticService.getById('Product', id).subscribe({
          next: (staticRes) => {
            if (staticRes) {
              this.klaviyoService.viewProduct(staticRes as ProductDetail, this.router.url);
              observer.next(staticRes as ProductDetail);
              observer.complete();
            } else {
              this.subscriptions.push(this.repoService.getData(`Product/${id}`).subscribe({
                next: (apiRes) => {
                  if (apiRes) {
                    this.klaviyoService.viewProduct(apiRes as ProductDetail, this.router.url);
                    observer.next(apiRes as ProductDetail);
                    observer.complete();
                  } else {
                    const errMessage = 'Sorry we could get the product. Please try again later or hit refresh.';
                    this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 3000 });
                    observer.error(null);
                    observer.complete();
                  }
                },
                error: (e) => {
                  const errMessage = 'Sorry we could get the product. Please try again later or hit refresh.';
                  this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 3000 });
                  observer.error(e);
                  observer.complete();
                }
              }));
            }
          },
          error: (e) => {
            this.subscriptions.push(this.repoService.getData(`Product/${id}`).subscribe({
              next: (apiRes) => {
                this.klaviyoService.viewProduct(apiRes as ProductDetail, this.router.url);
                observer.next(apiRes as ProductDetail);
                observer.complete();
              },
              error: (e) => {
                const errMessage = 'Sorry we could get the product. Please try again later or hit refresh.';
                this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 3000 });
                observer.error(e);
                observer.complete();
              }
            }));
          }
        }));
      } else {
        this.subscriptions.push(this.repoService.getData(`Product/${id}`).subscribe({
          next: (res) => {
            this.klaviyoService.viewProduct(res as ProductDetail, this.router.url);
            observer.next(res as ProductDetail);
            observer.complete();
          },
          error: (e) => {
            const errMessage = 'Sorry we could get the product. Please try again later or hit refresh.';
            this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 3000 });
            observer.error(e);
            observer.complete();
          }
        }));
      }
    });
  }

  public getRelatedProducts(id: number, bypassCache?: boolean): Observable<Product[]> {
    return new Observable((observer) => {
      if (this.useCache && !bypassCache) {
        let related: Product[] = null;
        this.subscriptions.push(this.staticService.getById('Product/Related', id).subscribe({
          next: (staticRes) => {
            related = staticRes as Product[];
            if (related) {
              observer.next(staticRes as Product[]);
              observer.complete();
            } else {
              this.subscriptions.push(this.repoService.getData(`Product/Related/${id}`).subscribe({
                next: (apiRes) => {
                  observer.next(apiRes as Product[]);
                  observer.complete();
                },
                error: (e) => {
                  observer.error(e);
                  observer.complete();
                }
              }));
            }
          },
          error: (e) => {
            this.subscriptions.push(this.repoService.getData(`Product/Related/${id}`).subscribe({
              next: (apiRes) => {
                observer.next(apiRes as Product[]);
                observer.complete();
              },
              error: (e) => {
                observer.error(e);
                observer.complete();
              }
            }));
          }
        }));
      } else {
        this.subscriptions.push(this.repoService.getData(`Product/Related/${id}`).subscribe({
          next: (apiRes) => {
            observer.next(apiRes as Product[]);
            observer.complete();
          },
          error: (e) => {
            observer.error(e);
            observer.complete();
          }
        }));
      }
    });
  }

  public getCategoryProducts(category: Category) {
    this.currentCategory = category?.seName;
    this.categoryPageIndex = 0;
    this.productCategoryService.replaceProducts(null);
    this.getCategoryProductsRecursive(category);
  }

  private getCategoryProductsRecursive(category: Category) {
    if (!category || this.currentCategory !== category.seName) { // To stop recursive calls no longer needed
      return;
    }
    if (this.categoryPageIndex * environment.pageSize > category.products) {
      if (this.subscriptions && this.subscriptions.length > 0) {
        this.subscriptions.forEach((sub) => {
          sub.unsubscribe();
        });
      }
    } else {
      const subscription = this.getCategoryProductsPaged(category.seName, this.categoryPageIndex, environment.pageSize).subscribe({
        next: (res) => {
          if (this.currentCategory === category?.seName) { // To stop recursive calls no longer needed
            const page = new ProductsPaged();
            page.products = res as Product[];
            page.pageIndex = this.categoryPageIndex;
            page.pageSize = environment.pageSize;
            page.lastPage = (this.categoryPageIndex + 1) * environment.pageSize > category.products - 1;
            page.seName = category.seName;
            page.visible = page.pageIndex === 0;
            this.productCategoryService.replaceProducts(page);
            try {
              subscription.unsubscribe();
            } catch (e) {
              // Nothing - Not initialised - TODO - Deal with this.
            }
            this.categoryPageIndex++;
            this.getCategoryProductsRecursive(category);
          }
        }
      });
    }
  }

  private getCategoryProductsPaged(seName: string, pageIndex: number, pageSize: number, bypassCache?: boolean): Observable<Product[]> {
    return new Observable((observer) => {
      if (this.useCache && !bypassCache) {
        this.subscriptions.push(this.staticService.getProducts('Category', seName, pageIndex, pageSize).subscribe({
          next: (staticRes) => {
            const pagedProducts = staticRes as Product[];
            if (pagedProducts) {
              observer.next(pagedProducts);
              observer.complete();
            } else {
              this.subscriptions.push(this.repoService.getData(`Category/${seName}/${pageIndex}/${pageSize}`).subscribe({
                next: (apiRes) => {
                  observer.next(apiRes as Product[]);
                  observer.complete();
                }
              }));
            }
          },
          error: (e) => {
            this.subscriptions.push(this.repoService.getData(`Category/${seName}/${pageIndex}/${pageSize}`).subscribe({
              next: (apiRes) => {
                observer.next(apiRes as Product[]);
                observer.complete();
              }
            }));
          }
        }));
      } else {
        this.subscriptions.push(this.repoService.getData(`Category/${seName}/${this.categoryPageIndex}/${environment.pageSize}`).subscribe({
          next: (apiRes) => {
            observer.next(apiRes as Product[]);
            observer.complete();
          }
        }));
      }
    });
  }

  public getManufacturerProducts(manufacturer: Manufacturer) {
    this.categoryPageIndex = 0;
    this.productManufacturerService.replaceProducts(null);
    this.currentManufacturer = manufacturer?.seName;
    this.getManufacturerProductsRecursive(manufacturer);
  }

  private getManufacturerProductsRecursive(manufacturer: Manufacturer) {
    if (!manufacturer || this.currentManufacturer !== manufacturer.seName) { // To stop recursive calls no longer needed
      return;
    }
    if (this.categoryPageIndex * environment.pageSize > manufacturer.products) {
      if (this.subscriptions && this.subscriptions.length > 0) {
        this.subscriptions.forEach((sub) => {
          sub.unsubscribe();
        });
      }
    } else {
      const subscription = this.getManufacturerProductsPaged(manufacturer.seName, this.categoryPageIndex, environment.pageSize).subscribe({
        next: (res) => {
          if (this.currentManufacturer === manufacturer?.seName) { // To stop recursive calls no longer needed
            const page = new ProductsPaged();
            page.products = res as Product[];
            page.pageIndex = this.categoryPageIndex;
            page.pageSize = environment.pageSize;
            page.lastPage = (this.categoryPageIndex + 1) * environment.pageSize > manufacturer.products - 1;
            page.seName = manufacturer.seName;
            page.visible = page.pageIndex === 0;
            this.productManufacturerService.replaceProducts(page);
            subscription.unsubscribe();
            this.categoryPageIndex++;
            this.getManufacturerProductsRecursive(manufacturer);
          }
        }
      });
    }
  }

  private getManufacturerProductsPaged(seName: string, pageIndex: number, pageSize: number, bypassCache?: boolean): Observable<Product[]> {
    return new Observable((observer) => {
      if (this.useCache && !bypassCache) {
        this.subscriptions.push(this.staticService.getProducts('Manufacturer', seName, pageIndex, pageSize).subscribe({
          next: (staticRes) => {
            const pagedProducts = staticRes as Product[];
            if (pagedProducts) {
              observer.next(pagedProducts);
              observer.complete();
            } else {
              this.subscriptions.push(this.repoService.getData(`Manufacturer/${seName}/${pageIndex}/${pageSize}`).subscribe({
                next: (apiRes) => {
                  observer.next(apiRes as Product[]);
                  observer.complete();
                }
              }));
            }
          },
          error: (e) => {
            this.subscriptions.push(this.repoService.getData(`Manufacturer/${seName}/${pageIndex}/${pageSize}`).subscribe({
              next: (apiRes) => {
                observer.next(apiRes as Product[]);
                observer.complete();
              }
            }));
          }
        }));
      } else {
        this.subscriptions.push(this.repoService.getData(`Manufacturer/${seName}/${this.categoryPageIndex}/${environment.pageSize}`).subscribe({
          next: (apiRes) => {
            observer.next(apiRes as Product[]);
            observer.complete();
          }
        }));
      }
    });
  }
}
