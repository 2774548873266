import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductReviewSubmitDialogComponent } from './product-review-submit-dialog.component';
import { RouterModule } from '@angular/router';
import { MaterialBaseModule } from 'src/@omnial/material.base.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RatingModule } from '../rating/rating.module';
import { MaterialExtendModule } from 'src/@omnial/material.extend.module';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialBaseModule,
    MaterialExtendModule,
    ReactiveFormsModule,
    RatingModule
  ],
  declarations: [ProductReviewSubmitDialogComponent],
  exports: [ProductReviewSubmitDialogComponent]
})
export class ProductReviewSubmitDialogModule { }
