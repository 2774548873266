import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { AppSettings } from 'src/app/app.settings';

@Injectable()
export class VersionCheckService {
  private subscriptions: Subscription[] = [];
  // this will be replaced by actual hash post-build.js
  private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';

  constructor(private http: HttpClient, private toastr: ToastrService, private settings: AppSettings) { }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe(); });
    }
  }

  public initVersionCheck(url, frequency = 1000 * 60 * environment.versionCheck): void {
    setInterval(() => { this.checkVersion(url); }, frequency);
  }

  private checkVersion(url): void {
    // timestamp these requests to invalidate caches
    this.http.get(url + '?t=' + new Date().getTime()).subscribe({
      next: (response: any) => {
        const hash = response.hash;
        const hashChanged = this.hasHashChanged(this.currentHash, hash);
        if (hashChanged) {
          const message = `This site has updated since you last visited. Tap this message to reload the page and ensure you have the latest version.`;
          const toastrCart = this.toastr.success(message, `${this.settings.companyName}`);
          toastrCart.onTap.subscribe(() => location.reload());
          localStorage.setItem('refreshVersion', hash);
        } else {
          localStorage.removeItem('refreshVersion');
        }
      },
      error: (err) => {
        console.error(err, 'Could not get version');
      }
    });
  }

  private hasHashChanged(currentHash, newHash): boolean {
    if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
      return false;
    }
    return currentHash !== newHash;
  }
}
