import { GA4Item } from "./ga4.item.model";


export class GA4AddPaymentInfo {
  coupon: string;
  currency: string;
  payment_type: string;
  value: number;
  items: GA4Item[] = [];
}

export class GA4AddShippingInfo {
  coupon: string;
  currency: string;
  shipping_tier: string;
  value: number;
  items: GA4Item[] = [];
}

export class GA4AddToCart {
  currency: string;
  value: number;
  items: GA4Item[] = [];
}

export class GA4AddToWishList {
  currency: string;
  value: number;
  items: GA4Item[] = [];
}

export class GA4BeginCheckout {
  coupon: string;
  currency: string;
  value: number;
  items: GA4Item[] = [];
}

export class GA4EarnVirtualCurrency {
  virtual_currency_name: string;
  value: number;
}

export class GA4GenerateLead {
  currency: string;
  value: number;
}

export class GA4JoinGroup {
  group_id: string;
}

export class GA4LevelEnd {
  level_name: string;
  success: boolean;
}

export class GA4LevelStart {
  level_name: string;
}

export class GA4LevelUp {
  level: number;
  character: string;
}

export class GA4Login {
  method: string;
}

export class GA4PostScore {
  score: number;
  level: number;
  character: string;
}

export class GA4Purchase {
  affiliation: string;
  coupon: string;
  currency: string;
  shipping: number;
  tax: number;
  transaction_id: string;
  value: number; // Value excl tax and shipping but including discounts (e.g. SubTotal)
  items: GA4Item[] = [];
}

export class GA4Refund {
  affiliation: string;
  coupon: string;
  currency: string;
  shipping: number;
  tax: number;
  transaction_id: string;
  value: number; // Value excl tax and shipping but including discounts (e.g. SubTotal)
  items: GA4Item[] = [];
}

export class GA4RemoveFromCart {
  currency: string;
  value: number;
  items: GA4Item[] = [];
}

export class GA4Search {
  search_term: string;
}

export class GA4SelectContent {
  content_type: string;
  item_id: string;
}

export class GA4SelectItem {
  item_list_id: string;
  item_list_name: string;
  items: GA4Item[] = [];
}

export class GA4SelectPromotion {
  creative_name: string; // Could be set at item level
  creative_slot: string; // Could be set at item level
  item_list_id: string; // Could be set at item level
  location_id: string; // Could be set at item level
  promotion_id: string; // Could be set at item level
  promotion_name: string; // Could be set at item level
  items: GA4Item[] = [];
}

export class GA4Share {
  method: string;
  content_type: string;
  item_id: string;
}

export class GA4SignUp {
  method: string;
}

export class GA4SpendVirtualCurrency {
  virtual_currency_name: string;
  item_name: string;
  value: number;
}

export class GA4UnlockAchievement {
  achievement_id: string;
}

export class GA4ViewCart {
  currency: string;
  value: number;
  items: GA4Item[] = [];
}

export class GA4ViewItem {
  currency: string;
  value: number;
  items: GA4Item[] = [];
}

export class GA4ViewItemList {
  item_list_id: string;
  item_list_name: string;
  items: GA4Item[] = [];
}

export class GA4ViewPromotion {
  creative_name: string; // Could be set at item level
  creative_slot: string; // Could be set at item level
  location_id: string; // Could be set at item level
  promotion_id: string; // Could be set at item level
  promotion_name: string; // Could be set at item level
  items: GA4Item[] = [];
}
