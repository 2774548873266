import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ProductsPaged } from "src/@omnial/_models/catalog/products.paged.model";
import { CatalogService } from "./catalog.service";

@Injectable()
export class ProductsPagedService {
  public pages: BehaviorSubject<ProductsPaged[]> = new BehaviorSubject<ProductsPaged[]>(null);
  private $pages: ProductsPaged[] = null;

  constructor(public catalogService: CatalogService) { }

  public replace(pages: ProductsPaged[]): void {
    this.$pages = pages;
    this.pages.next(this.$pages);
  }
}
