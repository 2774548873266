/// These models are for reference


export class GtmPage {
    event = 'pageViewCustomEvent';
    pagePath: string;
    pageTitle: string;
}

export class GtmCheckoutStep {
    event = 'eec.checkout';
    ecommerce: {
        checkout_option: {
            actionField: {
                step: number;
            };
            product: GtmProduct[];
        };
    };
}

export class GtmCheckoutStepOption {
    event = 'eec.checkout';
    ecommerce: {
        checkout_option: {
            actionField: {
                step: number;
                option: string; // For Payment method, shipping, etc
            };
            product: GtmProduct[];
        };
    };
}

export class GtmCommerce {
    currencyCode = 'AUD';
}

export class GtmProduct {
    name: string;
    id: string; // a.k.a sku
    price: number;
    brand: string;
    category: string;
    quantity: number;
}

export class GtmAddToCart {
    event = 'ecc.add';
    ecommerce: {
        add: {
            actionField: {
                list: string; // Where they were added from
            };
        products: GtmProduct[];
        }
    };
}

export class GtmRemoveFromCart {
    event = 'eec.remove';
    ecommerce: {
        remove: {
            products: GtmProduct[];
        }
    };
}

export class GtmProductView {
    event: 'eec.detail';
    ecommerce: {
        detail: {
            actionField: {
                list: string; // Detail or Quickview
            };
        }
        products: GtmProduct[];
    };
}

export class GtmPurchase {
    event = 'eec.purchase';
    ecommerce: {
        currencyCode: 'AUD';
        purchase: {
            actionField: {
                id: string; // Order Number
                affiliation: string;
                revenue: number; // Total
                tax: number;
                shipping: number;
                coupon: string;
            };
            products: GtmProduct[];
        }
    };
}
