import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription, Observable } from 'rxjs';
import { Product, ProductDetail } from 'src/@omnial/_models/catalog/product.model';
import { Customer } from 'src/@omnial/_models/customer/customer.model';
import { OrderItem } from 'src/@omnial/_models/order/order.model';
import { CustomerService } from '../customer/customer.service';
import { TrackingService } from '../external/tracking.service';
import { KlaviyoService } from '../external/klaviyo.service';
import { CanonicalService } from '../navigation/canonical.service';
import { SiteMapService } from '../navigation/sitemap.service';
import { RepositoryService } from '../repository.service';


@Injectable()
export class CartService implements OnDestroy {
  private customer: Customer;
  public recentToken = '';
  private subscriptions: Subscription[] = [];

  constructor(
    public router: Router,
    public sitemapService: SiteMapService,
    public repoService: RepositoryService,
    public customerService: CustomerService,
    public canonicalService: CanonicalService,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public trackingService: TrackingService,
    public klaviyoService: KlaviyoService) {
    this.customerService.customer.subscribe({ next: (res) => { this.customer = res as Customer; } });
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe(); });
    }
  }

  public checkCart(customerGuid: string): Observable<boolean> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.getData(`Cart/Check/${customerGuid}`).subscribe({
        next: (res: Customer) => {
          this.customer = res as Customer;
          this.trackingService.pushCartView(this.customer);
          if (this.customer.warnings && this.customer.warnings.length > 0) {
            for (const warning of this.customer.warnings) {
              const message = warning;
              this.toastr.warning(message, 'Shopping Cart');
            }
            observer.next(false);
          } else {
            observer.next(true);
          }
          observer.complete();
        },
        error: () => {
          this.toastr.warning('We tried to check the contents of your cart but there was a problem', 'Shopping Cart');
          observer.next(false);
          observer.complete();
        }
      }));
    });
  }


  public addCoupon(customerGuid: string, couponCode: string, token: string): Observable<Customer> {
    return new Observable((observer) => {
      this.spinner.show();
      this.subscriptions.push(this.repoService.update(`Cart/AddCoupon/${customerGuid}`, { couponCode, token }).subscribe({
        next: (res) => {
          this.spinner.hide();
          this.customerService.update(res as Customer);
          observer.next(res as Customer);
          observer.complete();
        },
        error: () => {
          this.spinner.hide();
          const errMessage = 'Sorry something went wrong with this coupon.';
          this.toastr.warning(errMessage, 'Coupon');
          observer.next(null);
          observer.complete();
        }
      }));
    });
  }

  public removeCoupons(customerGuid: string): Observable<Customer> {
    return new Observable((observer) => {
      this.spinner.show();
      this.subscriptions.push(this.repoService.update(`Cart/RemoveCoupons/${customerGuid}`, JSON.stringify('nothing')).subscribe({
        next: (res) => {
          this.spinner.hide();
          this.customerService.update(res as Customer);
          observer.next(res as Customer);
          observer.complete();
        },
        error: () => {
          this.spinner.hide();
          const errMessage = 'Sorry something went wrong removing coupons.';
          this.toastr.warning(errMessage, 'Coupon');
          observer.next(null);
          observer.complete();
        }
      }));
    });
  }

  public reOrder(customerGuid: string, orderNumber: string): Observable<boolean> {
    return new Observable((observer) => {
      this.spinner.show();
      this.subscriptions.push(this.repoService.create(`Cart/ReOrder/${customerGuid}`, JSON.stringify(orderNumber)).subscribe({
        next: (res) => {
          this.spinner.hide();
          const result = res as Customer;
          this.customerService.update(result);
          if (result.warnings && result.warnings.length > 0) {
            for (const warning of this.customer.warnings) {
              const message = warning;
              this.toastr.warning(message, 'Re-Order');
            }
            observer.next(false);
          } else {
            observer.next(true);
          }
          observer.complete();
        },
        error: () => {
          this.spinner.hide();
          const errMessage = 'Sorry we could reorder these items. Please try again later.';
          this.toastr.warning(errMessage, 'Re-Order');
          this.spinner.hide();
          observer.next(false);
          observer.complete();
        }
      }));
    });
  }

  public reOrderItem(customerGuid: string, orderItem: OrderItem): Observable<boolean> {
    return new Observable((observer) => {
      this.spinner.show();
      this.subscriptions.push(this.repoService.create(`Cart/ReOrderItem/${customerGuid}`, orderItem).subscribe({
        next: (res) => {
          this.spinner.hide();
          const result = res as Customer;
          this.customerService.update(result);
          if (result.warnings && result.warnings.length > 0) {
            for (const warning of this.customer.warnings) {
              const message = warning;
              this.toastr.warning(message, 'Re-Order');
            }
            observer.next(false);
          } else {
            this.trackingService.pushCartAdd(orderItem.product, orderItem.quantity);
            this.klaviyoService.addCart(orderItem.product, orderItem.quantity, this.customer);
            const message = 'The product ' + orderItem.product.name + ' has been added. Tap this message to go to your cart.';
            const toastrCart = this.toastr.success(message, 'Shopping Cart', { positionClass: 'toast-top-center' });
            toastrCart.onTap.subscribe(() => this.router.navigate(['/cart']));
            observer.next(true);
          }
          observer.complete();
        },
        error: () => {
          this.spinner.hide();
          const errMessage = 'Sorry we could reorder this item. Please try again later.';
          this.toastr.warning(errMessage, 'Re-Order');
          this.spinner.hide();
          observer.next(false);
          observer.complete();
        }
      }));
    });
  }

  public addToWishlist(product: Product): boolean {
    if (this.customer?.customerGuid && !this.customerService.checkCustomerCookie()) {
      this.customerService.saveCustomerCookie(this.customer);
    }
    if (!this.customer || !this.customer?.customerGuid) {
      this.subscriptions.push(this.customerService.newCustomer().subscribe({
        next: (res) => {
          if (res !== null) {
            this.customer = res as Customer;
            return this.addProductToWishList(product);
          }
        }
      }));
    } else {
      return this.addProductToWishList(product);
    }
  }

  public addToWishListDetail(product: ProductDetail): boolean {
    if (this.customer?.customerGuid && !this.customerService.checkCustomerCookie()) {
      this.customerService.saveCustomerCookie(this.customer);
    }
    const cartProduct = new Product();
    cartProduct.id = product.id;
    cartProduct.name = product.name;
    cartProduct.listName = product.listName;
    cartProduct.listId = product.listId;
    if (!this.customer || !this.customer?.customerGuid) {
      this.subscriptions.push(this.customerService.newCustomer().subscribe({
        next: (res) => {
          if (res !== null) {
            this.customer = res as Customer;
            return this.addProductToWishList(cartProduct);
          }
        }
      }));
    } else {
      return this.addProductToWishList(cartProduct);
    }
  }

  public addToWishListWithAttributes(product: ProductDetail): boolean {
    if (this.customer && !this.customerService.checkCustomerCookie()) {
      this.customerService.saveCustomerCookie(this.customer);
    }
    if (!this.customer || !this.customer?.customerGuid) {
      this.subscriptions.push(this.customerService.newCustomer().subscribe({
        next: (res) => {
          if (res !== null) {
            this.customer = res as Customer;
            return this.addProductWithAttributesToWishList(product);
          }
        }
      }));
    } else {
      return this.addProductWithAttributesToWishList(product);
    }
  }

  public removeFromWishlist(product: Product): void {
    if (this.customer && !this.customerService.checkCustomerCookie()) {
      this.customerService.saveCustomerCookie(this.customer);
    }
    const cartProduct = new Product();
    cartProduct.id = product.id;
    this.spinner.show();
    this.subscriptions.push(this.repoService.update(`WishList/Remove/${this.customer.customerGuid}`, cartProduct).subscribe({
      next: (res) => {
        this.customer = res as Customer;
        this.customerService.update(this.customer);
        if (this.customer.warnings && this.customer.warnings.length > 0) {
          for (const warning of this.customer.warnings) {
            const message = `Sorry cannot remove ${product.name} from your wishlist right now - ${warning}`;
            this.toastr.warning(message, 'Wishlist');
          }
        } else {
          const message = 'The product ' + product.name + ' has been removed from the wishlist.';
          this.toastr.success(message, 'Wishlist');
        }
        this.spinner.hide();
      },
      error: () => {
        const errMessage = 'Sorry we could remove this from your wishlist, please try again later';
        this.toastr.warning(errMessage, 'Wishlist');
        this.spinner.hide();
      }
    }));
  }

  public addToCart(product: Product, quantity: number): boolean {
    this.spinner.show();
    if (this.customer?.customerGuid && !this.customerService.checkCustomerCookie()) {
      this.customerService.saveCustomerCookie(this.customer);
    }
    if (!this.customer || !this.customer?.customerGuid) {
      this.subscriptions.push(this.customerService.newCustomer().subscribe({
        next: (res) => {
          if (res !== null) {
            this.customer = res as Customer;
            return this.addProductToCart(product, null, quantity);
          }
        }
      }));
    } else {
      return this.addProductToCart(product, null, quantity);
    }
  }

  public addToCartDetail(product: ProductDetail, quantity: number): boolean {
    if (this.customer?.customerGuid && !this.customerService.checkCustomerCookie()) {
      this.customerService.saveCustomerCookie(this.customer);
    }
    const cartProduct = new Product();
    cartProduct.id = product.id;
    cartProduct.name = product.name;
    cartProduct.cartQuantity = product.cartQuantity;
    cartProduct.listName = product.listName;
    cartProduct.listId = product.listId;
    if (!this.customer || !this.customer?.customerGuid) {
      this.subscriptions.push(this.customerService.newCustomer().subscribe({
        next: (res) => {
          if (res !== null) {
            this.customer = res as Customer;
            return this.addProductToCart(cartProduct, product, quantity);
          }
        }
      }));
    } else {
      return this.addProductToCart(cartProduct, product, quantity);
    }
  }

  public addToCartWithAttributes(product: ProductDetail, quantity: number, category: string): boolean {
    if (this.customer?.customerGuid && !this.customerService.checkCustomerCookie()) {
      this.customerService.saveCustomerCookie(this.customer);
    }
    if (!this.customer || !this.customer?.customerGuid) {
      this.subscriptions.push(this.customerService.newCustomer().subscribe({
        next: (res) => {
          if (res !== null) {
            this.customer = res as Customer;
            return this.addProductWithAttributesToCart(product, quantity, category);
          }
        }
      }));
    } else {
      return this.addProductWithAttributesToCart(product, quantity, category);
    }
  }

  public increment(product: Product): boolean {
    if (this.customer?.customerGuid && !this.customerService.checkCustomerCookie()) {
      this.customerService.saveCustomerCookie(this.customer);
    }
    if (!this.customer || !this.customer?.customerGuid) {
      this.subscriptions.push(this.customerService.newCustomer().subscribe({
        next: (res) => {
          if (res !== null) {
            this.customer = res as Customer;
            return this.incrementLine(product);
          }
        }
      }));
    } else {
      return this.incrementLine(product);
    }
  }

  public removeFromCart(product: Product): void {
    if (this.customer?.customerGuid && !this.customerService.checkCustomerCookie()) {
      this.customerService.saveCustomerCookie(this.customer);
    }
    const cartProduct = new Product();
    cartProduct.id = product.id;
    const quantity = product.cartCount;
    this.spinner.show();
    this.subscriptions.push(this.repoService.update(`Cart/Remove/${this.customer?.customerGuid}`, cartProduct).subscribe({
      next: (res) => {
        this.spinner.hide();
        this.customer = res as Customer;
        this.customerService.update(this.customer);
        if (this.customer.warnings && this.customer.warnings.length > 0) {
          for (const warning of this.customer.warnings) {
            const message = `Sorry cannot remove ${product.name} from your cart right now - ${warning}`;
            this.toastr.warning(message, 'Shopping Cart');
          }
        } else {
          const message = 'The product ' + product.name + ' has been removed from the cart.';
          this.toastr.success(message, 'Shopping Cart');
          this.trackingService.pushCartRemove(product, quantity);
        }
      },
      error: () => {
        this.spinner.hide();
        const errMessage = 'Sorry we could remove this from your cart, please try again later';
        this.toastr.error(errMessage, 'Shopping Cart');
      }
    }));
  }

  public removeOneFromCart(product: Product): void {
    if (this.customer?.customerGuid && !this.customerService.checkCustomerCookie()) {
      this.customerService.saveCustomerCookie(this.customer);
    }
    const cartProduct = new Product();
    cartProduct.id = product.id;
    cartProduct.attributeInfo = product.attributeInfo;
    cartProduct.attributeXml = product.attributeXml;
    this.spinner.show();
    this.subscriptions.push(this.repoService.update(`Cart/RemoveOne/${this.customer.customerGuid}`, cartProduct).subscribe({
      next: (res) => {
        this.customer = res as Customer;
        this.customerService.update(this.customer);
        this.spinner.hide();
        if (this.customer.warnings && this.customer.warnings.length > 0) {
          for (const warning of this.customer.warnings) {
            const message = `Sorry cannot remove ${product.name} from your cart right now - ${warning}`;
            this.toastr.warning(message, 'Shopping Cart');
          }
        } else {
          const message = 'The product ' + product.name + ' has been removed from the cart.';
          this.toastr.success(message, 'Shopping Cart');
          this.trackingService.pushCartRemove(product, 1);
        }
      },
      error: () => {
        this.spinner.hide();
        const errMessage = 'Sorry we could remove this from your cart, please try again later';
        this.toastr.error(errMessage, 'Shopping Cart');
      }
    }));
  }

  private addProductToWishList(product: Product): boolean {
    const cartProduct = new Product();
    cartProduct.id = product.id;
    cartProduct.cartQuantity = 1;
    if (product.hasAttributes) {
      const message = `You cannot add the ${product.name} to you wishlist as you need to select your options. Opened the quick view or go to the product page.`;
      this.toastr.warning(message, 'Shopping Cart');
      return false;
    }
    this.spinner.show();
    this.subscriptions.push(this.repoService.update(`WishList/${this.customer.customerGuid}`, cartProduct).subscribe({
      next: (res) => {
        this.customer = res as Customer;
        this.customerService.update(this.customer);
        if (this.customer.warnings && this.customer.warnings.length > 0) {
          for (const warning of this.customer.warnings) {
            const message = `Sorry cannot add ${product.name} to you wishlist right now - ${warning}`;
            this.toastr.warning(message, 'Wishlist');
          }
        } else {
          const message = 'The product ' + product.name + ' has been added to your wishlist.';
          this.toastr.success(message, 'Wishlist');
          this.trackingService.pushWishListAdd(product);
        }
        this.spinner.hide();
      },
      error: () => {
        const errMessage = 'Sorry we could add this to your wishlist, please try again later';
        this.toastr.error(errMessage, 'Wishlist');
        this.spinner.hide();
      }
    }));
    return true;
  }

  // NOTE - Detailed Product (detail or quickview) for a product with attributes such as a bundle
  private addProductWithAttributesToWishList(product: ProductDetail): boolean {
    product.cartQuantity = 1;
    this.spinner.show();
    this.subscriptions.push(this.repoService.update(`WishList/Attributes/${this.customer.customerGuid}`, product).subscribe({
      next: (res) => {
        this.customer = res as Customer;
        this.customerService.update(this.customer);
        if (this.customer.warnings && this.customer.warnings.length > 0) {
          for (const warning of this.customer.warnings) {
            const message = `Sorry cannot add ${product.name} to you wishlist right now - ${warning}`;
            this.toastr.warning(message, 'Wishlist');
          }
        } else {
          const message = 'The product ' + product.name + ' has been added to the wishlist.';
          this.toastr.success(message, 'Wishlist');
        }
        this.spinner.hide();
      },
      error: () => {
        const errMessage = 'Sorry we could add this to your wishlist, please try again later';
        this.toastr.error(errMessage, 'Wishlist');
        this.spinner.hide();
      }
    }));
    return true;
  }

  // NOTE - This is the list screen version as it is not a Detailed Product
  private addProductToCart(product: Product, productDetail: ProductDetail, quantity: number): boolean {
    this.spinner.hide();
    const cartProduct = new Product();
    cartProduct.id = product.id;
    cartProduct.cartQuantity = quantity;
    cartProduct.listId = product.listId;
    cartProduct.listName = product.listName;
    if (product.hasAttributes) {
      const message = `Please review and choose your options options.`;
      this.toastr.info(message, 'Shopping Cart', { positionClass: 'toast-top-center' });
      if (product.canonical) {
        this.router.navigate([product.canonical, product.seName]);
      } else {
        this.subscriptions.push(this.canonicalService.productCanonical(product).subscribe({
          next: (res) => {
            const canonical = res as string;
            this.router.navigate([canonical, product.seName]);
          }
        }));
      }
      return false;
    }
    const message = 'The product ' + product.name + ' is being added. Tap this message to go to your cart.';
    this.customerService.addProductToCart(product, quantity);
    const toastrCart = this.toastr.success(message, 'Shopping Cart', { positionClass: 'toast-top-center' });
    toastrCart.onTap.subscribe(() => this.router.navigate(['/cart']));

    this.subscriptions.push(this.repoService.update(`Cart/${this.customer.customerGuid}`, cartProduct).subscribe({
      next: (res) => {
        this.customer = res as Customer;
        this.customerService.update(this.customer);
        if (this.customer.warnings && this.customer.warnings.length > 0) {
          for (const warning of this.customer.warnings) {
            const message = `Sorry cannot add ${quantity} x ${product.name} to you cart right now - ${warning}`;
            this.toastr.warning(message, 'Shopping Cart');
          }
        } else {
          if (productDetail) {
            this.trackingService.pushCartAddDetail(productDetail, quantity);
            this.klaviyoService.addCartDetail(productDetail, quantity, this.customer);
          } else {
            this.trackingService.pushCartAdd(product, quantity);
            this.klaviyoService.addCart(product, quantity, this.customer);
          }
        }
      },
      error: () => {
        const errMessage = 'Sorry we could add this to your cart, please try again later';
        this.toastr.error(errMessage, 'Shopping Cart');
      }
    }));
    return true;
  }

  // NOTE - Detailed Product (detail or quickview) for a product with attributes such as a bundle
  private addProductWithAttributesToCart(product: ProductDetail, quantity: number, category: string): boolean {
    product.cartQuantity = quantity;
    const message = 'The product ' + product.name + ' is being added. Tap this message to go to your cart.';
    const toastrCart = this.toastr.success(message, 'Shopping Cart', { positionClass: 'toast-top-center' });
    toastrCart.onTap.subscribe(() => this.router.navigate(['/cart']));
    this.customerService.addProductToCart(product, quantity);
    this.subscriptions.push(this.repoService.update(`Cart/Attributes/${this.customer.customerGuid}`, product).subscribe({
      next: (res) => {
        this.customer = res as Customer;
        this.customerService.update(this.customer);
        if (this.customer.warnings && this.customer.warnings.length > 0) {
          for (const warning of this.customer.warnings) {
            const message = `Sorry cannot add ${quantity} x ${product.name} to you cart right now - ${warning}`;
            this.toastr.warning(message, 'Shopping Cart');
          }
        } else {
          this.trackingService.pushCartAddDetail(product, quantity);
          this.klaviyoService.addCartDetail(product, quantity, this.customer);
        }
      },
      error: () => {
        const errMessage = 'Sorry we could add this to your cart, please try again later';
        this.toastr.error(errMessage, 'Shopping Cart');
      }
    }));
    return true;
  }

  private incrementLine(product: Product): boolean {
    this.spinner.show();
    this.subscriptions.push(this.repoService.update(`Cart/Increment/${this.customer.customerGuid}`, product).subscribe({
      next: (res) => {
        this.customer = res as Customer;
        this.customerService.update(this.customer);
        if (this.customer.warnings && this.customer.warnings.length > 0) {
          for (const warning of this.customer.warnings) {
            const message = `Sorry cannot update ${product.name} in your cart right now - ${warning}`;
            this.toastr.warning(message, 'Shopping Cart', { positionClass: 'toast-top-center' });
          }
        } else {
          const message = 'The product ' + product.name + ' has been updated.';
          const toastrCart = this.toastr.success(message, 'Shopping Cart');
          toastrCart.onTap.subscribe(action => this.router.navigate(['/cart']));
          this.trackingService.pushCartAdd(product, 1);
          this.klaviyoService.addCart(product, 1, this.customer);
        }
        this.spinner.hide();
      },
      error: () => {
        const errMessage = 'Sorry we could add this to your cart, please try again later';
        this.toastr.error(errMessage, 'Shopping Cart', { positionClass: 'toast-top-center' });
        this.spinner.hide();
      }
    }));
    return true;
  }
}
