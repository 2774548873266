import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { Manufacturer } from 'src/@omnial/_models/catalog/manufacturer.model';
import { FieldProperty } from 'src/@omnial/_models/field-prompt.model';
import { SocialLinks } from 'src/@omnial/_models/settings.model';
import { RepositoryStaticService } from 'src/@omnial/_services/repository-static.service';
import { RepositoryService } from 'src/@omnial/_services/repository.service';
import { Artist } from 'src/app/_models/artist.model';
import { environment } from 'src/environments/environment';
import { Faqs } from '../_models/faqs.model';
import { PluginSettings } from '../_models/plugin-settings.model';


@Injectable()
export class ElfaService {
  private subscriptions: Subscription[] = [];
  private useCache = environment.useCache;
  constructor(public spinner: NgxSpinnerService,
    public staticService: RepositoryStaticService,
    public repoService: RepositoryService,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute) {
    this.route.fragment.subscribe((fragment: string) => {
      if (fragment && fragment === 'CacheBust') {
        this.useCache = false;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe(); });
    }
  }

  // Plugin Settings
  public fieldProperties(): Observable<FieldProperty[]> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.getData('ElfaSettings/FieldProperties').subscribe({
        next: (apiRes) => {
          observer.next(apiRes as FieldProperty[]);
          observer.complete();
        },
        error: (err) => {
          console.log(err);
          observer.error("Settings Error");
          observer.complete();
        }
      }));
    });
  }

  public settings(): Observable<PluginSettings> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.getData('ElfaSettings').subscribe({
        next: (apiRes) => {
          observer.next(apiRes as PluginSettings);
          observer.complete();
        },
        error: (err) => {
          console.log(err);
          observer.error("Settings Error");
          observer.complete();
        }
      }));
    });
  }

  public updateSettings(settings: PluginSettings): Observable<PluginSettings> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.update('ElfaSettings', settings).subscribe({
        next: (apiRes) => {
          observer.next(apiRes as PluginSettings);
          observer.complete();
        },
        error: (err) => {
          console.log(err);
          observer.error("Settings Error");
          observer.complete();
        }
      }));
    });
  }

  public getSocials(): Observable<SocialLinks> {
    return new Observable((observer) => {
      if (environment.useCache) {
        this.subscriptions.push(this.staticService.get("Generic/Key/socials").subscribe({
          next: (staticRes) => {
            const socials = staticRes as SocialLinks;
            if (socials) {
              observer.next(socials);
              observer.complete();
            } else {
              this.subscriptions.push(this.repoService.getData('ElfaSettings/Socials').subscribe({
                next: (apiRes) => {
                  observer.next(apiRes as SocialLinks);
                  observer.complete();
                }
              }));
            }
          },
          error: () => {
            this.subscriptions.push(this.repoService.getData('ElfaSettings/Socials').subscribe({
              next: (apiRes) => {
                observer.next(apiRes as SocialLinks);
                observer.complete();
              }
            }));
          }
        }));
      } else {
        this.subscriptions.push(this.repoService.getData('ElfaSettings/Socials').subscribe({
          next: (apiRes) => {
            observer.next(apiRes as SocialLinks);
            observer.complete();
          }
        }));
      }
    });
  }

  public apicExport(): Observable<boolean> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.getData('ElfaSettings/APIC').subscribe({
        next: (apiRes) => {
          observer.next(apiRes as boolean);
          observer.complete();
        },
        error: (err) => {
          console.log(err);
          observer.error("APIC Export Error");
          observer.complete();
        }
      }));
    });
  }

  public getBrands(): Observable<Manufacturer[]> {
    return new Observable((observer) => {
      if (this.useCache) {
        this.subscriptions.push(this.getBrandsFromCache().subscribe({
          next: (cacheResult) => {
            observer.next(cacheResult as Manufacturer[]);
            observer.complete();
          }
        }));
      } else {
        this.subscriptions.push(this.getBrandsFromNop().subscribe({
          next: (nopResult) => {
            observer.next(nopResult as Manufacturer[]);
            observer.complete();
          }
        }));
      }
    });
  }

  public getArtists(): Observable<Artist[]> {
    return new Observable((observer) => {
      if (this.useCache) {
        this.subscriptions.push(this.getArtistsFromCache().subscribe({
          next: (cacheResult) => {
            observer.next(cacheResult as Artist[]);
            observer.complete();
          }
        }));
      } else {
        this.subscriptions.push(this.getArtistsFromNop().subscribe({
          next: (nopResult) => {
            observer.next(nopResult as Artist[]);
            observer.complete();
          }
        }));
      }
    });
  }

  public getArtistFieldProperties(): Observable<FieldProperty[]> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.getData('ElfaArtist/FieldProperties').subscribe({
        next: (res) => {
          observer.next(res as FieldProperty[]);
          observer.complete();
        },
        error: () => {
          observer.next(null);
          observer.complete();
        }
      }));
    });
  }

  public createArtist(artist: Artist): Observable<Artist> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.create('ElfaArtist', artist).subscribe({
        next: (res) => {
          observer.next(res as Artist);
          observer.complete();
        },
        error: (err) => {
          observer.error(err);
          observer.complete();
        }
      }));
    });
  }

  public updateArtist(artist: Artist): Observable<Artist> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.update('ElfaArtist', artist).subscribe({
        next: (res) => {
          observer.next(res as Artist);
          observer.complete();
        },
        error: (err) => {
          observer.error(err);
          observer.complete();
        }
      }));
    });
  }

  public deleteArtist(artist: Artist): Observable<boolean> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.delete(`ElfaArtist/${artist.id}`).subscribe({
        next: (res) => {
          observer.next(res as boolean);
          observer.complete();
        },
        error: (err) => {
          observer.error(err);
          observer.complete();
        }
      }));
    });
  }

  public reOrderArtists(artists: Artist[]): Observable<Artist[]> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.update('ElfaArtist/ReOrder', artists).subscribe({
        next: (res) => {
          observer.next(res as Artist[]);
          observer.complete();
        },
        error: (err) => {
          observer.error(err);
          observer.complete();
        }
      }));
    });
  }

  public getFaqs(): Observable<Faqs> {
    return new Observable((observer) => {
      if (this.useCache) {
        this.subscriptions.push(this.getFaqsFromCache().subscribe({
          next: (cacheResult) => {
            observer.next(cacheResult as Faqs);
            observer.complete();
          }
        }));
      } else {
        this.subscriptions.push(this.getFaqsFromNop().subscribe({
          next: (nopResult) => {
            observer.next(nopResult as Faqs);
            observer.complete();
          }
        }));
      }
    });
  }

  private getBrandsFromCache(): Observable<Manufacturer[]> {
    return new Observable((observer) => {
      this.subscriptions.push(this.staticService.get("Generic/Key/elfa-brands").subscribe({
        next: (cacheResult) => {
          if (cacheResult) {
            observer.next(cacheResult as Manufacturer[]);
            observer.complete();
          } else {
            this.subscriptions.push(this.getBrandsFromNop().subscribe({
              next: (nopResult) => {
                observer.next(nopResult as Manufacturer[]);
                observer.complete();
              }
            }));
          }
        },
        error: () => {
          this.subscriptions.push(this.getBrandsFromNop().subscribe({
            next: (nopResult) => {
              observer.next(nopResult as Manufacturer[]);
              observer.complete();
            }
          }));
        }
      }));
    });
  }

  private getBrandsFromNop(): Observable<Manufacturer[]> {
    return new Observable((observer) => {
      let endpoint = 'ElfaProduct/Brands';
      this.subscriptions.push(this.repoService.getData(endpoint).subscribe({
        next: (res) => {
          observer.next(res as Manufacturer[]);
          observer.complete();
        },
        error: () => {
          observer.next(null);
          observer.complete();
        }
      }));
    });
  }

  private getArtistsFromCache(): Observable<Artist[]> {
    return new Observable((observer) => {
      this.subscriptions.push(this.staticService.get("Generic/Key/artists").subscribe({
        next: (cacheResult) => {
          if (cacheResult) {
            observer.next(cacheResult as Artist[]);
            observer.complete();
          } else {
            this.subscriptions.push(this.getArtistsFromNop().subscribe({
              next: (nopResult) => {
                observer.next(nopResult as Artist[]);
                observer.complete();
              }
            }));
          }
        },
        error: () => {
          this.subscriptions.push(this.getArtistsFromNop().subscribe({
            next: (nopResult) => {
              observer.next(nopResult as Artist[]);
              observer.complete();
            }
          }));
        }
      }));
    });
  }

  private getArtistsFromNop(): Observable<Artist[]> {
    return new Observable((observer) => {
      let endpoint = 'ElfaArtist';
      this.subscriptions.push(this.repoService.getData(endpoint).subscribe({
        next: (res) => {
          observer.next(res as Artist[]);
          observer.complete();
        },
        error: () => {
          observer.next(null);
          observer.complete();
        }
      }));
    });
  }

  private getFaqsFromCache(): Observable<Faqs> {
    return new Observable((observer) => {
      this.subscriptions.push(this.staticService.get("Generic/Key/faqs").subscribe({
        next: (cacheResult) => {
          if (cacheResult) {
            observer.next(cacheResult as Faqs);
            observer.complete();
          } else {
            this.subscriptions.push(this.getFaqsFromNop().subscribe({
              next: (nopResult) => {
                observer.next(nopResult as Faqs);
                observer.complete();
              }
            }));
          }
        },
        error: () => {
          this.subscriptions.push(this.getFaqsFromNop().subscribe({
            next: (nopResult) => {
              observer.next(nopResult as Faqs);
              observer.complete();
            }
          }));
        }
      }));
    });
  }

  private getFaqsFromNop(): Observable<Faqs> {
    return new Observable((observer) => {
      let endpoint = 'ElfaFaq';
      this.subscriptions.push(this.repoService.getData(endpoint).subscribe({
        next: (res) => {
          observer.next(res as Faqs);
          observer.complete();
        },
        error: () => {
          observer.next(null);
          observer.complete();
        }
      }));
    });
  }

}
