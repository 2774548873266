import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ControlsMiniComponent } from './controls-mini.component';
import { MaterialBaseModule } from 'src/@omnial/material.base.module';
import { MaterialExtendModule } from 'src/@omnial/material.extend.module';



@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MaterialBaseModule,
    MaterialExtendModule
  ],
  declarations: [ControlsMiniComponent],
  exports: [ControlsMiniComponent]
})
export class ControlsMiniModule { }
