import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RepositoryService } from 'src/@omnial/_services/repository.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Customer } from 'src/@omnial/_models/customer/customer.model';
import { PaymentResult } from 'src/@omnial/_models/order/payment-result.model';
import { CustomerService } from 'src/@omnial/_services/customer/customer.service';
import { KlaviyoService } from 'src/@omnial/_services/external/klaviyo.service';
import { SecurePayConfig, SecurePayInitiatePaymentOrderResponse } from '../_models/external/securepay.model';

@Injectable()
export class SecurePayService {
  private customer: Customer;

  constructor(
    public repoService: RepositoryService,
    public customerService: CustomerService,
    public snackBar: MatSnackBar,
    public klaviyoService: KlaviyoService) {
    this.customerService.customer.subscribe({ next: (res) => { this.customer = res as Customer; } });
  }

  public config(): Observable<SecurePayConfig> {
    return new Observable((observer) => {
      this.repoService.getData(`PaymentSecurePay/Config/${this.customer.customerGuid}`).subscribe({
        next: (res) => {
          observer.next(res);
          observer.complete();
        },
        error: (msg) => {
          const errMessage = `Sorry SecurePay is not configured for this store. ${msg}`;
          this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 8000 });
          observer.error(msg);
          observer.complete();
        }
      });
    });
  }

  public initiatePaymentOrder(): Observable<SecurePayInitiatePaymentOrderResponse> {
    return new Observable((observer) => {
      this.repoService.getData(`PaymentSecurePay/InitiatePaymentOrder/${this.customer.customerGuid}`).subscribe({
        next: (res) => {
          observer.next(res);
          observer.complete();
        },
        error: (msg) => {
          const errMessage = `Sorry SecurePay Payment failed when establishing additional Fraud Check Details. ${msg}`;
          this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 8000 });
          observer.error(msg);
          observer.complete();
        }
      });
    });
  }

  public payment(paymentRequest: any): Observable<PaymentResult> {
    return new Observable((observer) => {
      this.repoService.create(`PaymentSecurePay/CreatePayment/${this.customer.customerGuid}`, paymentRequest).subscribe({
        next: (res) => {
          const result = res as PaymentResult;
          if (result.success && result.order) {
            this.klaviyoService.placedOrder(result.order);
          }
          observer.next(result);
          observer.complete();
        },
        error: (msg) => {
          const errMessage = `Sorry SecurePay Payment failed when capturing. ${msg}`;
          this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 8000 });
          observer.error(msg);
          observer.complete();
        }
      });
    });
  }
}
