import { Product } from "../catalog/product.model";
import { Country, State } from "../order/country.model";

export class Review {
    productId: number;
    title: string;
    reviewText: string;
    replyText: string;
    rating: number;
    helpfulYesTotal: number;
    helpfulNoTotal: number;
    created: string;
    reviewerName: string;
    state: State;
    country: Country;
    product: Product;
}

export class ReviewRequest {
    productId: number;
    email: string;
    name: string;
    title: string;
    reviewText: string;
    rating: number;
    token: string;
    customerGuid: string;
}

export class ReviewResult {
    success: boolean;
    errors: string[];
    customerGuid: string;
}
