import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Pagination } from 'src/@omnial/_models/pagination.model';


@Injectable({
  providedIn: 'root'
})
export class PaginationService {
  private pagination: Pagination;

  get pageIndex(): number {
    return this.pagination.pageNumber < 1 ? 0 : this.pagination.pageNumber - 1;
  }

  get pageNumber(): number {
    return this.pagination.pageNumber;
  }

  set pageNumber(value: number) {
    this.pagination.pageNumber = value;
  }

  get selectItemsPerPage(): number[] {
    return this.pagination.selectItemsPerPage;
  }

  get pageSize(): number {
    return this.pagination.pageSize;
  }

  constructor() {
    this.pagination = new Pagination();
  }

  change(pageEvent: PageEvent): void {
    this.pagination.pageNumber = pageEvent.pageIndex + 1;
    this.pagination.pageSize = pageEvent.pageSize;
    this.pagination.allItemsLength = pageEvent.length;
  }

  setPage(pageNumber: number): void {
    this.pagination.pageNumber = pageNumber;
  }
}
