import { Injectable, OnDestroy } from '@angular/core';
import { StripeElementLocale } from '@stripe/stripe-js';
import { Subscription } from 'rxjs';
import { OmnialImage } from 'src/@omnial/_models/catalog/omnial-image.model';
import { Product, ProductDetail } from 'src/@omnial/_models/catalog/product.model';
import { Customer } from 'src/@omnial/_models/customer/customer.model';
import { AccountLink } from 'src/@omnial/_models/navigation/account.link.model';
import { MenuBanner } from 'src/@omnial/_models/navigation/menu.model';
import { StarShipItSettings } from 'src/@omnial/_models/order/star-shipping.model';
import { NopTemplate, SocialLinks, StoreSettings, WidgetZone } from 'src/@omnial/_models/settings.model';
import { SecurePayStyle } from 'src/@omnial/_models/external/securepay.model';
import { StoreService } from 'src/@omnial/_services/catalog/store.service';
import { CurrencyService } from 'src/@omnial/_services/price/currency.service';
import { SocialService } from './_services/social.service';


@Injectable()
export class AppSettings implements OnDestroy {
  storeSettings: StoreSettings;
  companyName = 'ELFA The Electric Factory'; // Tagged to the end of Page Titles
  companyDescription = 'ELFA The Electric Factory. Musical Instruments and Sound Equipment'; // Default Meta Tag
  holdingImage = new OmnialImage( // Holding image for loading and off screen deferrals (using animated)
    '/assets/images/loading/sound-wave-small.gif',
    '/assets/images/loading/sound-wave-small.gif',
    '/assets/images/loading/sound-wave.gif',
    '/assets/images/loading/sound-wave.gif',
    '/assets/images/loading/sound-wave.gif', 0, "elfa", "Loading Please Wait", "Loading Please Wait", '');
  theme = 'elfa';
  menuBanners: MenuBanner[] = [];
  megaMobileNav = true; // Mobile (side) nav follows Mega Menu structure (instead of raw sitemap)
  megaSideNav = false; // Desktop side nav follows Mega Menu structure (instead of raw sitemap)
  maintainScroll = false; // Maintain the scroll position on Listing Pages (for large Catalogues)
  maintainFilters = true; // Maintain the filters on Listing Pages
  maintainSort = true; // Maintain the sort on Listing Pages
  socialLinks: SocialLinks = new SocialLinks("https:///facebook.com", "", "", "", "", "", "");
  rtl = false;
  accountTitle = "My Account";
  accountLinks: AccountLink[] = [
    { title: 'Dashboard', name: 'Dashboard', routerLink: '/account/dashboard', icon: 'dashboard', children: [] },
    { title: 'Information', name: 'Information', routerLink: '/account/information', icon: 'info', children: [] },
    { title: 'Addresses', name: 'Addresses', routerLink: '/account/addresses', icon: 'location_on', children: [] },
    { title: 'Order History', name: 'Order History', routerLink: '/account/orders', icon: 'add_shopping_cart', children: [] },
    { title: 'Logout', name: 'Logout', routerLink: '/account/logout', icon: 'power_settings_new', children: [] },
  ];
  templates = [
    new NopTemplate("topic", "artists", 2, "Artists Listing"),
    new NopTemplate("topic", "productsBefore", 3, "Content with product top"),
    new NopTemplate("topic", "productsAfter", 4, "Content with product bottom"),
    new NopTemplate("topic", "contactUs", 5, "Contact Form"),
    new NopTemplate("topic", "faqs", 6, "FAQs"),
    new NopTemplate("topic", "dealer-locator", 7, "Dealer Locator")
  ];
  widgetZones = [
    new WidgetZone("home_page_main_slider", "Slider", "Home Page Slider"),
    new WidgetZone("theme_header_menu", "Menu", "Main Header Menu"),
    new WidgetZone("footer_menu", "Menu", "Footer Links"),
    new WidgetZone("terms_conditions", "Menu", "Terms and Conditions")
  ]
  productDetailExpandTextLimit = 800;
  tinyMceFontSizes = "14px 16px";
  tinyMceFonts = "Pragati Narrow (text)=Pragati Narrow, sans-serif;Fjalla One (headings)=Fjalla One, sans-serif";
  tinyMceStyles = "@import url('https://fonts.googleapis.com/css2?family=Pragati+Narrow:wght@400;700&display=swap');@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Lato:wght@400;700&display=swap');.row > div {border:1px dotted #ccc;};";
  tinyMceLineHeights = "1";
  paymentOrder: string[] = ["Payments.Stripe", "Payments.PayPal", "Payments.Afterpay"];
  public subscriptions: Subscription[] = [];

  constructor(public socialService: SocialService,
    public storeService: StoreService,
    public currencyService: CurrencyService) {
    this.subscriptions.push(this.socialService.socials.subscribe({
      next: (res: SocialLinks) => {
        if (res) {
          this.socialLinks = res;
        }
      },
      error: () => {
        // Back out values ....
        this.socialLinks = new SocialLinks(
          "https://www.facebook.com/ElectricFactory1/",
          "https://www.facebook.com/ElectricFactory1/",
          "https://www.facebook.com/ElectricFactory1/",
          "https://www.facebook.com/ElectricFactory1/",
          "https://www.facebook.com/ElectricFactory1/",
          "https://www.facebook.com/ElectricFactory1/",
          "https://www.facebook.com/ElectricFactory1/");
      }
    }));

    this.subscriptions.push(this.storeService.storeSettings.subscribe({
      next: (res: StoreSettings) => {
        if (res) {
          this.storeSettings = res;
        }
      },
      error: () => {
        // Back out values .... ?
        this.storeSettings = new StoreSettings();
        this.storeSettings.defaultMetaDescription = this.companyDescription;
        this.storeSettings.defaultPageTitle = this.companyName;
      }
    }));
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe(); });
    }
  }
  public showPrice(product: Product): boolean {
    return false;
  }
  public showPriceDetail(product: ProductDetail): boolean {
    return false;
  }
  public spendMore(shippingSettings : StarShipItSettings, customer: Customer): string {
    if (customer?.orderTotals?.shipping === 0) {
      return null; // Already Got Free Shipping
    }
    const moreToSpend = shippingSettings?.thresholdFree - customer?.orderTotals?.orderTotal + customer?.orderTotals?.shipping;
    if (moreToSpend > 0) {
      return `Spend ${this.currencyService.formatCurrency(customer?.cartList[0]?.currency, moreToSpend)} more to get FREE shipping`;
    } else {
      return null;
    }
  }

  public stripeLocale: StripeElementLocale = 'auto';

  public securePayStyles: SecurePayStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    label: {
      font: {
        family: 'Roboto, Open Sans, Segoe UI, sans-serif',
        size: '18px',
        color: '#6a2447'
      }
    },
    input: {
      font: {
        family: 'Roboto, Open Sans, Segoe UI, sans-serif',
        size: '18px',
        color: '#6a2447'
      }
    }
  }
}
