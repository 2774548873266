import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Product, ProductDetail } from 'src/@omnial/_models/catalog/product.model';
import { SiteMapItem } from 'src/@omnial/_models/navigation/site-map.model';
import { SiteMapService } from './sitemap.service';



@Injectable()
export class CanonicalService implements OnDestroy {
  private subscriptions: Subscription[] = [];
  private sitemapItems: SiteMapItem[];

  constructor(public sitemapService: SiteMapService) { }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe(); });
    }
  }

  public productCanonical(product: Product): Observable<string> {
    return new Observable((observer) => {
      this.subscriptions.push(this.sitemapService.siteMap.subscribe({
        next: (res) => {
          this.sitemapItems = res as SiteMapItem[];
          if (this.sitemapItems && this.sitemapItems.length > 0) {
            let canonical = '/';
            if (product.canonicalCategoryId) {
              const node = this.sitemapItems.find(i => i.categoryId === product.canonicalCategoryId);
              if (node) {
                canonical = node.routerLink;
              }
            }
            if (product.categoryIds && canonical !== '/') { // HACK - Set the canonical to the longest link in case we have no canonical category
              for (const catId of product.categoryIds) {
                const node = this.sitemapItems.find(i => i.categoryId === catId);
                if (node && node.routerLink.length > canonical.length) {
                  canonical = node.routerLink;
                }
              }
            }
            observer.next(canonical);
            observer.complete();
          }
        },
        error: () => {
          observer.next('/'); // Should never happen);
          observer.complete();
      }
      }));
    });
  }

  public productDetailCanonical(product: ProductDetail): Observable<string> {
    return new Observable((observer) => {
      this.subscriptions.push(this.sitemapService.siteMap.subscribe({
        next: (res) => {
          this.sitemapItems = res as SiteMapItem[];
          if (this.sitemapItems && this.sitemapItems.length > 0) {
            let canonical = '/';
            if (product.canonicalCategoryId) {
              const node = this.sitemapItems.find(i => i.categoryId === product.canonicalCategoryId);
              if (node) {
                canonical = node.routerLink;
              }
            }
            if (product.categoryIds && canonical !== '/') { // HACK - Set the canonical to the longest link in case we have no canonical category
              for (const catId of product.categoryIds) {
                const node = this.sitemapItems.find(i => i.categoryId === catId);
                if (node && node.routerLink.length > canonical.length) {
                  canonical = node.routerLink;
                }
              }
            }
            observer.next(canonical);
            observer.complete();
          }
        },
        error: () => {
          observer.next('/'); // Should never happen);
          observer.complete();
      }
      }));
    });
  }
}
