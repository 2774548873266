import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { MaterialBaseModule } from "src/@omnial/material.base.module";
import { MaterialExtendModule } from "src/@omnial/material.extend.module";
import { MaterialTableModule } from "src/@omnial/material.table.module";
import { BannersModule } from "src/@omnial/shared/banners/banners.module";
import { PipesModule } from "src/@omnial/_pipes/pipes.module";
import { ProductReviewModule } from "src/@omnial/shared/product-review/product-review.module";
import { ProductsCarouselModule } from "src/@omnial/shared/products-carousel/products-carousel.module";
import { RatingModule } from "src/@omnial/shared/rating/rating.module";
import { TopicContentModule } from "src/@omnial/shared/topic-content/topic-content.module";
import { SwiperModule } from "swiper/angular";
import { FaqsComponent } from "./faqs/faqs.component";
import { ArtistCarouselComponent } from "./artist-carousel/artist-carousel.component";
import { ArtistCarouselItemComponent } from "./artist-carousel/artist-item/artist-item.component";
import { ElfaProductFiltersComponent } from "./elfa-product-filters/elfa-product-filters.component";
import { ShopByElfaBrandComponent } from "./shop-by-elfa-brand/shop-by-elfa-brand.component";
import { ElfaBrandCarouselComponent } from "./elfa-brand-carousel/elfa-brand-carousel.component";


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    RatingModule,
    ProductsCarouselModule,
    ReactiveFormsModule,
    MaterialBaseModule,
    MaterialExtendModule,
    MaterialTableModule,
    LazyLoadImageModule,
    ProductReviewModule,
    PipesModule,
    LazyLoadImageModule,
    SwiperModule,
    RatingModule,
    BannersModule,
    TopicContentModule
  ],
  declarations: [ElfaProductFiltersComponent, FaqsComponent, ArtistCarouselComponent, ArtistCarouselItemComponent, ShopByElfaBrandComponent, ElfaBrandCarouselComponent],
  exports: [ElfaProductFiltersComponent, FaqsComponent, ArtistCarouselComponent, ArtistCarouselItemComponent, ShopByElfaBrandComponent, ElfaBrandCarouselComponent]
})
export class ClientSharedModule { }
