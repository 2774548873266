import { RepositoryService } from 'src/@omnial/_services/repository.service';
import { Observable, Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { Injectable, OnDestroy } from '@angular/core';
import { ContactResult } from 'src/@omnial/_models/customer/contact.model';
import { ReviewRequest, ReviewResult } from 'src/@omnial/_models/customer/review.model';


@Injectable()
export class ReviewService implements OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor(private repoService: RepositoryService, public spinner: NgxSpinnerService) { }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe(); });
    }
  }

  public submit(reviewRequest: ReviewRequest): Observable<ContactResult> {
    this.spinner.show();
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.create('Review', reviewRequest).subscribe({
        next: (res) => {
          this.spinner.hide();
          const result = res as ReviewResult;
          observer.next(result);
          observer.complete();
        },
        error: () => {
          this.spinner.hide();
          const result = new ReviewResult();
          result.errors = [];
          result.errors.push('General Server error');
          observer.next(result);
          observer.complete();
        }
      }));
    });
  }
}
