import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Manufacturer } from "src/@omnial/_models/catalog/manufacturer.model";
import { ProductsPaged } from "src/@omnial/_models/catalog/products.paged.model";

@Injectable()
export class ProductManufacturerService {
  public manufacturer: BehaviorSubject<Manufacturer> = new BehaviorSubject<Manufacturer>(null);
  private $manufacturer: Manufacturer = null;
  public manufacturerProducts: BehaviorSubject<ProductsPaged> = new BehaviorSubject<ProductsPaged>(null);
  private $products: ProductsPaged = null;

  constructor() { }

  public load(): void {
    this.$products = null;
    this.manufacturerProducts.next(null);
  }

  public replaceManufacturer(manufacturer: Manufacturer): void {
    this.$manufacturer = manufacturer;
    this.manufacturer.next(this.$manufacturer);
  }

  public replaceProducts(products: ProductsPaged): void {
    this.$products = products;
    this.manufacturerProducts.next(this.$products);
  }
}
