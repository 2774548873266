import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { Customer } from 'src/@omnial/_models/customer/customer.model';
import { PayPalConfig } from 'src/@omnial/_models/external/paypal.config.model';
import { PaymentResult } from 'src/@omnial/_models/order/payment-result.model';
import { CustomerService } from 'src/@omnial/_services/customer/customer.service';
import { KlaviyoService } from 'src/@omnial/_services/external/klaviyo.service';
import { RepositoryService } from 'src/@omnial/_services/repository.service';

@Injectable()
export class PayPalService {
  private customer: Customer;

  payPalClientConfig = new Observable<PayPalConfig>((observer) => {
    this.repoService.getData(`PaymentPayPal/Config/${this.customer.customerGuid}`).subscribe({
      next: (res) => {
        const config = res as PayPalConfig;
        observer.next(config);
        observer.complete();
      },
      error: (e) => {
        const errMessage = 'Sorry PayPal is unavailable. Please try again later';
        this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 3000 });
        observer.error(e);
        observer.complete();
      }
    });
  });

  constructor(
    public repoService: RepositoryService,
    public customerService: CustomerService,
    public snackBar: MatSnackBar,
    public klaviyoService: KlaviyoService) {
    this.customerService.customer.subscribe({ next: (res) => { this.customer = res as Customer; } });
  }

  public payPalCapture(customerGuid: string, payPalOrderId: string): Observable<PaymentResult> {
    return new Observable((observer) => {
      this.repoService.create(`PaymentPayPal/Capture/${customerGuid}`, JSON.stringify(payPalOrderId)).subscribe({
        next: (res) => {
          const result = res as PaymentResult;
          if (result.success && result.order) {
            this.klaviyoService.placedOrder(result.order);
          }
          observer.next(result);
          observer.complete();
        },
        error: (err) => {
          let errMessage = 'Sorry PayPal failed when capturing. ';
          if (err && err.msg) {
            errMessage += err.msg;
          }
          this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 8000 });
          observer.error(err);
          observer.complete();
        }
      });
    });
  }

  public payPalAuthorise(customerGuid: string, payPalOrderId: string): Observable<PaymentResult> {
    return new Observable((observer) => {
      this.repoService.create(`PaymentPayPal/Authorise/${customerGuid}`, JSON.stringify(payPalOrderId)).subscribe({
        next: (res) => {
          observer.next(res as PaymentResult);
          observer.complete();
        },
        error: (err) => {
          let errMessage = 'Sorry PayPal failed when Authorising. ';
          if (err && err.msg) {
            errMessage += err.msg;
          }
          this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 8000 });
          observer.error(err);
          observer.complete();
        }
      });
    });
  }
}
