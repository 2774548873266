import { Injectable, OnDestroy } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { Manufacturer } from "src/@omnial/_models/catalog/manufacturer.model";
import { DealerLocatorSettings } from "src/@omnial/_models/dealer-locator/dealer-locator.settings.model";
import { Dealer } from "src/@omnial/_models/dealer-locator/dealer.model";
import { OmnialMarker } from "src/@omnial/_models/dealer-locator/marker.model";
import { FieldProperty } from "src/@omnial/_models/field-prompt.model";
import { environment } from "src/environments/environment";
import { RepositoryStaticService } from "../repository-static.service";
import { RepositoryService } from "../repository.service";

@Injectable()
export class DealerService implements OnDestroy {
  public dealers: BehaviorSubject<Dealer[]> = new BehaviorSubject<Dealer[]>(null);
  private $dealers: Dealer[] = null;
  public settings: BehaviorSubject<DealerLocatorSettings> = new BehaviorSubject<DealerLocatorSettings>(null);
  private $settings: DealerLocatorSettings = null;
  private subscriptions: Subscription[] = [];
  private useCache = environment.useCache;
  public lat: number;
  public lng: number;
  public zoom: number;

  constructor(public spinner: NgxSpinnerService,
    public staticService: RepositoryStaticService,
    public repoService: RepositoryService,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute) {
    this.route.fragment.subscribe((fragment: string) => {
      if (fragment && fragment === 'CacheBust') {
        this.useCache = false;
      }
    });
  }

  public load(init?: Dealer[]): void {
    if (init) {
      this.$dealers = init;
      this.dealers.next(this.$dealers);
      return;
    }
    if (!this.$dealers) {
      this.subscriptions.push(this.getDealers().subscribe({
        next: (res: Dealer[]) => {
          if (res) {
            res.forEach((dealer) => {
              if (dealer.webSite && dealer.webSite.indexOf('http') === -1) {
                dealer.webSite = `https://${dealer.webSite}`;
              }
            });
          }
          this.$dealers = res;
          this.dealers.next(this.$dealers);
        },
        error: () => {
          this.$dealers = null;
          this.dealers.next(this.$dealers);
        }
      }));
    } else {
      this.dealers.next(this.$dealers);
    }
  }

  public loadSettings(init?: DealerLocatorSettings): void {
    if (init) {
      this.$settings = init;
      this.settings.next(this.$settings);
      return;
    }
    if (!this.$settings) {
      this.subscriptions.push(this.getSettings().subscribe({
        next: (res: DealerLocatorSettings) => {
          this.$settings = res;
          this.settings.next(this.$settings);
        },
        error: () => {
          this.$settings = null;
          this.settings.next(this.$settings);
        }
      }));
    } else {
      this.settings.next(this.$settings);
    }
  }

  public getDealers(bypassCache?: boolean): Observable<Dealer[]> {
    return new Observable((observer) => {
      if (this.useCache && !bypassCache) {
        this.subscriptions.push(this.getDealersFromCache().subscribe({
          next: (cacheResult) => {
            observer.next(cacheResult as Dealer[]);
            observer.complete();
          }
        }));
      } else {
        this.subscriptions.push(this.getDealersFromNop().subscribe({
          next: (nopResult) => {
            observer.next(nopResult as Dealer[]);
            observer.complete();
          }
        }));
      }
    });
  }

  public getFieldProperties(): Observable<FieldProperty[]> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.getData('StoreLocator/FieldProperties').subscribe({
        next: (res) => {
          observer.next(res as FieldProperty[]);
          observer.complete();
        },
        error: (e) => {
          observer.error(e);
          observer.complete();
        }
      }));
    });
  }

  private getDealersFromCache(): Observable<Dealer[]> {
    return new Observable((observer) => {
      this.subscriptions.push(this.staticService.get('Generic/Key/storelocator').subscribe({
        next: (cacheResult) => {
          if (cacheResult) {
            observer.next(cacheResult as Dealer[]);
            observer.complete();
          } else {
            this.subscriptions.push(this.getDealersFromNop().subscribe({
              next: (nopResult) => {
                observer.next(nopResult as Dealer[]);
                observer.complete();
              }
            }));
          }
        },
        error: () => {
          this.subscriptions.push(this.getDealersFromNop().subscribe({
            next: (nopResult) => {
              observer.next(nopResult as Dealer[]);
              observer.complete();
            }
          }));
        }
      }));
    });
  }

  private getDealersFromNop(): Observable<Dealer[]> {
    return new Observable((observer) => {
      let endpoint = 'StoreLocator';
      this.subscriptions.push(this.repoService.getData(endpoint).subscribe({
        next: (res) => {
          observer.next(res as Dealer[]);
          observer.complete();
        },
        error: (e) => {
          observer.error(e);
          observer.complete();
        }
      }));
    });
  }

  public getSettings(bypassCache?: boolean): Observable<DealerLocatorSettings> {
    return new Observable((observer) => {
      if (this.useCache && !bypassCache) {
        this.subscriptions.push(this.getSettingsFromCache().subscribe({
          next: (cacheResult) => {
            if (cacheResult) {
              observer.next(cacheResult as DealerLocatorSettings);
              observer.complete();
            } else {
              this.subscriptions.push(this.getSettingsFromNop().subscribe({
                next: (nopResult) => {
                  observer.next(nopResult as DealerLocatorSettings);
                  observer.complete();
                }
              }));
            }
          }
        }));
      } else {
        this.subscriptions.push(this.getSettingsFromNop().subscribe({
          next: (nopResult) => {
            observer.next(nopResult as DealerLocatorSettings);
            observer.complete();
          }
        }));
      }
    });
  }

  public createDealer(dealer: Dealer): Observable<Dealer> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.create('StoreLocator', dealer).subscribe({
        next: (res) => {
          observer.next(res as Dealer);
          observer.complete();
        },
        error: (err) => {
          observer.error(err);
          observer.complete();
        }
      }));
    });
  }

  public updateDealer(dealer: Dealer): Observable<Dealer> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.update('StoreLocator', dealer).subscribe({
        next: (res) => {
          observer.next(res as Dealer);
          observer.complete();
        },
        error: (err) => {
          observer.error(err);
          observer.complete();
        }
      }));
    });
  }

  public geoLocateDealer(dealer: Dealer): Observable<Dealer> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.update('StoreLocator/GeoLocate', dealer).subscribe({
        next: (res) => {
          observer.next(res as Dealer);
          observer.complete();
        },
        error: (err) => {
          observer.error(err);
          observer.complete();
        }
      }));
    });
  }

  public deleteDealer(dealer: Dealer): Observable<boolean> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.delete(`StoreLocator/${dealer.id}`).subscribe({
        next: (res) => {
          observer.next(res as boolean);
          observer.complete();
        },
        error: (err) => {
          observer.error(err);
          observer.complete();
        }
      }));
    });
  }

  private getSettingsFromCache(): Observable<DealerLocatorSettings> {
    return new Observable((observer) => {
      this.subscriptions.push(this.staticService.get('Generic/Key/storelocatorsettings').subscribe({
        next: (cacheResult) => {
          if (cacheResult) {
            observer.next(cacheResult as DealerLocatorSettings);
            observer.complete();
          } else {
            this.subscriptions.push(this.getSettingsFromNop().subscribe({
              next: (nopResult) => {
                observer.next(nopResult as DealerLocatorSettings);
                observer.complete();
              }
            }));
          }
        },
        error: () => {
          this.subscriptions.push(this.getSettingsFromNop().subscribe({
            next: (nopResult) => {
              observer.next(nopResult as DealerLocatorSettings);
              observer.complete();
            }
          }));
        }
      }));
    });
  }

  private getSettingsFromNop(): Observable<DealerLocatorSettings> {
    return new Observable((observer) => {
      let endpoint = 'StoreLocator/Settings';
      this.subscriptions.push(this.repoService.getData(endpoint).subscribe({
        next: (res) => {
          observer.next(res as DealerLocatorSettings);
          observer.complete();
        },
        error: (e) => {
          observer.error(e);
          observer.complete();
        }
      }));
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe() });
    }
  }

  public newCoordinators = new Subject();

  public openWindow = new Subject();

  public getMarkers(dealers: Dealer[]): OmnialMarker[] {
    const markers: OmnialMarker[] = [];
    dealers?.forEach((dealer) => {
      if (dealer?.address && dealer?.coordinates) {
        let marker = new OmnialMarker();
        marker.dealerId = dealer.id;
        marker.lat = dealer.coordinates?.latitude;
        marker.lng = dealer.coordinates?.longitude
        marker.title = dealer.name;
        marker.icon = '/assets/images/map/marker.png'; // TODO
        marker.draggable = false;
        marker.street = dealer.address.address2 ? `${dealer.address.address1}; ${dealer.address.address2}` : dealer.address.address1;
        marker.city = dealer.address.city;
        marker.state = dealer.address.state?.name ? dealer.address.state?.name : dealer.address.stateName;
        marker.postalCode = dealer.address.zipPostalCode;
        marker.email = dealer.address.email;
        marker.phone = dealer.address.phoneNumber;
        marker.website = dealer.webSite;
        marker.detail = 'InfoWindow content';
        marker.position = new google.maps.LatLng(dealer.coordinates?.latitude, dealer.coordinates?.longitude);
        marker.manufacturers = [];
        dealer.manufacturerIds?.forEach(manufacturerId => {
          const manufacturer = new Manufacturer();
          manufacturer.id = manufacturerId; // The rest will be fleshed out on the dealer locator if we need it
          if (!marker.manufacturers.some(m => m.id === manufacturerId)) {
            marker.manufacturers.push(manufacturer);
          }
        });
        markers.push(marker);
      }
    });
    return markers;
  }
}
