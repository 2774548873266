import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductsCarouselComponent } from './products-carousel.component';
import { RouterModule } from '@angular/router';
import { MaterialBaseModule } from 'src/@omnial/material.base.module';
import { ProductDialogModule } from '../product-dialog/product-dialog.module';
import { RatingModule } from '../rating/rating.module';
import { ControlsListModule } from 'src/@omnial/product-controls/controls-list/controls-list.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SwiperModule } from 'swiper/angular';
import { PipesModule } from '../../_pipes/pipes.module';
import { MaterialExtendModule } from 'src/@omnial/material.extend.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialBaseModule,
    MaterialExtendModule,
    ProductDialogModule,
    RatingModule,
    ControlsListModule,
    LazyLoadImageModule,
    SwiperModule,
    PipesModule
  ],
  declarations: [ProductsCarouselComponent],
  exports: [ProductsCarouselComponent]
})
export class ProductsCarouselModule { }
