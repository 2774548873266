import { OmnialImage } from "./catalog/omnial-image.model";

export class Settings {
    constructor(public storeSettings: StoreSettings,
                public companyName: string,
                public companyDescription: string,
                public holdingImage: OmnialImage,
                public theme: string,
                public megaMobileNav: boolean,
                public megaSideNav: boolean,
                public maintainScroll: boolean,
                public topMenuBackground: string,
                public topMenuStaticBackground: string,
                public socialLinks: SocialLinks,
                public rtl: boolean,
                public accountLinks: any,
                public templates: NopTemplate[],
                public widgetZones: WidgetZone[]
        ) { }
}

export class StoreSettings {
  defaultPageTitle: string;
  defaultMetaKeywords: string;
  defaultMetaDescription: string;
  sorting: CatalogSort;
  returnReasons: ReturnReason[];
  returnActions: ReturnAction[];
}

export class CatalogSort{
  allowSorting: boolean;
  options: CatalogSortOption[]
}

export class CatalogSortOption {
  id: number;
  name: string;
  field: string;
  reverse: boolean;
  displayOrder: number;
}

export class ReturnReason {
  name: string;
  displayOrder: number;
}

export class ReturnAction {
  name: string;
  displayOrder: number;
}

export class SocialLinks {
  constructor(public facebook: string,
    public instagram: string,
    public youtube: string,
    public linkedIn: string,
    public pinterest: string,
    public twitter: string,
    public reddit: string) { }
}

export class NopTemplate {
  constructor(public type: string, public name: string, public id: number, public description: string) { }
}
export class WidgetZone {
  constructor(public code: string, public type: string, public name: string) { }
}
