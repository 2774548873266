import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';


@NgModule({
  providers: [],
  imports: [
    CommonModule,
    MatTableModule
  ],
  exports: [
    MatTableModule]
})
export class MaterialTableModule {
}
