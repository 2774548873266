import { animate, style, transition, trigger } from '@angular/animations';

export const fadeInLeftAnimation = trigger('fadeInLeft', [
  transition(':enter', [
    style({
      transform: 'translateX(2vw)',
      opacity: 0
    }),
    animate('0.4s cubic-bezier(0, 0.35, 1, 0.25)', style({
      transform: 'translateX(0)',
      opacity: 1
    }))
  ])
]);
