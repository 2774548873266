import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { SwiperModule } from 'swiper/angular';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MaterialBaseModule } from 'src/@omnial/material.base.module';
import { MaterialExtendModule } from 'src/@omnial/material.extend.module';
import { ProductZoomModule } from 'src/@omnial/pages/product/product-zoom/product-zoom.module';
import { ProductSwiperComponent } from './product-swiper.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialBaseModule,
    MaterialExtendModule,
    LazyLoadImageModule,
    ProductZoomModule,
    SwiperModule,
    YouTubePlayerModule
  ],
  declarations: [ProductSwiperComponent],
  exports: [ProductSwiperComponent]
})
export class ProductSwiperModule { }
