import { ProductFilterGroup } from "./product-filter.model";
import { Product } from "./product.model";

export class ProductsPaged {
  products: Product[];
  pageIndex: number;
  pageSize: number;
  lastPage: boolean;
  seName: string;
  visible: boolean;
  filterGroups: ProductFilterGroup[];
}
