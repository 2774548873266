import { Injectable } from "@angular/core";

@Injectable()
export class CurrencyService {
  private installments = 4; // Hardcoded?

  constructor() { }

  public installmentPrice(amount: number): number {
    return Math.round((amount) / this.installments / 0.01) * 0.01;
  }

  public formatCurrency(currencyCode: string, amount: number): string {
    let culture = "en-US";
    switch (currencyCode) {
      case 'AUD':
        culture = 'en-AU';
        break;
      case 'USD':
        culture = 'en-US';
        break;
      case 'GBP':
        culture = 'en-GB';
        break;
      case 'EUR':
        culture = 'fr-FR';
        break;
      case 'NZD':
        culture = 'en-NZ';
        break;
      case 'JPY':
        culture = 'jp-JP';
        amount = amount * 100; // Revert for "no cent" currencies ??
        break;
      default:
        culture = 'en-AU';
        break;
    }
    try {
      return new Intl.NumberFormat(culture, {
        style: 'currency',
        currency: currencyCode,
      }).format(amount);
    } catch {
      return amount.toString();
    }
  }
}
