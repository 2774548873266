import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialBaseModule } from 'src/@omnial/material.base.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RatingModule } from '../rating/rating.module';
import { MaterialExtendModule } from 'src/@omnial/material.extend.module';
import { DealerLocatorComponent } from './dealer-locator.component';
import { DealerLocatorMapComponent } from './dealer-locator-map/dealer-locator-map.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { AutoCompleteModule } from '../autocomplete/autocomplete.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialBaseModule,
    MaterialExtendModule,
    ReactiveFormsModule,
    RatingModule,
    GoogleMapsModule,
    AutoCompleteModule
  ],
  declarations: [DealerLocatorComponent, DealerLocatorMapComponent],
  exports: [DealerLocatorComponent, DealerLocatorMapComponent]
})
export class DealerLocatorModule { }
