import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { StarShipItSettings } from "src/@omnial/_models/order/star-shipping.model";
import { environment } from "src/environments/environment";
import { RepositoryStaticService } from "../repository-static.service";
import { RepositoryService } from "../repository.service";

@Injectable()
export class StarShipItService implements OnDestroy {
  public starShipItSettings: BehaviorSubject<StarShipItSettings> = new BehaviorSubject<StarShipItSettings>(null);
  private $starShipItSettings: StarShipItSettings = null;
  private subscriptions: Subscription[] = [];
  private useCache = environment.useCache;

  constructor(public staticService: RepositoryStaticService,
    public repoService: RepositoryService) { }

  public load(init?: StarShipItSettings): void {
    if (init) {
      this.$starShipItSettings = init;
      this.starShipItSettings.next(this.$starShipItSettings);
      return;
    }
    if (!this.$starShipItSettings) {
      this.subscriptions.push(this.getStarShipItSettings().subscribe({
        next: (res: StarShipItSettings) => {
          this.$starShipItSettings = res;
          this.starShipItSettings.next(this.$starShipItSettings);
        },
        error: () => {
          this.$starShipItSettings = null;
          this.starShipItSettings.next(this.$starShipItSettings);
        }
      }));
    } else {
      this.starShipItSettings.next(this.$starShipItSettings);
    }
  }

  public getStarShipItSettings(bypassCache?: boolean): Observable<StarShipItSettings> {
    return new Observable((observer) => {
      if (this.useCache && !bypassCache) {
        this.subscriptions.push(this.staticService.get('Checkout/StarShipIt').subscribe({
          next: (staticRes) => {
            const settings = staticRes as StarShipItSettings;
            if (settings) {
              observer.next(settings);
              observer.complete();
            } else {
              this.subscriptions.push(this.repoService.getData(`StarShipping`).subscribe({
                next: (res) => {
                  observer.next(res as StarShipItSettings);
                  observer.complete();
                },
                error: (e) => {
                  observer.error(e);
                  observer.complete();
                }
              }));
            }
          },
          error: () => {
            this.subscriptions.push(this.repoService.getData(`StarShipping`).subscribe({
              next: (res) => {
                observer.next(res as StarShipItSettings);
                observer.complete();
              },
              error: (e) => {
                observer.error(e);
                observer.complete();
              }
            }));
          }
        }));
      } else {
        this.subscriptions.push(this.repoService.getData(`StarShipping`).subscribe({
          next: (res) => {
            observer.next(res as StarShipItSettings);
            observer.complete();
          },
          error: (e) => {
            observer.error(e);
            observer.complete();
          }
        }));
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe() });
    }
  }
}
