import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { RepositoryService } from '../repository.service';
import { RepositoryStaticService } from '../repository-static.service';
import { Customer } from 'src/@omnial/_models/customer/customer.model';
import { environment } from 'src/environments/environment';
import { Topic } from 'src/@omnial/_models/catalog/topic.model';

@Injectable()
export class TopicService implements OnDestroy {
  public customer: Customer = null;
  public categoryPageIndex = 0;
  public currentCategory: string;
  public manufacturerPageIndex = 0;
  public currentManufacturer: string;
  private subscriptions: Subscription[] = [];
  private useCache = environment.useCache;

  constructor(
    public staticService: RepositoryStaticService,
    public repoService: RepositoryService) { }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe() });
    }
  }

  public getTopicById(id: number, bypassCache?: boolean): Observable<Topic> {
    return new Observable((observer) => {
      if (this.useCache && !bypassCache) {
        this.subscriptions.push(this.staticService.getBySeName("Topic", id.toString()).subscribe({
          next: (staticRes) => {
            const topic = staticRes as Topic;
            if (topic) {
              observer.next(topic);
              observer.complete();
            } else {
              this.subscriptions.push(this.repoService.getData(`Topic/Id/${id}`).subscribe({
                next: (apiRes) => {
                  observer.next(apiRes as Topic);
                  observer.complete();
                }
              }));
            }
          },
          error: (e) => {
            this.subscriptions.push(this.repoService.getData(`Topic/Id/${id}`).subscribe({
              next: (apiRes) => {
                observer.next(apiRes as Topic);
                observer.complete();
              }
            }));
          }
        }));
      } else {
        this.subscriptions.push(this.repoService.getData(`Topic/Id/${id}`).subscribe({
          next: (res) => {
            observer.next(res as Topic);
            observer.complete();
          },
          error: (e) => {
            observer.error(e);
            observer.complete();
          }
        }));
      }
    });
  }

  public getTopicBySlug(slug: string, bypassCache?: boolean): Observable<Topic> {
    return new Observable((observer) => {
      if (this.useCache && !bypassCache) {
        this.subscriptions.push(this.staticService.getBySeName("Topic", slug).subscribe({
          next: (staticRes) => {
            const topic = staticRes as Topic;
            if (topic) {
              observer.next(topic);
              observer.complete();
            } else {
              this.subscriptions.push(this.repoService.getData(`Topic/${slug}`).subscribe({
                next: (apiRes) => {
                  observer.next(apiRes as Topic);
                  observer.complete();
                }
              }));
            }
          },
          error: () => {
            this.subscriptions.push(this.repoService.getData(`Topic/${slug}`).subscribe({
              next: (apiRes) => {
                observer.next(apiRes as Topic);
                observer.complete();
              }
            }));
          }
        }));
      } else {
        this.subscriptions.push(this.repoService.getData(`Topic/${slug}`).subscribe({
          next: (res) => {
            observer.next(res as Topic);
            observer.complete();
          },
          error: (e) => {
            observer.error(e);
            observer.complete();
          }
        }));
      }
    });
  }
}
