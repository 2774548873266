import { Injectable } from '@angular/core';
import { Product, ProductDetail } from 'src/@omnial/_models/catalog/product.model';
import { Customer } from 'src/@omnial/_models/customer/customer.model';
import { Order } from 'src/@omnial/_models/order/order.model';
import { environment } from 'src/environments/environment';

declare var fbq: any;

@Injectable()
export class FacebookService {

  constructor() { }

  public push(event: string, object?: any) {
    try {
      if (object) {
        fbq('track', event, object);
      } else {
        fbq('track', event);
      }
    } catch (error) {
      console.log('!!! Facebook Error !!!!');
      console.log(error);
      console.log(event);
    }
  }

  public pushPage(event: string, object: any) {
    this.push('PageView');
  }

  public pushAddPaymentInfo(customer: Customer, paymentType: string) {
    const contentIds: string[] = [];
    const contents: any[] = [];
    customer?.cartList?.forEach((item) => {
      contentIds.push(item.sku);
      contents.push({ id: item.sku, quantity: item.cartQuantity });
    });
    const paymentObject = {
      content_category: 'checkout',
      content_ids: contentIds,
      contents: contents,
      currency: customer?.cartList?.length > 0 ? customer.cartList[0].currency : environment.currencyCode,
      value: customer?.orderTotals?.orderTotal
    };
    this.push('AddPaymentInfo', paymentObject);
  }

  public pushAddToCart(product: Product, quantity: number): void {
    const contentIds: string[] = [product?.sku];
    const contents: any[] = [{ id: product.sku, quantity: quantity }];
    const cartObject = {
      content_type: 'product',
      content_name: product?.listName,
      content_ids: contentIds,
      contents: contents,
      currency: product?.currency,
      value: product.valuePrice
    };
    this.push('AddToCart', cartObject);
  }

  public pushAddToCartDetail(product: ProductDetail, quantity: number): void {
    const contentIds: string[] = [product?.sku];
    const contents: any[] = [{ id: product.sku, quantity: quantity }];
    const cartObject = {
      content_type: 'product',
      content_name: product?.listName,
      content_ids: contentIds,
      contents: contents,
      currency: product?.currency,
      value: product.valuePrice
    };
    this.push('AddToCart', cartObject);
  }

  public pushAddToWishList(product: Product): void {
    const contentIds: string[] = [product?.sku];
    const contents: any[] = [{ id: product.sku, quantity: 1 }];
    const wishListObject = {
      content_name: product?.listName,
      content_ids: contentIds,
      contents: contents,
      currency: product?.currency,
      value: product.valuePrice
    };
    this.push('AddToWishlist', wishListObject);
  }

  public pushBeginCheckout(customer: Customer): void {
    const contentIds: string[] = [];
    const contents: any[] = [];
    let noItems = 0;
    customer?.cartList?.forEach((item) => {
      contentIds.push(item.sku);
      contents.push({ id: item.sku, quantity: item.cartQuantity });
      noItems += item.cartQuantity;
    });
    const checkoutObject = {
      content_category: 'checkout',
      content_ids: contentIds,
      contents: contents,
      currency: customer?.cartList?.length > 0 ? customer.cartList[0].currency : environment.currencyCode,
      num_items: noItems,
      value: customer?.orderTotals?.orderTotal
    };
        this.push('InitiateCheckout', checkoutObject);
  }

  public pushGenerateLead(value: number, currencyCode: string): void {
    this.push('Lead', { content_category: 'omnial_lead', content_name: 'omnial_lead', currency: currencyCode, value: value});
  }

  public pushPurchase(order: Order): void {
    const contents: any[] = [];
    let noItems = 0;
    order?.items?.forEach((item) => {
      contents.push({ id: item?.product?.sku, quantity: item.quantity });
      noItems += item.quantity;
    });
    const purchaseObject = {
      content_name: 'checkout_complete',
      content_type: 'product',
      contents: contents,
      currency: order.customerCurrencyCode,
      num_items: noItems,
      value: order?.orderTotal
    };
    this.push('Purchase', purchaseObject);
  }

  public pushSearch(term: string): void {
    this.push('Search', {search_string: term});
  }

  public pushSignUp(method: string): void {
    this.push('CompleteRegistration');
  }

  public pushViewItem(product: ProductDetail, listName: string, listId: string, position: number): void {
    const contents: any[] = [{ id: product.sku, quantity: 1 }];
    const productObject = {
      content_type: 'product',
      content_name: listName,
      content_category: listId,
      contents: contents,
      currency: product?.currency,
      value: product.valuePrice
    };
    this.push('ViewContent', productObject);
  }

}
