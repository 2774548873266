import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { ThemeSettings } from "src/@omnial/_models/catalog/theme-settings.model";
import { SevenSpikesService } from "./seven.spikes.service";

@Injectable()
export class ThemeService implements OnDestroy {
  public themeSettings: BehaviorSubject<ThemeSettings> = new BehaviorSubject<ThemeSettings>(null);
  private $themeSettings: ThemeSettings = null;
  private subscriptions: Subscription[] = [];

  constructor(public sevenSpikesService: SevenSpikesService) { }

  public load(init?: ThemeSettings): void {
    if (init) {
      this.$themeSettings = init;
      this.themeSettings.next(this.$themeSettings);
      return;
    }
    if (!this.$themeSettings) {
      this.subscriptions.push(this.sevenSpikesService.getThemeSettings().subscribe({
        next: (res: ThemeSettings) => {
          this.$themeSettings = res;
          this.themeSettings.next(this.$themeSettings);
        },
        error: () => {
          this.$themeSettings = null;
          this.themeSettings.next(this.$themeSettings);
        }
      }));
    } else{
      this.themeSettings.next(this.$themeSettings);
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe() });
    }
  }
}
