import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RepositoryStaticService {

  constructor(private http: HttpClient) { }

  public get = (type: string) => {
    return this.http.get(`${environment.staticEndPoint}/Cache/${type}`, this.generateHeaders());
  }

  public getMenus = (widgetZone: string) => {
    return this.http.get(`${environment.staticEndPoint}/Cache/Menu/List/${widgetZone}`, this.generateHeaders());
  }

  public getSliders = (widgetZone: string, url?: string) => {
    if (url) {
      return this.http.get(`${environment.staticEndPoint}/Cache/Sliders/${widgetZone}-${url}`, this.generateHeaders());
    } else {
      return this.http.get(`${environment.staticEndPoint}/Cache/Sliders/${widgetZone}`, this.generateHeaders());
    }
  }

  public getProductCollections = (widgetZone: string) => {
    return this.http.get(`${environment.staticEndPoint}/Cache/Product/Collections/${widgetZone}`, this.generateHeaders());
  }

  public getBlog = () => {
    return this.http.get(`${environment.staticEndPoint}/Cache/Blog`, this.generateHeaders());
  }

  public getInfoBoxes = () => {
    return this.http.get(`${environment.staticEndPoint}/Cache/Store/InfoBoxes`, this.generateHeaders());
  }

  public getThemeSettings = () => {
    return this.http.get(`${environment.staticEndPoint}/Cache/Store/Theme`, this.generateHeaders());
  }

  public getTopReviews = () => {
    return this.http.get(`${environment.staticEndPoint}/Cache/Store/TopReviews`, this.generateHeaders());
  }

  public getBySeName = (type: string, seName: string) => {
    return this.http.get(`${environment.staticEndPoint}/Cache/${type}/${seName}`, this.generateHeaders());
  }

  public getProducts = (type: string, seName: string, pageIndex: number, pageSize: number) => {
    return this.http.get(`${environment.staticEndPoint}/Cache/${type}/${seName}/${pageIndex}/${pageSize}`, this.generateHeaders());
  }

  public getById = (type: string, id: number) => {
    return this.http.get(`${environment.staticEndPoint}/Cache/${type}/${id}`, this.generateHeaders());
  }

  public getByBoolean = (type: string, qualifier: boolean) => {
    return this.http.get(`${environment.staticEndPoint}/Cache/${type}/${qualifier}`, this.generateHeaders());
  }

  public getByGuid = (type: string, guid: string) => {
    return this.http.get(`${environment.staticEndPoint}/Cache/${type}/${guid}`, this.generateHeaders());
  }

  omnialHeader(): string {
    const omnialValues = {
      'StoreId': environment.storeId,
      'PageSize': environment.pageSize,
      'TopLevelCatId': environment.rootCatId,
      'IncludeManufacturers': false
    };
    return JSON.stringify(omnialValues);
  }

  private generateHeaders = () => {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.staticEndPoint,
        'Access-Control-Allow-Methods': 'GET, OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, content-type',
        'Omnial': this.omnialHeader()
      })
    };
  }
}
