import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialBaseModule } from 'src/@omnial/material.base.module';
import { ProductDialogComponent } from './product-dialog.component';
import { ControlsQuickviewModule } from 'src/@omnial/product-controls/controls-quickview/controls-quickview.module';
import { MaterialExtendModule } from 'src/@omnial/material.extend.module';
import { ProductDetailModule } from 'src/@omnial/pages/product/product-detail/product-detail.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialBaseModule,
    MaterialExtendModule,
    ControlsQuickviewModule,
    ProductDetailModule
  ],
  declarations: [ProductDialogComponent],
  exports: [ProductDialogComponent]
})
export class ProductDialogModule { }
