import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class ClaimsService {
  public claims: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);
  private $claims: string[] = null;

  constructor() { }

  public set(claims: string[]): void {
    if (claims) {
      this.$claims = claims;
      this.claims.next(this.$claims);
      return;
    }
  }
}
