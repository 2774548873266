import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Product, ProductDetail } from 'src/@omnial/_models/catalog/product.model';
import { GtmPage } from 'src/@omnial/_models/external/gtm.model';
import { Order } from 'src/@omnial/_models/order/order.model';
import { environment } from 'src/environments/environment';
import { GoogleGA4Service } from './google.ga4.service';
import { GoogleGA3Service } from './google.ga3.service';

import PlaceResult = google.maps.places.PlaceResult;
import { Customer } from 'src/@omnial/_models/customer/customer.model';
import { ShippingOption } from 'src/@omnial/_models/order/shipping-option.model';
import { FacebookService } from './facebook.service';


@Injectable()
export class TrackingService {

  constructor(private gtmService: GoogleTagManagerService,
    private ga3Service: GoogleGA3Service,
    private ga4Service: GoogleGA4Service,
    public facebookService: FacebookService) { }

  public pushPage(path: string, title: string): void {
    if (environment.gtmActive) {
      const virtualPage = new GtmPage();
      virtualPage.pagePath = path;
      virtualPage.pageTitle = title;
      try {
        this.gtmService.pushTag(virtualPage);
      } catch (error) {
        console.log(error);
        console.log(virtualPage);
      }
    }
    if (environment.facebookActive) {
      this.facebookService.pushPage(path, title);
    }
  }

  public pushImpression(product: Product, listId: string, listName: string, position: number): void {
    if (environment.gtmActive) {
      if (environment.ga3) {
        this.ga3Service.pushImpression(product, listId, listName, position);
      }
      if (environment.ga4) {
        this.ga4Service.pushViewItemList([product], listId, listName, position);
      }
    }
  }

  public pushProductView(product: ProductDetail, listId: string, listName: string): void {
    if (environment.gtmActive) {
      if (environment.ga3) {
        this.ga3Service.pushProductView(product, listId);
      }
      if (environment.ga4) {
        this.ga4Service.pushViewItem(product, listId, listName, 1);
      }
    }
    if (environment.facebookActive) {
      this.facebookService.pushViewItem(product, listId, listName, 1);
    }
  }

  public pushProductClick(product: Product, listId: string, listName: string, position: number): void {
    if (environment.gtmActive) {
      if (environment.ga3) {
        this.ga3Service.pushProductClick(product, listId, listName, position);
      }
      if (environment.ga4) {
        this.ga4Service.pushSelectItem(product, listId, listName, position);
      }
    }
  }

  public pushStartCheckout(customer: Customer): void {
    if (environment.gtmActive) {
      if (environment.ga4) {
        this.ga4Service.pushBeginCheckout(customer);
      }
    }
    if (environment.facebookActive) {
      this.facebookService.pushBeginCheckout(customer);
    }
  }

  public pushAddShippingInfo(customer: Customer, shippingOption: ShippingOption): void {
    if (environment.gtmActive) {
      if (environment.ga4) {
        this.ga4Service.pushAddShippingInfo(customer, shippingOption);
      }
    }
  }

  public pushAddPaymentInfo(customer: Customer, paymentType: string): void {
    if (environment.gtmActive) {
      if (environment.ga4) {
        this.ga4Service.pushAddPaymentInfo(customer, paymentType);
      }
    }
    if (environment.facebookActive) {
      this.facebookService.pushAddPaymentInfo(customer, paymentType);
    }
  }

  public pushCheckout(step: number, products: Product[]): void {
    if (environment.gtmActive) {
      if (environment.ga3) {
        this.ga3Service.pushCheckout(step, products);
      }
      // Nothing for GA4 here as BeginCheckout, AddShippingInfo and Add PaymentInfo are used instead
    }
  }

  public pushCheckoutOption(step: number, option: string): void {
    if (environment.gtmActive) {
      if (environment.ga3) {
        this.ga3Service.pushCheckoutOption(step, option);
      }
      // Nothing for GA4 here as BeginCheckout, AddShippingInfo and Add PaymentInfo are used instead
    }
  }

  public pushPurchase(order: Order, companyName: string): void {
    if (environment.gtmActive) {
      if (environment.ga3) {
        this.ga3Service.pushPurchase(order, companyName);
      }
      if (environment.ga4) {
        this.ga4Service.pushPurchase(order);
      }
    }
    if (environment.facebookActive) {
      this.facebookService.pushPurchase(order);
    }
  }

  public pushCartView(customer: Customer): void {
    if (environment.gtmActive) {
      if (environment.ga4) {
        this.ga4Service.pushViewCart(customer);
      }
    }
  }

  public pushCartAdd(product: Product, quantity: number): void {
    if (environment.gtmActive) {
      if (environment.ga3) {
        this.ga3Service.pushCartAdd(product, quantity);
      }
      if (environment.ga4) {
        this.ga4Service.pushAddToCart(product, quantity);
      }
    }
    if (environment.facebookActive) {
      this.facebookService.pushAddToCart(product, quantity);
    }
  }

  public pushCartAddDetail(product: ProductDetail, quantity: number): void {
    if (environment.gtmActive) {
      if (environment.ga3) {
        this.ga3Service.pushCartAddDetail(product, quantity);
      }
      if (environment.ga4) {
        this.ga4Service.pushAddToCartDetail(product, quantity);
      }
    }
    if (environment.facebookActive) {
      this.facebookService.pushAddToCartDetail(product, quantity);
    }
  }

  public pushCartRemove(product: Product, quantity: number): void {
    if (environment.gtmActive) {
      if (environment.ga3) {
        this.ga3Service.pushCartRemove(product, quantity);
      }
      if (environment.ga4) {
        this.ga4Service.pushRemoveFromCart(product, quantity);
      }
    }
  }

  public pushWishListAdd(product: Product): void {
    if (environment.gtmActive) {
      if (environment.ga4) {
        this.ga4Service.pushAddToWishList(product);
      }
    }
    if (environment.facebookActive) {
      this.facebookService.pushAddToWishList(product);
    }
  }

  public pushSelectContent(contentType: string, itemId: string): void {
    if (environment.gtmActive) {
      if (environment.ga4) {
        this.ga4Service.pushSelectContent(contentType, itemId);
      }
    }
  }
}
