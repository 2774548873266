import { Slider } from "../catalog/slider.model";

export class Menu {
  id: number;
  name: string;
  cssClass: string;
  items: MenuItem[];
}

export class MenuItem {
  id: string;
  link: string;
  name: string;
  imageUrl: string;
  children: MenuItem[];
  isLogo: boolean;
  isMega: boolean;
  cssClass: string;
  parentId: string;
}

export class MenuBanner {
  widgetZone: string;
}
