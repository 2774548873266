import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialBaseModule } from 'src/@omnial/material.base.module';
import { RouterModule } from '@angular/router';
import { BannersStaticComponent } from './banners-static.component';
import { PipesModule } from 'src/@omnial/_pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialBaseModule,
    RouterModule,
    PipesModule
  ],
  declarations: [BannersStaticComponent],
  exports: [BannersStaticComponent]
})
export class BannersStaticModule { }
