import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialBaseModule } from 'src/@omnial/material.base.module';
import { AutoCompleteComponent } from './autocomplete.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialExtendModule } from 'src/@omnial/material.extend.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialBaseModule,
    MaterialExtendModule,
    ReactiveFormsModule
  ],
  declarations: [AutoCompleteComponent],
  exports: [AutoCompleteComponent]
})
export class AutoCompleteModule { }
