import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilterByIdPipe } from './filter-by-id.pipe';
import { SafePipe } from './safe.pipes';
import { SafeHtmlPipe } from './sanitize.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        FilterByIdPipe,
        SafePipe,
        SafeHtmlPipe
    ],
    exports: [
        FilterByIdPipe,
        SafePipe,
        SafeHtmlPipe
    ]
})
export class PipesModule { }
