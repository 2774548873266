import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SiteMapItem } from 'src/@omnial/_models/navigation/site-map.model';
import { environment } from 'src/environments/environment';
import { RepositoryStaticService } from '../repository-static.service';
import { RepositoryService } from '../repository.service';
import { ActivatedRoute } from '@angular/router';


@Injectable()
export class SiteMapService {
  public siteMap: BehaviorSubject<SiteMapItem[]> = new BehaviorSubject<SiteMapItem[]>(null);
  private $siteMap: SiteMapItem[] = null;
  private subscriptions: Subscription[] = [];
  private useCache = environment.useCache;

  constructor(
    private repoService: RepositoryService,
    private staticService: RepositoryStaticService,
    private location: Location,
    private route: ActivatedRoute) {
    this.route.fragment.subscribe({
      next: (fragment: string) => {
        if (fragment && fragment === 'CacheBust') {
          this.useCache = false;
        }
      }
    });
  }


  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe(); });
    }
  }

  public load(init?: SiteMapItem[], bypassCache?: boolean): void {
    if (init && !bypassCache) {
      this.$siteMap = init;
      this.siteMap.next(this.$siteMap);
      return;
    }
    if (this.useCache && !bypassCache) {
      this.subscriptions.push(this.staticService.getById('Menu/Full', environment.rootCatId).subscribe({
        next: (staticRes) => {
          if (!staticRes) {
            this.subscriptions.push(this.repoService.getData(`Sitemap`).subscribe({
              next: (apiRes) => {
                this.$siteMap = apiRes as SiteMapItem[];
                this.siteMap.next(apiRes as SiteMapItem[]);
              }
            }));
          } else {
            this.$siteMap = staticRes as SiteMapItem[];
            this.siteMap.next(staticRes as SiteMapItem[]);
          }
        },
        error: () => {
          this.subscriptions.push(this.repoService.getData(`Sitemap`).subscribe({
            next: (apiRes) => {
              this.$siteMap = apiRes as SiteMapItem[];
              this.siteMap.next(apiRes as SiteMapItem[]);
            }
          }));
        }
      }));
    } else {
      this.subscriptions.push(this.repoService.getData(`Sitemap`).subscribe({
        next: (apiRes) => {
          this.$siteMap = apiRes as SiteMapItem[];
          this.siteMap.next(apiRes as SiteMapItem[]);
        }
      }));
    }
  }

  public expandActiveSubMenu(): void {
    this.closeAllSubMenus();
    const url = this.location.path();
    let routerLink = decodeURIComponent(url);
    if (!url || !routerLink || !this.$siteMap || routerLink === '/') {
      return
    }
    const activeMenuItem = this.$siteMap.filter(item => item.link === routerLink || item.routerLink === routerLink);
    if (activeMenuItem[0]) {
      let menuItem = activeMenuItem[0];
      while (menuItem && menuItem.parentId !== 'root') {
        menuItem = this.$siteMap.filter(item => item.id === menuItem.parentId)[0];
        if (menuItem) {
          this.openMenuItem(menuItem.id);
        }
      }
    } else { // Look up one level in case we are at a Product page
      const parentArray = this.location.path().split('/');
      parentArray.pop();
      const parentPath = parentArray.join('/');
      const parentItem = this.$siteMap.find(i => i.link === parentPath || i.routerLink === parentPath);
      if (parentItem) {
        let menuItem = parentItem;
        while (menuItem && menuItem.parentId !== 'root') {
          menuItem = this.$siteMap.filter(item => item.id === menuItem.parentId)[0];
          if (menuItem) {
            this.openMenuItem(menuItem.id);
          }
        }
      }
    }
  }

  public openMenuItem(menuId: string): void {
    const menuItem = document.getElementById('menu-item-' + menuId);
    const subMenu = document.getElementById('sub-menu-' + menuId);
    if (subMenu) {
      subMenu.classList.add('show');
      menuItem.classList.add('expanded');
    }
  }

  public toggleMenuItem(menuId: string): void {
    const menuItem = document.getElementById('menu-item-' + menuId);
    const subMenu = document.getElementById('sub-menu-' + menuId);
    if (subMenu) {
      if (subMenu.classList.contains('show')) {
        subMenu.classList.remove('show');
        menuItem.classList.remove('expanded');
      } else {
        subMenu.classList.add('show');
        menuItem.classList.add('expanded');
      }
    }
  }

  public closeOtherSubMenus(menu: Array<SiteMapItem>, menuId: string): void {
    const currentMenuItem = menu.filter(item => item.id === menuId)[0];
    menu.forEach(item => {
      if (item && currentMenuItem) {
        if ((item.id !== menuId && item.parentId === currentMenuItem.parentId) ||
          (currentMenuItem.parentId === "root" && item.id !== menuId)) {
          const subMenu = document.getElementById('sub-menu-' + item.id);
          const menuItem = document.getElementById('menu-item-' + item.id);
          if (subMenu) {
            if (subMenu.classList.contains('show')) {
              subMenu.classList.remove('show');
              menuItem.classList.remove('expanded');
            }
          }
        }
      }
    });
  }

  public closeAllSubMenus(): void {
    this.$siteMap?.forEach(item => {
      const subMenu = document.getElementById('sub-menu-' + item.id);
      const menuItem = document.getElementById('menu-item-' + item.id);
      if (subMenu) {
        if (subMenu.classList.contains('show')) {
          subMenu.classList.remove('show');
          menuItem.classList.remove('expanded');
        }
      }
    });
  }
}
