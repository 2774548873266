import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RepositoryService } from 'src/@omnial/_services/repository.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RepositoryStaticService } from 'src/@omnial/_services/repository-static.service';
import { Customer } from 'src/@omnial/_models/customer/customer.model';
import { PaymentResult } from 'src/@omnial/_models/order/payment-result.model';
import { CustomerService } from 'src/@omnial/_services/customer/customer.service';
import { KlaviyoService } from 'src/@omnial/_services/external/klaviyo.service';
import { PaymentIntent, StripeError } from '@stripe/stripe-js';
import { StripePaymentOptions } from 'src/@omnial/_models/external/stripe-config.model';
import { StripeIntent } from '../_models/external/stripe-intent.model';

@Injectable()
export class StripeServiceOmnial {
  private customer: Customer;

  constructor(
    public staticService: RepositoryStaticService,
    public repoService: RepositoryService,
    public customerService: CustomerService,
    public snackBar: MatSnackBar,
    public klaviyoService: KlaviyoService) {
    this.customerService.customer.subscribe({ next: (res) => { this.customer = res as Customer; } });
  }

  public paymentOptions(): Observable<StripePaymentOptions> {
    return new Observable((observer) => {
      this.repoService.getData(`PaymentStripe/Options/${this.customer.customerGuid}`).subscribe({
        next: (res) => {
          const paymentOptions = res as StripePaymentOptions;
          if (paymentOptions) {
            observer.next(paymentOptions);
            observer.complete();
          } else {
            const errMessage = `Sorry Stripe Payment is unavailable.`;
            this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 3000 });
            observer.error(null);
            observer.complete();
          }
        },
        error: (msg) => {
          const errMessage = `Sorry Stripe Payment is unavailable. ${msg}`;
          this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 3000 });
          observer.error(msg);
          observer.complete();
        }
      });
    });
  };

  public logButtonPayment(method: string): Observable<boolean> {
    return new Observable((observer) => {
      this.repoService.getData(`PaymentStripe/LogButtonPayment/${this.customer.customerGuid}/${method}`).subscribe({
        next: () => {
          observer.next(true);
          observer.complete();
        },
        error: () => {
          observer.next(true);
          observer.complete();
        }
      });
    });
  };

  public intent(): Observable<StripeIntent> {
    return new Observable((observer) => {
      this.repoService.getData(`PaymentStripe/Intent/${this.customer.customerGuid}`).subscribe({
        next: (res) => {
          const paymentIntent = res as StripeIntent;
          if (paymentIntent) {
            observer.next(paymentIntent);
            observer.complete();
          } else {
            const errMessage = `Sorry Stripe Payment is unavailable.`;
            this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 3000 });
            observer.error(null);
            observer.complete();
          }
        },
        error: (msg) => {
          const errMessage = `Sorry Stripe Payment is unavailable. ${msg}`;
          this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 3000 });
          observer.error(msg);
          observer.complete();
        }
      });
    });
  };

  public capture(intent: PaymentIntent, method: string): Observable<PaymentResult> {
    return new Observable((observer) => {
      const stripeIntent = new StripeIntent();
      stripeIntent.paymentIntent = intent;
      stripeIntent.method = method;
      this.repoService.create(`PaymentStripe/Capture/${this.customer.customerGuid}`, stripeIntent).subscribe({
        next: (res) => {
          const result = res as PaymentResult;
          if (result.success && result.order) {
            this.klaviyoService.placedOrder(result.order);
          }
          observer.next(result);
          observer.complete();
        },
        error: (msg) => {
          const errMessage = `Sorry Stripe Payment failed when capturing. ${msg}`;
          this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 8000 });
          observer.error(msg);
          observer.complete();
        }
      });
    });
  }

  public error(error: string): Observable<boolean> {
    return new Observable((observer) => {
      this.repoService.create(`PaymentStripe/Error/${this.customer.customerGuid}`, error).subscribe({
        next: (res) => {
          observer.next(res as boolean);
          observer.complete();
        },
        error: () => {
          observer.next(false);
          observer.complete();
        }
      });
    });
  }

  public stripeError(error: StripeError): Observable<boolean> {
    return new Observable((observer) => {
      this.repoService.create(`PaymentStripe/StripeError/${this.customer.customerGuid}`, error).subscribe({
        next: (res) => {
          observer.next(res as boolean);
          observer.complete();
        },
        error: () => {
          observer.next(false);
          observer.complete();
        }
      });
    });
  }
}
