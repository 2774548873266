import { Injectable, OnDestroy } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { Manufacturer } from "src/@omnial/_models/catalog/manufacturer.model";
import { environment } from "src/environments/environment";
import { RepositoryStaticService } from "../repository-static.service";
import { RepositoryService } from "../repository.service";

@Injectable()
export class ManufacturerService implements OnDestroy {
  public manufacturers: BehaviorSubject<Manufacturer[]> = new BehaviorSubject<Manufacturer[]>(null);
  private $manufacturers: Manufacturer[] = null;
  private subscriptions: Subscription[] = [];
  private useCache = environment.useCache;

  constructor(    public staticService: RepositoryStaticService,
    public repoService: RepositoryService,
    public snackBar: MatSnackBar) { }

  public load(init?: Manufacturer[]): void {
    if (init) {
      this.$manufacturers = init;
      this.manufacturers.next(this.$manufacturers);
      return;
    }
    if (!this.$manufacturers) {
      this.subscriptions.push(this.getManufacturers().subscribe({
        next: (res: Manufacturer[]) => {
          this.$manufacturers = res;
          this.manufacturers.next(this.$manufacturers);
        },
        error: () => {
          this.$manufacturers = null;
          this.manufacturers.next(this.$manufacturers);
        }
      }));
    } else{
      this.manufacturers.next(this.$manufacturers);
    }
  }
  public getManufacturers(bypassCache?: boolean): Observable<Manufacturer[]> {
    return new Observable((observer) => {
      if (this.useCache && !bypassCache) {
        this.subscriptions.push(this.staticService.get('Manufacturer').subscribe({
          next: (staticRes) => {
            if (staticRes) {
              observer.next(staticRes as Manufacturer[]);
              observer.complete();
            } else {
              this.subscriptions.push(this.repoService.getData(`Manufacturer`).subscribe({
                next: apiRes => {
                  observer.next(apiRes as Manufacturer[]);
                  observer.complete();
                },
                error: (e) => {
                  const message = 'Sorry we could not get the information for the manufacturers, please try again later';
                  this.snackBar.open(message, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 3000 });
                  observer.error(e);
                  observer.complete();
                }
              }));
            }
          },
          error: (e) => {
            this.subscriptions.push(this.repoService.getData(`Manufacturer`).subscribe({
              next: (apiRes) => {
                observer.next(apiRes as Manufacturer[]);
                observer.complete();
              },
              error: (e) => {
                const message = 'Sorry we could not get the information for the manufacturers, please try again later';
                this.snackBar.open(message, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 3000 });
                observer.error(e);
                observer.complete();
              }
            }));
          }
        }));
      } else {
        this.subscriptions.push(this.repoService.getData(`Manufacturer`).subscribe({
          next: (resNoCache) => {
            observer.next(resNoCache as Manufacturer[]);
            observer.complete();
          },
          error: (e) => {
            observer.error(e);
            observer.complete();
          }
        }));
      }
    });
  }

  public getManufacturer(slug: string, bypassCache?: boolean): Observable<Manufacturer> {
    return new Observable((observer) => {
      if (this.useCache && !bypassCache) {
        this.subscriptions.push(this.staticService.getBySeName('Manufacturer', slug).subscribe({
          next: (staticRes) => {
            if (staticRes) {
              observer.next(staticRes as Manufacturer);
              observer.complete();
            } else {
              this.subscriptions.push(this.repoService.getData(`Manufacturer/${slug}`).subscribe({
                next: apiRes => {
                  observer.next(apiRes as Manufacturer);
                  observer.complete();
                },
                error: (e) => {
                  const message = 'Sorry we could not get the information for this manufacturer page, please try again later';
                  this.snackBar.open(message, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 3000 });
                  observer.error(e);
                  observer.complete();
                }
              }));
            }
          },
          error: () => {
            this.subscriptions.push(this.repoService.getData(`Manufacturer/${slug}`).subscribe({
              next: (apiRes) => {
                observer.next(apiRes as Manufacturer);
                observer.complete();
              },
              error: (e) => {
                const message = 'Sorry we could not get the information for this manufacturer page, please try again later';
                this.snackBar.open(message, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 3000 });
                observer.error(e);
                observer.complete();
              }
            }));
          }
        }));
      } else {
        this.subscriptions.push(this.repoService.getData(`Manufacturer/${slug}`).subscribe({
          next: (resNoCache) => {
            observer.next(resNoCache as Manufacturer);
            observer.complete();
          },
          error: (e) => {
            observer.error(e);
            observer.complete();
          }
        }));
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe() });
    }
  }
}
