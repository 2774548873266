import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialBaseModule } from 'src/@omnial/material.base.module';
import { PipesModule } from '../../_pipes/pipes.module';
import { TopicContentComponent } from './topic-content.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialBaseModule,
    PipesModule
  ],
  declarations: [TopicContentComponent],
  exports: [TopicContentComponent]
})
export class TopicContentModule { }
