import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MaterialBaseModule } from 'src/@omnial/material.base.module';
import { MaterialExtendModule } from 'src/@omnial/material.extend.module';
import { ProductZoomComponent } from './product-zoom.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialBaseModule,
    MaterialExtendModule,
    LazyLoadImageModule,
  ],
  declarations: [ProductZoomComponent],
  exports: [ProductZoomComponent]
})
export class ProductZoomModule { }
