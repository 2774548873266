import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, Observable } from 'rxjs';
import { Customer } from 'src/@omnial/_models/customer/customer.model';
import { Country } from 'src/@omnial/_models/order/country.model';
import { CreditCard } from 'src/@omnial/_models/order/credit-card.model';
import { PaymentResult } from 'src/@omnial/_models/order/payment-result.model';
import { Payment } from 'src/@omnial/_models/order/payment.model';
import { ShippingOption } from 'src/@omnial/_models/order/shipping-option.model';
import { environment } from 'src/environments/environment';
import { CustomerService } from '../customer/customer.service';
import { KlaviyoService } from '../external/klaviyo.service';
import { RepositoryStaticService } from '../repository-static.service';
import { RepositoryService } from '../repository.service';
import { AppSettings } from 'src/app/app.settings';


@Injectable()
export class CheckoutService implements OnDestroy {
  private subscriptions: Subscription[] = [];
  private useCache = environment.useCache;

  constructor(
    public staticService: RepositoryStaticService,
    public repoService: RepositoryService,
    public customerService: CustomerService,
    public snackBar: MatSnackBar,
    public klaviyoService: KlaviyoService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    public settings: AppSettings) {
    this.subscriptions.push(this.route.fragment.subscribe({
      next: (fragment: string) => {
        if (fragment && fragment === 'CacheBust') {
          this.useCache = false;
        }
      }
    }));
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe(); });
    }
  }

  public startCheckout(customer: Customer): void {
    this.klaviyoService.startCheckout(customer);
  }

  public getAllowableCountries(shipping: boolean, bypassCache?: boolean): Observable<Country[]> {
    return new Observable((observer) => {
      if (this.useCache && !bypassCache) {
        this.subscriptions.push(this.staticService.getByBoolean('Checkout/Countries', shipping).subscribe({
          next: (staticRes) => {
            if (staticRes) {
              observer.next(staticRes as Country[]);
              observer.complete();
            } else {
              this.subscriptions.push(this.repoService.getData(`Checkout/Countries/${shipping}`).subscribe({
                next: (apiRes) => {
                  observer.next(apiRes as Country[]);
                  observer.complete();
                },
                error: (e) => {
                  observer.error(e);
                  observer.complete();
                }
              }));
            }
          },
          error: () => {
            this.subscriptions.push(this.repoService.getData(`Checkout/Countries/${shipping}`).subscribe({
              next: (apiRes) => {
                observer.next(apiRes as Country[]);
                observer.complete();
              },
              error: (e) => {
                observer.error(e);
                observer.complete();
              }
            }));
          }
        }));
      } else {
        this.subscriptions.push(this.repoService.getData(`Checkout/Countries/${shipping}`).subscribe({
          next: (res) => {
            observer.next(res as Country[]);
            observer.complete();
          },
          error: (e) => {
            observer.error(e);
            observer.complete();
          }
        }));
      }
    });
  }

  public ghostCreate(customerGuid: string): Observable<Customer> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.getData(`Checkout/GhostCreate/${customerGuid}`).subscribe({
        next: (res) => {
          observer.next(res as Customer);
          observer.complete();
        },
        error: (e) => {
          observer.error(e);
          observer.complete();
        }
      }));
    });
  }

  public shippingOptions(customerGuid: string): Observable<ShippingOption[]> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.getData(`Checkout/ShippingOptions/${customerGuid}`).subscribe({
        next: (res) => {
          observer.next(res as ShippingOption[]);
          observer.complete();
        },
        error: (e) => {
          observer.error(e);
          observer.complete();
        }
      }));
    });
  }

  public paymentOptions(customerGuid: string): Observable<Payment> {
    return new Observable((observer) => {
      if (this.useCache) {
        this.subscriptions.push(this.staticService.get('Checkout/PaymentOptions').subscribe({
          next: (staticRes) => {
            if (staticRes) {
              const paymentOptions = staticRes as Payment;
              observer.next(this.fleshPaymentOptions(paymentOptions));
              observer.complete();
            } else {
              this.spinner.show();
              this.subscriptions.push(this.repoService.getData(`Checkout/PaymentOptions/${customerGuid}`).subscribe({
                next: (apiRes) => {
                  this.spinner.hide();
                  const paymentOptions = apiRes as Payment;
                  observer.next(this.fleshPaymentOptions(paymentOptions));
                  observer.complete();
                },
                error: (e) => {
                  this.spinner.hide();
                  const errMessage = 'Sorry we could get the payment options for your order. Please try again later.';
                  this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 3000 });
                  observer.error(e);
                  observer.complete();
                }
              }));
            }
          }
        }));
      } else {
        this.spinner.show();
        this.subscriptions.push(this.repoService.getData(`Checkout/PaymentOptions/${customerGuid}`).subscribe({
          next: (res) => {
            this.spinner.hide();
            const paymentOptions = res as Payment;
            observer.next(this.fleshPaymentOptions(paymentOptions));
            observer.complete();
          },
          error: (e) => {
            this.spinner.hide();
            const errMessage = 'Sorry we could get the payment options for your order. Please try again later.';
            this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 3000 });
            observer.error(e);
            observer.complete();
          }
        }));
      }
    });
  }

  private fleshPaymentOptions(payment: Payment) {
    // Flesh out the options with logos from the front end assets and a display order from the settings
    if (payment.paymentMethods) {
      payment.paymentMethods.forEach((method) => {
        method.logoUrl = `/assets/images/payments/logos/${method.paymentMethodSystemName}.jpg`;
        method.logoUrlActive = `/assets/images/payments/logos/${method.paymentMethodSystemName}-Active.jpg`;
        method.mobileLogoUrl = `/assets/images/payments/logos/${method.paymentMethodSystemName}-Mobile.jpg`;
        method.mobileLogoUrlActive = `/assets/images/payments/logos/${method.paymentMethodSystemName}-Mobile-Active.jpg`;
      });
    }
    let count = 10;
    if (this.settings.paymentOrder?.length > 0) {
      this.settings.paymentOrder.forEach((paymentOrder) => {
        const option = payment.paymentMethods.find(m => m.paymentMethodSystemName === paymentOrder);
        if (option) {
          option.displayOrder = count;
          count += 10;
        } else {
          option.displayOrder = 9999;
        }
      });
    }
    payment.paymentMethods = payment.paymentMethods.sort((n1, n2) => {
      if (n1.displayOrder > n2.displayOrder) {
        return 1;
      }
      if (n1.displayOrder < n2.displayOrder) {
        return -1;
      }
      return 0;
    });
    return payment;
  }

  public updateCustomerPaymentOption(chosenMethod: string, customerGuid: string): void {
    this.subscriptions.push(this.repoService.update(`Checkout/SelectPayment/${customerGuid}`, JSON.stringify(chosenMethod)).subscribe({
      next: (res) => {
        this.customerService.update(res as Customer);
      },
      error: (e) => {
        const errMessage = 'Error saving customer payment option. ' + e;
        this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 6000 });
      }
    }));
  }

  public submitPayment(card: CreditCard, customerGuid: string): Observable<PaymentResult> {
    this.spinner.show();
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.create(`Checkout/Payment/${customerGuid}`, card).subscribe({
        next: (res) => {
          this.spinner.hide();
          const result = res as PaymentResult;
          if (result.success) {
            this.klaviyoService.placedOrder(result.order);
          }
          observer.next(result);
          observer.complete();
        },
        error: (e) => {
          this.spinner.hide();
          const errMessage = 'Sorry there was an error processing you payment. Please feel fee to contact us as. This error will be recorded for our reference. ' + e;
          this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 9000 });
          observer.error(e);
          observer.complete();
        }
      }));
    });
  }
}
