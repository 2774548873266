import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class BreadCrumbService {

  private endNode = new BehaviorSubject(null);
  currentEndNode = this.endNode.asObservable();

  constructor() { }

  public setEndNode(title: string): void {
    setTimeout(() => { this.endNode.next(title); });
  }
}
