import { ProductFilterGroup } from "./product-filter.model";

export class Manufacturer {
  id: number;
  name: string;
  seName: string;
  description: string;
  manufacturerTemplateId: number;
  metaKeywords: string;
  metaDescription: string;
  metaTitle: string;
  pictureId: number;
  pictureUrl: string;
  displayOrder: number;
  products: number;
  public filterGroups: ProductFilterGroup[];
  published: boolean;
}
