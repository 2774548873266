import { Review } from "../customer/review.model";
import { Discount } from "../order/discount.model";
import { Manufacturer } from "./manufacturer.model";
import { OmnialImage } from "./omnial-image.model";
import { OmnialVideo } from "./omnial-video.moel";
import { ProductAttribute } from "./product-attribute.model";

export class Product {
  searchScore: number;

  oldPrice: number;
  newPrice: number;
  fromPrice: number;
  discount: number;
  valuePrice: number;
  saveAmount: number;
  installmentPrice: number;

  oldPriceDisplay: string;
  newPriceDisplay: string;
  fromPriceDisplay: string;
  discountDisplay: string;
  valuePriceDisplay: string;
  saveAmountDisplay: number;
  installmentPriceDisplay: number;

  listId: string;
  listName: string;

  savePercent: number;
  id: number;
  sku: string;
  name: string;
  seName: string;
  holdingImage: OmnialImage;
  images: Array<OmnialImage>;
  videos: Array<OmnialVideo>;
  defaultImages: OmnialImage;
  currency: string;
  ratingsCount: number;
  ratingsValue: number;
  description: string;
  categoryId: number;
  isFeatured: boolean;
  availability: string;
  stockQuantity: number;
  cartCount: number; // The number already in the cart
  cartQuantity: number; // The number to be added to the cart
  hasAttributes: boolean; // We will know if this is a Bundle
  attributeInfo: string; // For display in the cart
  attributeSummary: string; // For alt display in the cart
  attributeXml: string; // For increment, decrement from the cart
  categoryIds: number[];
  canonical: string;
  multiBuy: boolean;
  isInStock: boolean;
  lineTotal: number;
  lineTotalString: string;
  manufacturer: Manufacturer;
  canonicalCategoryId: number;
  productTemplateId: number;
  position: number;
  createdOn: number;
  specifications: ProductSpecification[];
}

export class ProductDetail {
  oldPrice: number;
  newPrice: number;
  fromPrice: number;
  discount: number; // TODO
  valuePrice: number;
  saveAmount: number;
  installmentPrice: number;
  adjustedPrice: number;
  adjustedInstallmentPrice: number;
  priceAdjustment: number;

  oldPriceDisplay: string;
  newPriceDisplay: string;
  fromPriceDisplay: string;
  discountDisplay: string;
  valuePriceDisplay: string;
  saveAmountDisplay: string;
  installmentPriceDisplay: string;
  adjustedPriceDisplay: string;
  adjustedInstallmentPriceDisplay: string;
  priceAdjustmentDisplay: string;

  listId: string;
  listName: string;

  savePercent: number;
  priceAdjustmentUsePercentage: boolean;
  id: number;
  sku: string;
  gtin: string;
  name: string;
  seName: string;
  images: Array<OmnialImage>;
  currency: string;
  ratingsCount: number;
  ratingsValue: number;
  reviews: Review[];
  shortDescription: string;
  fullDescription: string;
  disableBuyButton: boolean;
  disableWishlistButton: boolean;
  markAsNew: boolean;
  productType: string;
  stockQuantity: number;
  availability: string;
  weight: number;
  manufacturerName: string;
  canonicalCategoryId: number;
  categoryTemplateId: number;
  attributes: ProductAttribute[];
  cartQuantity: number; // The number to be added to the cart
  hasAttributes: boolean; // We will know if this is a Bundle
  attributeInfo: string; // For display in the cart
  attributeSummary: string; // For alt display in the cart
  attributeXml: string; // For increment, decrement from the cart
  categoryIds: number[];
  canonical: string;
  multiBuy: boolean;
  tierPrices: TierPrice[];
  manufacturer: Manufacturer;
  allowBackInStockSubscriptions: boolean;
  youTubeVideoAttributes: string[];
  videos: OmnialVideo[];
  tabs: ProductTab[];

  selectAttributes: SelectAttribute[];
  specifications: ProductSpecification[];
  discounts: Discount[];
  published: boolean;
}

export class SelectAttribute {
  attributeId: number;
  optionId: number;
  selected: boolean;
  name: string;
  content: string;
  noSelected: number; // For MultiSelect customisations
  productId: number; // For MultiSelect customisations
}

export class ProductOption {
  id: number;
  productAttributeMappingId: number;
  attributeValueTypeId: number;
  associatedProductId: number;
  name: string;
  colorSquaresRgb: string;
  imageSquaresPictureId: number;
  afterpayPrice: number;
  priceAdjustment: number;
  priceAdjustmentUsePercentage: boolean;
  weightAdjustment: number;
  cost: number;
  customerEntersQty: boolean;
  quantity: number;
  isPreSelected: boolean;
  displayOrder: number;
  pictureId: number;
  attributeValueType: number;
  valueType: string;
  selected: boolean;
}

export class TierPrice {
  productId: number;
  storeId: number;
  customerRoleId: number;
  quantity: number;
  price: number;
  startDate: string;
  endDate: string;
}

export class ProductTab {
  systemName: string;
  displayName: string;
  description: string;
  displayOrder: number;
}

export class ProductSpecification {
  id: number;
  specificationAttributeId: number;
  specificationAttributeName: string;
  valueRaw: string;
  colorSquaresRgb: string;
  pictureId: number;
  imageUrl: string;
  displayOrder: number;
  attributeTypeId: number;
}

export class ProductSlide {
  imgUrl: string;
  index: number;
  imageIndex: number;
  youTubeRef: string;
  isVideo: boolean;
  alt: string;
}
