<div class="bread-crumb-desktop">
  <div class="flex-100">
    <mat-card class="d-flex flex-row bread-crumb" color="accent">
      <div class="bread-crumb-item bread-crumb-item-home">
        <a [routerLink]="['/']" title="Home Page">
          <mat-icon>home</mat-icon>
        </a>
      </div>
      <div *ngFor="let breadcrumb of breadcrumbs" class="d-flex flex-row bread-crumb-item justify-content-start align-items-center">
        <a [@fadeInRight] *ngIf="breadcrumb.link" [routerLink]="[breadcrumb.link]">{{breadcrumb.title}}</a>
        <a [@fadeInRight] *ngIf="!breadcrumb.link && breadcrumb.routerLink" [routerLink]="[breadcrumb.routerLink]">{{breadcrumb.title}}</a>
      </div>
      <div *ngIf="localEndNode" class="d-flex flex-row bread-crumb-item justify-content-start align-items-center">
        <span [@fadeInRight] class="bread-crumb-title active">{{localEndNode}}</span>
      </div>
    </mat-card>
  </div>
</div>
<div class="d-flex flex-row bread-crumb-mobile">
  <ul class="bread-crumb-mobile-container">
    <li [@fadeInRight] class="bread-crumb-mobile-item  bread-crumb-mobile-item-home">
      <a [routerLink]="['/']" title="Home Page">
        <mat-icon class="mat-icon-small">home</mat-icon>
      </a>
    </li>
    <li [@fadeInRight] *ngFor="let breadcrumb of breadcrumbs" class="bread-crumb-mobile-item">
      <a *ngIf="breadcrumb.link" [routerLink]="[breadcrumb.link]">{{breadcrumb.title}}</a>
      <a *ngIf="!breadcrumb.link && breadcrumb.routerLink" [routerLink]="[breadcrumb.routerLink]">{{breadcrumb.title}}</a>
    </li>
    <li [@fadeInRight] *ngIf="localEndNode" class="bread-crumb-mobile-item">
      <span class="bread-crumb-title active">{{localEndNode}}</span>
    </li>
  </ul>
</div>
