import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { RenderType } from "../_models/render.type.model";

@Injectable()
export class RenderService implements OnDestroy {
  public renderType: BehaviorSubject<RenderType> = new BehaviorSubject<RenderType>(null);
  private $renderType: RenderType = null;
  private subscriptions: Subscription[] = [];

  constructor(public http: HttpClient) { }

  public load(): void {
    if (!this.$renderType) {
      this.subscriptions.push(this.http.get(environment.renderCheckUrl + '?t=' + new Date().getTime()).subscribe({
        next: (res: any) => {
          this.$renderType = res.renderType;
          if (this.$renderType === 'preRender') {
            console.log('Omnial - Pre Render Site used for search engines, social sites and core web vitals.')
          } else {
            console.log('Omnial - Application Site used for front end user access.')
          }
          this.renderType.next(this.$renderType);
        },
        error: (e) => {
          this.$renderType = 'application';
          this.renderType.next(this.$renderType);
        }
      }));
    } else{
      this.renderType.next(null);
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe() });
    }
  }
}
