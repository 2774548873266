import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProductDetail, Product } from 'src/@omnial/_models/catalog/product.model';
import { Customer } from 'src/@omnial/_models/customer/customer.model';
import { KlaviyoCustomer, KlaviyoStartedCheckout, KlaviyoProductDetails, KlaviyoTrackViewedItem, KlaviyoTrackViewedItemMeta, KlaviyoAddToCartInformation, KlaviyoCartItem } from 'src/@omnial/_models/external/klaviyo.model';
import { SiteMapItem } from 'src/@omnial/_models/navigation/site-map.model';
import { Order } from 'src/@omnial/_models/order/order.model';
import { environment } from 'src/environments/environment';
import { SiteMapService } from '../navigation/sitemap.service';
import { RepositoryService } from '../repository.service';
import { ScriptLoaderService } from './script-load.service';

declare var _learnq: any;

@Injectable()
export class KlaviyoService implements OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    public scriptLoaderService: ScriptLoaderService,
    private sitemapService: SiteMapService,
    private repoService: RepositoryService) { }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe(); });
    }
  }

  public identify(result: Customer): void {
    if (!result?.email) {
      return;
    }
    const customer = result.shippingAddress;
    const customerData = new KlaviyoCustomer();
    customerData.$email = result.email;
    customerData.$first_name = customer?.firstName;
    customerData.$last_name = customer?.lastName;
    customerData.$phone_number = customer?.phoneNumber;
    customerData.$address1 = customer?.address1;
    customerData.$address2 = customer?.address2;
    customerData.$city = customer?.city;
    customerData.$region = customer?.state;
    customerData.$country = customer?.country;
    customerData.$organization = customer?.company;
    customerData.$zip = customer?.zipPostalCode;
    this.pushToKlaviyo('', customerData, 'identify');
  }

  public startCheckout(customer: Customer): void {
    if (!customer) {
      return;
    }
    if (!customer.cartList) {
      return;
    }
    if (customer.cartList.length === 0) {
      return;
    }
    this.subscriptions.push(this.sitemapService.siteMap.subscribe({
      next: (res) => {
        const sitemap = res as SiteMapItem[];
        if (sitemap && sitemap.length > 0) {
          // Build a product detail for the categories of ALL the cart items
          const product = new ProductDetail();
          product.categoryIds = [];
          for (const cartItem of customer.cartList) {
            if (cartItem.categoryIds && cartItem.categoryIds.length > 0) {
              for (const catId of cartItem.categoryIds) {
                const existing = product.categoryIds.find(i => i === catId);
                if (!existing) {
                  product.categoryIds.push(catId);
                }
              }
            }
          }
          const categories = this.generateCategories(product, sitemap);
          const cartItems = this.getCartItems(customer, sitemap);
          const trackCheckout = new KlaviyoStartedCheckout();
          trackCheckout.$event_id = Date.now();
          trackCheckout.$value = customer?.orderTotals?.orderTotal;
          trackCheckout.ItemNames = cartItems.cartItemNames;
          trackCheckout.CheckoutURL = `${environment.baseUrl}/checkout`;
          trackCheckout.Categories = categories;
          trackCheckout.Items = cartItems.cartItems;
          this.pushToKlaviyo('Started Checkout', trackCheckout, 'track');
        }
      }
    }));
  }

  public viewProduct(product: ProductDetail, url: string): void {
    if (!product) {
      return;
    }
    this.subscriptions.push(this.sitemapService.siteMap.subscribe({
      next: (res) => {
        const sitemap = res as SiteMapItem[];
        if (sitemap && sitemap.length > 0) {
          const categories = this.generateCategories(product, sitemap);
          const productDetails = new KlaviyoProductDetails();
          productDetails.ProductName = product.name;
          productDetails.ProductID = product.id;
          productDetails.Categories = categories;
          productDetails.ImageURL = product.images && product.images.length > 0 ? product.images[0].medium : '';
          productDetails.URL = url;
          productDetails.Brand = product.manufacturer?.name;
          productDetails.Price = product.newPrice;
          productDetails.CompareAtPrice = product.oldPrice;
          this.pushToKlaviyo('Viewed Product', productDetails, 'track');

          const viewedItem = new KlaviyoTrackViewedItem();
          viewedItem.Title = productDetails.ProductName;
          viewedItem.ItemId = productDetails.ProductID;
          viewedItem.Categories = categories;
          viewedItem.ImageUrl = productDetails.ImageURL;
          viewedItem.Url = productDetails.URL;
          viewedItem.Metadata = new KlaviyoTrackViewedItemMeta();
          viewedItem.Metadata.Brand = productDetails.Brand;
          viewedItem.Metadata.Price = productDetails.Price;
          viewedItem.Metadata.CompareAtPrice = productDetails.CompareAtPrice;
          this.pushToKlaviyo('', viewedItem, 'trackViewedItem'); // ... TODO this throws an error, need to contact Klayvio support
        }
      }
    }));
  }

  public addCartDetail(product: ProductDetail, quantity: number, customer: Customer): void {
    this.addToCart(null, product, quantity, customer);
  }

  public addCart(product: Product, quantity: number, customer: Customer): void {
    this.addToCart(product, null, quantity, customer);
  }

  public placedOrder(order: Order): void {
    if (order && environment.klayvioActive) {
      this.subscriptions.push(this.repoService.create('Account/Klaviyo/TrackOrder', order).subscribe());
    }
  }

  private addToCart(productData: Product, productDetail: ProductDetail, quantity: number, customer: Customer): void {
    if (!productData && !productDetail) {
      return;
    }
    this.subscriptions.push(this.sitemapService.siteMap.subscribe({
      next: (res) => {
        const sitemap = res as SiteMapItem[];
        if (sitemap && sitemap.length > 0) {
          const categories = this.generateCategories(productDetail, sitemap);
          let product: any;
          product = productData;
          if (productDetail) {
            product = productDetail;
          }
          const items = this.getCartItems(customer, sitemap);
          const cartData = new KlaviyoAddToCartInformation();
          cartData.$value = product.newPrice;
          cartData.AddedItemProductName = product.name;
          cartData.AddedItemProductID = product.id;
          cartData.AddedItemSKU = product.sku;
          cartData.AddedItemCategories = categories;
          cartData.AddedItemImageURL = product.images && product.images.length > 0 ? product.images[0].medium : '';
          cartData.AddedItemURL = environment.baseUrl + this.router.url;
          cartData.AddedItemPrice = product.newPrice;
          cartData.AddedItemQuantity = quantity;
          cartData.ItemNames = items.cartItemNames;
          cartData.CheckoutURL = `${environment.baseUrl}/checkout`;
          cartData.Items = items.cartItems;
          this.pushToKlaviyo('Added to Cart', cartData, 'track');
        }
      }
    }));
  }

  private getCartItems(customer: Customer, sitemap: SiteMapItem[]): any {
    if (!customer) {
      return;
    }
    const items = [];
    const itemNames = [];
    customer.cartList.forEach(cartItem => {
      itemNames.push(cartItem.name);
      const orderItem = new KlaviyoCartItem();
      orderItem.ProductID = cartItem.id.toString();
      orderItem.SKU = cartItem.sku;
      orderItem.ProductName = cartItem.name;
      orderItem.Quantity = cartItem.cartCount;
      orderItem.ItemPrice = cartItem.newPrice;
      orderItem.RowTotal = cartItem.valuePrice * cartItem.cartCount;
      orderItem.ProductURL = `${environment.baseUrl}/products/${cartItem.seName}`;
      orderItem.ImageURL = cartItem.images && cartItem.images.length > 0 ? cartItem.images[0].medium : '';
      const product = new ProductDetail();
      product.categoryIds = cartItem.categoryIds;
      orderItem.Categories = this.generateCategories(product, sitemap);
      items.push(orderItem);
    });
    return { cartItems: items, cartItemNames: itemNames };
  }

  private pushToKlaviyo(event: string, object: any, type: string): void {
    if (!environment.klayvioActive) {
      return;
    }
    this.scriptLoaderService.load('Klayvio');
    this.subscriptions.push(this.scriptLoaderService.scripts.subscribe(
      res => {
        if (res && res.find(s => s.ref === 'Klayvio')?.loaded) {
          // Set timeout as there is a chain of scripts for Klaviyo to load
          setTimeout(() => {
            try {
              if (event) {
                _learnq.push([type, event, object]);
              } else {
                _learnq.push([type, object]);
              }
            }
            catch (e) {
              console.log('Klayvio Error');
              console.log(e);
            }
          }, 200);
        }
      }));
  }

  private generateCategories(product: ProductDetail, sitemap: SiteMapItem[]): string[] {
    const categories: string[] = [];
    if (!product) {
      return categories;
    }
    const categoryIds = product.categoryIds;
    if (categoryIds && categoryIds.length > 0) {
      for (const catId of categoryIds) {
        if (catId !== environment.rootCatId) {
          const cat = sitemap.find(i => i.categoryId === catId);
          if (cat) {
            categories.push(cat.title);
          }
        }
      }
    }
    return categories;
  }
}
