import { Manufacturer } from "../catalog/manufacturer.model";

export class OmnialMarker {
  dealerId: number;
  lat: number;
  lng: number;
  position: google.maps.LatLng;
  draggable: boolean;
  title: string;
  icon: string;
  street: string;
  city: string;
  state: string;
  postalCode: string;
  email: string;
  phone: string;
  website: string;
  detail?: string;
  distance: number;
  manufacturers: Manufacturer[];
  pin: string;
}
