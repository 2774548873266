<div class="top-menu hide-print" [style.overflow]="menuOverflow" [style.position]="menuPosition" [style.background]="menuBackground">
  <mat-toolbar color="primary" class="hide-print">
    <mat-toolbar-row class="d-flex flex-row flex-wrap justify-content-between align-items-center">
      <div class="flex-20 justify-content-start align-items-center">
        <span>
          <button mat-button color="primary" (click)="inputSideNav.toggle()" class="sidenav-toggle menu menu-button" name="menu" [attr.aria-label]="'Menu'">
            <mat-icon>menu</mat-icon>
          </button>
        </span>
      </div>
      <div class="flex-20 justify-content-start align-items-center">
        <a routerLink="/" (click)="closeSubMenus()" class="logo-link">
          <img src="assets/images/logo/logo-white.svg" alt="{{settings?.companyName}}" class="logo" />
        </a>
      </div>
      <div class="flex-60 d-flex flex-row flex-wrap justify-content-end align-items-center mobile-header-links">
        <div class="flex-20 flex-fill">
          <a [routerLink]="'/search'" class="search-link" title="Search">
            <mat-icon class="search-button">search</mat-icon>
          </a>
        </div>
        <div *ngIf="settings?.socialLinks?.youtube" class="flex-20 flex-fill">
          <a rel="noreferrer" target="_blank" [href]="settings?.socialLinks?.youtube">
            <img src="/assets/images/social/youtube-small.png" alt="Youtube">
          </a>
        </div>
        <div *ngIf="settings?.socialLinks?.facebook" class="flex-20 flex-fill">
          <a rel="noreferrer" target="_blank" [href]="settings?.socialLinks?.facebook">
            <img src="/assets/images/social/facebook-small.png" alt="Youtube">
          </a>
        </div>
        <div *ngIf="settings?.socialLinks?.instagram" class="flex-20 flex-fill">
          <a rel="noreferrer" target="_blank" [href]="settings?.socialLinks?.instagram">
            <img src="/assets/images/social/instagram-small.png" alt="Youtube">
          </a>
        </div>
        <div *ngIf="settings?.socialLinks?.linkedIn" class="flex-20 flex-fill">
          <a rel="noreferrer" target="_blank" [href]="settings?.socialLinks?.linkedIn">
            <img src="/assets/images/social/linkedin-small.png" alt="Youtube">
          </a>
        </div>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
