import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Blog } from 'src/@omnial/_models/catalog/blog.model';
import { InfoBox } from 'src/@omnial/_models/catalog/info-box.model';
import { ProductCollection } from 'src/@omnial/_models/catalog/product.collection.model';
import { Slider } from 'src/@omnial/_models/catalog/slider.model';
import { ThemeSettings } from 'src/@omnial/_models/catalog/theme-settings.model';
import { Menu } from 'src/@omnial/_models/navigation/menu.model';
import { environment } from 'src/environments/environment';
import { RepositoryStaticService } from '../repository-static.service';
import { RepositoryService } from '../repository.service';


@Injectable()
export class SevenSpikesService {
  private subscriptions: Subscription[] = [];
  private useCache = environment.useCache;
  constructor(
    public staticService: RepositoryStaticService,
    public repoService: RepositoryService,
    private route: ActivatedRoute) {
    this.route.fragment.subscribe((fragment: string) => {
      if (fragment && fragment === 'CacheBust') {
        this.useCache = false;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe(); });
    }
  }

  public getMenus(widgetZone: string, bypassCache?: boolean): Observable<Menu[]> {
    return new Observable((observer) => {
      if (this.useCache && !bypassCache) {
        this.subscriptions.push(this.getMenusFromCache(widgetZone).subscribe({
          next: (cacheResult) => {
            observer.next(cacheResult as Menu[]);
            observer.complete();
          }
        }));
      } else {
        this.subscriptions.push(this.getMenusFromNop(widgetZone).subscribe({
          next: (nopResult) => {
            observer.next(nopResult as Menu[]);
            observer.complete();
          }
        }));
      }
    });
  }

  public getSlides(widgetZone: string, url: string, bypassCache?: boolean): Observable<Slider[]> {
    return new Observable((observer) => {
      if (this.useCache && !bypassCache) {
        this.subscriptions.push(this.getSlidesFromCache(widgetZone, url).subscribe({
          next: (cacheResult) => {
            observer.next(cacheResult as Slider[]);
            observer.complete();
          }
        }));
      } else {
        this.subscriptions.push(this.getSlidesFromNop(widgetZone, url).subscribe({
          next: (nopResult) => {
            observer.next(nopResult as Slider[]);
            observer.complete();
          }
        }));
      }
    });
  }

  public getSlidesBySliderId(sliderId: number, bypassCache?: boolean): Observable<Slider[]> {
    const sliders: Slider[] = [];
    return new Observable((observer) => {
      if (this.useCache && !bypassCache) {
        this.subscriptions.push(this.getSlidesBySliderIdFromCache(sliderId).subscribe({
          next: (cacheResult) => {
            sliders.push(cacheResult);
            observer.next(sliders);
            observer.complete();
          }
        }));
      } else {
        this.subscriptions.push(this.getSlidesBySliderIdFromNop(sliderId).subscribe({
          next: (nopResult) => {
            sliders.push(nopResult);
            observer.next(sliders);
            observer.complete();
          }
        }));
      }
    });
  }

  public getProductCollections(widgetZone: string, bypassCache?: boolean): Observable<ProductCollection[]> {
    return new Observable((observer) => {
      if (this.useCache && !bypassCache) {
        this.subscriptions.push(this.getCollectionsFromCache(widgetZone).subscribe({
          next: (cacheResult) => {
            observer.next(cacheResult as ProductCollection[]);
            observer.complete();
          }
        }));
      } else {
        this.subscriptions.push(this.getCollectionsFromNop(widgetZone).subscribe({
          next: (nopResult) => {
            observer.next(nopResult as ProductCollection[]);
            observer.complete();
          }
        }));
      }
    });
  }

  public getProductCollectionById(collectionId: number, bypassCache?: boolean): Observable<ProductCollection[]> {
    const collections: ProductCollection[] = [];
    return new Observable((observer) => {
      if (this.useCache && !bypassCache) {
        this.subscriptions.push(this.getCollectionFromCache(collectionId).subscribe({
          next: (cacheResult) => {
            collections.push(cacheResult as ProductCollection);
            observer.next(collections);
            observer.complete();
          }
        }));
      } else {
        this.subscriptions.push(this.getCollectionFromNop(collectionId).subscribe({
          next: (nopResult) => {
            collections.push(nopResult as ProductCollection);
            observer.next(collections);
            observer.complete();
          }
        }));
      }
    });
  }

  public getBlog(bypassCache?: boolean): Observable<Blog> {
    return new Observable((observer) => {
      if (this.useCache && !bypassCache) {
        this.subscriptions.push(this.getBlogFromCache().subscribe({
          next: (cacheResult) => {
            observer.next(cacheResult as Blog);
            observer.complete();
          }
        }));
      } else {
        this.subscriptions.push(this.getBlogFromNop().subscribe({
          next: (nopResult) => {
            observer.next(nopResult as Blog);
            observer.complete();
          }
        }));
      }
    });
  }

  public getInfoBoxes(bypassCache?: boolean): Observable<InfoBox[]> {
    return new Observable((observer) => {
      if (this.useCache && !bypassCache) {
        this.subscriptions.push(this.getInfoBoxesFromCache().subscribe({
          next: (cacheResult) => {
            observer.next(cacheResult as InfoBox[]);
            observer.complete();
          }
        }));
      } else {
        this.subscriptions.push(this.getInfoBoxesFromNop().subscribe({
          next: (nopResult) => {
            observer.next(nopResult as InfoBox[]);
            observer.complete();
          }
        }));
      }
    });
  }

  public getThemeSettings(bypassCache?: boolean): Observable<ThemeSettings> {
    return new Observable((observer) => {
      if (this.useCache && !bypassCache) {
        this.subscriptions.push(this.getThemeSettingsFromCache().subscribe({
          next: (cacheResult) => {
            observer.next(cacheResult as ThemeSettings);
            observer.complete();
          }
        }));
      } else {
        this.subscriptions.push(this.getThemeSettingsFromNop().subscribe({
          next: (nopResult) => {
            observer.next(nopResult as ThemeSettings);
            observer.complete();
          }
        }));
      }
    });
  }

  private getMenusFromCache(widgetZone: string): Observable<Menu[]> {
    return new Observable((observer) => {
      this.subscriptions.push(this.staticService.getMenus(widgetZone).subscribe({
        next: (cacheResult) => {
          if (cacheResult) {
            observer.next(cacheResult as Menu[]);
            observer.complete();
          } else {
            this.subscriptions.push(this.getMenusFromNop(widgetZone).subscribe({
              next: (nopResult) => {
                observer.next(nopResult as Menu[]);
                observer.complete();
              }
            }));
          }
        },
        error: (e) => {
          this.subscriptions.push(this.getMenusFromNop(widgetZone).subscribe({
            next: (nopResult) => {
              observer.next(nopResult as Menu[]);
              observer.complete();
            }
          }));
        }
      }));
    });
  }

  private getMenusFromNop(widgetZone: string): Observable<Menu[]> {
    return new Observable((observer) => {
      let endpoint = `SevenSpikes/Menus/${widgetZone}`;
      this.subscriptions.push(this.repoService.getData(endpoint).subscribe({
        next: (res) => {
          observer.next(res as Menu[]);
          observer.complete();
        },
        error: (e) => {
          observer.error(e);
          observer.complete();
        }
      }));
    });
  }

  private getSlidesFromCache(widgetZone: string, url: string): Observable<Slider[]> {
    return new Observable((observer) => {
      this.subscriptions.push(this.staticService.getSliders(widgetZone, url).subscribe({
        next: (cacheResult) => {
          if (cacheResult) {
            observer.next(cacheResult as Slider[]);
            observer.complete();
          } else {
            this.subscriptions.push(this.getSlidesFromNop(widgetZone, url).subscribe({
              next: (nopResult) => {
                observer.next(nopResult as Slider[]);
                observer.complete();
              }
            }));
          }
        },
        error: (e) => {
          this.subscriptions.push(this.getSlidesFromNop(widgetZone, url).subscribe({
            next: (nopResult) => {
              observer.next(nopResult as Slider[]);
              observer.complete();
            }
          }));
        }
      }));
    });
  }

  private getSlidesBySliderIdFromCache(sliderId: number): Observable<Slider> {
    return new Observable((observer) => {
      this.subscriptions.push(this.staticService.get(`Generic/Key/slider-${sliderId}`).subscribe({
        next: (cacheResult) => {
          if (cacheResult) {
            observer.next(cacheResult as Slider);
            observer.complete();
          } else {
            this.subscriptions.push(this.getSlidesBySliderIdFromNop(sliderId).subscribe({
              next: (nopResult) => {
                observer.next(nopResult as Slider);
                observer.complete();
              }
            }));
          }
        },
        error: (e) => {
          this.subscriptions.push(this.getSlidesBySliderIdFromNop(sliderId).subscribe({
            next: (nopResult) => {
              observer.next(nopResult as Slider);
              observer.complete();
            }
          }));
        }
      }));
    });
  }

  private getSlidesFromNop(widgetZone: string, url: string): Observable<Slider[]> {
    return new Observable((observer) => {
      let endpoint = `SevenSpikes/Sliders/${widgetZone}`;
      if (url) {
        endpoint = `SevenSpikes/Sliders/${widgetZone}/${url}`
      }
      this.subscriptions.push(this.repoService.getData(endpoint).subscribe({
        next: (res) => {
          observer.next(res as Slider[]);
          observer.complete();
        },
        error: (e) => {
          observer.error(e);
          observer.complete();
        }
      }));
    });
  }

  private getSlidesBySliderIdFromNop(sliderId: number): Observable<Slider> {
    return new Observable((observer) => {
      let endpoint = `SevenSpikes/Slider/${sliderId}`;
      this.subscriptions.push(this.repoService.getData(endpoint).subscribe({
        next: (res) => {
          observer.next(res as Slider);
          observer.complete();
        },
        error: (e) => {
          observer.error(e);
          observer.complete();
        }
      }));
    });
  }

  private getCollectionsFromCache(widgetZone: string): Observable<ProductCollection[]> {
    return new Observable((observer) => {
      this.subscriptions.push(this.staticService.getProductCollections(widgetZone).subscribe({
        next: (cacheResult) => {
          if (cacheResult) {
            observer.next(cacheResult as ProductCollection[]);
            observer.complete();
          } else {
            this.subscriptions.push(this.getCollectionsFromNop(widgetZone).subscribe(
              nopResult => {
                observer.next(nopResult as ProductCollection[]);
                observer.complete();
              }));
          }
        },
        error: (e) => {
          this.subscriptions.push(this.getCollectionsFromNop(widgetZone).subscribe(
            nopResult => {
              observer.next(nopResult as ProductCollection[]);
              observer.complete();
            }));
        }
      }));
    });
  }

  private getCollectionsFromNop(widgetZone: string): Observable<ProductCollection[]> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.getData(`SevenSpikes/ProductCollections/${widgetZone}`).subscribe({
        next: (res) => {
          observer.next(res as ProductCollection[]);
          observer.complete();
        },
        error: (e) => {
          observer.error(e);
          observer.complete();
        }
      }));
    });
  }

  private getCollectionFromCache(collectionId: number): Observable<ProductCollection> {
    return new Observable((observer) => {
      this.subscriptions.push(this.staticService.get(`Generic/Key/collection-${collectionId}`).subscribe({
        next: (cacheResult) => {
          if (cacheResult) {
            observer.next(cacheResult as ProductCollection);
            observer.complete();
          } else {
            this.subscriptions.push(this.getCollectionFromNop(collectionId).subscribe(
              nopResult => {
                observer.next(nopResult as ProductCollection);
                observer.complete();
              }));
          }
        },
        error: (e) => {
          this.subscriptions.push(this.getCollectionFromNop(collectionId).subscribe(
            nopResult => {
              observer.next(nopResult as ProductCollection);
              observer.complete();
            }));
        }
      }));
    });
  }

  private getCollectionFromNop(collectionId: number): Observable<ProductCollection> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.getData(`SevenSpikes/ProductCollection/${collectionId}`).subscribe({
        next: (res) => {
          observer.next(res as ProductCollection);
          observer.complete();
        },
        error: (e) => {
          observer.error(e);
          observer.complete();
        }
      }));
    });
  }

  private getInfoBoxesFromCache(): Observable<InfoBox[]> {
    return new Observable((observer) => {
      this.subscriptions.push(this.staticService.getInfoBoxes().subscribe({
        next: (cacheResult) => {
          if (cacheResult) {
            observer.next(cacheResult as InfoBox[]);
            observer.complete();
          } else {
            this.subscriptions.push(this.getInfoBoxesFromNop().subscribe({
              next: (nopResult) => {
                observer.next(nopResult as InfoBox[]);
                observer.complete();
              }
            }));
          }
        },
        error: (e) => {
          this.subscriptions.push(this.getInfoBoxesFromNop().subscribe({
            next: (nopResult) => {
              observer.next(nopResult as InfoBox[]);
              observer.complete();
            }
          }));
        }
      }));
    });
  }

  private getInfoBoxesFromNop(): Observable<InfoBox[]> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.getData(`SevenSpikes/InfoBoxes`).subscribe({
        next: (res) => {
          observer.next(res as InfoBox[]);
          observer.complete();
        },
        error: (e) => {
          observer.error(e);
          observer.complete();
        }
      }));
    });
  }

  private getThemeSettingsFromCache(): Observable<ThemeSettings> {
    return new Observable((observer) => {
      this.subscriptions.push(this.staticService.getThemeSettings().subscribe({
        next: (cacheResult) => {
          if (cacheResult) {
            observer.next(cacheResult as ThemeSettings);
            observer.complete();
          } else {
            this.subscriptions.push(this.getThemeSettingsFromNop().subscribe({
              next: (nopResult) => {
                observer.next(nopResult as ThemeSettings);
                observer.complete();
              }
            }));
          }
        },
        error: (e) => {
          this.subscriptions.push(this.getThemeSettingsFromNop().subscribe({
            next: (nopResult) => {
              observer.next(nopResult as ThemeSettings);
              observer.complete();
            }
          }));
        }
      }));
    });
  }

  private getThemeSettingsFromNop(): Observable<ThemeSettings> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.getData(`SevenSpikes/Theme`).subscribe({
        next: (res) => {
          observer.next(res as ThemeSettings);
          observer.complete();
        },
        error: (e) => {
          observer.error(e);
          observer.complete();
        }
      }));
    });
  }

  private getBlogFromCache(): Observable<Blog> {
    return new Observable((observer) => {
      this.subscriptions.push(this.staticService.getBlog().subscribe({
        next: (cacheResult) => {
          if (cacheResult) {
            observer.next(cacheResult as Blog);
            observer.complete();
          } else {
            this.subscriptions.push(this.getBlogFromNop().subscribe(
              nopResult => {
                observer.next(nopResult as Blog);
                observer.complete();
              }));
          }
        },
        error: (e) => {
          this.subscriptions.push(this.getBlogFromNop().subscribe(
            nopResult => {
              observer.next(nopResult as Blog);
              observer.complete();
            }));
        }
      }));
    });
  }

  private getBlogFromNop(): Observable<Blog> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.getData('SevenSpikes/Blog').subscribe({
        next: (res) => {
          observer.next(res as Blog);
          observer.complete();
        },
        error: (e) => {
          observer.error(e);
          observer.complete();
        }
      }));
    });
  }
}
