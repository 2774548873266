import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Product, ProductDetail } from 'src/@omnial/_models/catalog/product.model';
import { Address } from 'src/@omnial/_models/customer/address.model';
import { Customer } from 'src/@omnial/_models/customer/customer.model';
import { Order } from 'src/@omnial/_models/order/order.model';
import { Shipment } from 'src/@omnial/_models/order/shipment.model';
import { AppSettings } from 'src/app/app.settings';
import { environment } from 'src/environments/environment';
import { KlaviyoService } from '../external/klaviyo.service';
import { RepositoryService } from '../repository.service';

@Injectable()
export class CustomerService implements OnDestroy {
  public customer: BehaviorSubject<Customer> = new BehaviorSubject<Customer>(null);
  private $customer: Customer = null;
  private subscriptions: Subscription[] = [];

  constructor(
    public repoService: RepositoryService,
    public settings: AppSettings,
    public snackBar: MatSnackBar,
    private klaviyoService: KlaviyoService,
    private spinner: NgxSpinnerService,
    private cookieService: CookieService) { }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe(); });
    }
  }

  public load(): void {
    const customerGuid = this.getCustomerGuid();
    if (customerGuid) {
      this.subscriptions.push(this.repoService.getData(`Customer/${customerGuid}`).subscribe({
        next: (res) => {
          if (res) {
            this.$customer = res as Customer;
            this.klaviyoService.identify(this.$customer);
            this.saveCustomerCookie(this.$customer);
            this.customer.next(res as Customer);
          } else {
            this.$customer = null;
            this.customer.next(null);
          }
        },
        error: () => {
          this.$customer = null;
          this.customer.next(null);
        }
      }));
    } else {
      this.$customer = null;
      this.customer.next(null);
    }
  }

  public update(customer: Customer): void {
    this.$customer = customer;
    this.customer.next(customer);
  }

  public currentCustomer(): Observable<Customer> {
    return new Observable((observer) => {
      if (this.$customer) {
        observer.next(this.$customer);
        observer.complete();
        return;
      }
      const customerGuid = this.getCustomerGuid();
      if (!customerGuid) {
        return;
      }
      this.subscriptions.push(this.repoService.getData(`Customer/${customerGuid}`).subscribe({
        next: (res) => {
          this.$customer = res as Customer;
          this.saveCustomerCookie(this.$customer);
          this.customer.next(this.$customer);
          observer.next(this.$customer);
          observer.complete();
        },
        error: (e) => {
          const message = 'Sorry we could not get your customer account, please try again later';
          this.snackBar.open(message, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 3000 });
          this.$customer = null;
          this.customer.next(null);
          observer.error(e);
          observer.complete();
        }
      }));
    });
  }

  public newCustomer(): Observable<Customer> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.create('Customer', null).subscribe({
        next: (res) => {
          this.$customer = res as Customer;
          this.saveCustomerCookie(this.$customer);
          this.customer.next(this.$customer);
          observer.next(this.$customer);
          observer.complete();
        },
        error: (e) => {
          const message = 'Sorry we could not create you a guest customer account, please try again later';
          this.snackBar.open(message, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 3000 });
          this.$customer = null;
          this.customer.next(null);
          observer.error(e);
          observer.complete();
        }
      }));
    });
  }

  public saveCustomer(customer: Customer): Observable<Customer> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.update('Customer', customer).subscribe({
        next: (res) => {
          this.$customer = res as Customer;
          this.klaviyoService.identify(this.$customer);
          this.saveCustomerCookie(this.$customer);
          this.customer.next(res as Customer);
          observer.next(this.$customer);
          observer.complete();
        },
        error: () => {
          const message = 'Sorry we could not save your customer account, please try again later';
          this.snackBar.open(message, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 3000 });
          this.$customer = null;
          this.customer.next(null);
          observer.next(null);
          observer.complete();
        }
      }));
    });
  }

  public getCustomerGuid(): string {
    try {
      if (this.cookieService.check(environment.customerCookieKey)) {
        localStorage.setItem(environment.customerCookieKey, this.cookieService.get(environment.customerCookieKey));
        return this.cookieService.get(environment.customerCookieKey);
      } else {
        setTimeout(() => {
          if (this.cookieService.check(environment.customerCookieKey)) {
            localStorage.setItem(environment.customerCookieKey, this.cookieService.get(environment.customerCookieKey));
          }
        }, 500);
        return localStorage.getItem(environment.customerCookieKey);
      }
    } catch (e) {
      return '';
    }
  }

  public checkCustomerCookie(): boolean {
    return this.cookieService.check(environment.customerCookieKey);
  }

  public clearCustomerCookies(): void {
    localStorage.removeItem(environment.customerCookieKey);
    const allCookies: { [key: string]: string } = this.cookieService.getAll();
    for (const key of Object.keys(allCookies)) {
      if (key === environment.customerCookieKey) {
        this.cookieService.delete(key);
      }
    }
  }

  public saveCustomerCookie(customer: Customer): void {
    if (customer) {
      this.$customer = customer;
      this.clearCustomerCookies();
      const now = new Date();
      const year = now.getFullYear() + 1;
      const month = now.getMonth();
      const day = now.getDate();
      const expires = new Date(year, month, day);
      try {
        this.cookieService.set(environment.customerCookieKey, customer.customerGuid, expires, null, environment.baseUrl, environment.cookieSecure, 'Strict');
        localStorage.setItem(environment.customerCookieKey, customer.customerGuid);
      } catch {
        // If we have no cookie access we are a little stuffed - here for server rendering
      }
    }
  }

  public setGrid(view: string): void {
    if (this.customer && this.$customer) {
      this.$customer.gridView = view;
      this.customer.next(this.$customer);
    }
  }

  public getOrders(): Observable<Order[]> {
    if (this.$customer?.customerGuid) {
      this.spinner.show();
      return new Observable((observer) => {
        this.subscriptions.push(this.repoService.getData(`Customer/Orders/${this.$customer.customerGuid}`).subscribe({
          next: (res) => {
            this.spinner.hide();
            observer.next(res as Order[]);
            observer.complete();
          },
          error: (e) => {
            this.spinner.hide();
            const errMessage = 'Sorry we could get your orders at this time.';
            this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 3000 });
            observer.error(e);
            observer.complete();
          }
        }));
      });
    }
  }

  public getRecentProducts(customerGuid: string): Observable<ProductDetail[]> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.getData(`Customer/RecentProducts/${customerGuid}`).subscribe({
        next: (res) => {
          observer.next(res as ProductDetail[]);
          observer.complete();
        }
      }));
    });
  }

  public getShipments(): Observable<Shipment[]> {
    if (this.$customer?.customerGuid) {
      this.spinner.show();
      return new Observable((observer) => {
        this.subscriptions.push(this.repoService.getData(`Customer/Shipments/${this.$customer.customerGuid}`).subscribe({
          next: (res) => {
            this.spinner.hide();
            observer.next(res as Shipment[]);
            observer.complete();
          },
          error: () => {
            this.spinner.hide();
            const errMessage = 'Sorry we could get your shipment information at this time.';
            this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 3000 });
            observer.next(null);
            observer.complete();
          }
        }));
      });
    }
  }

  public createAddress(address: Address): Observable<Address> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.create(`Customer/Address/${this.$customer.customerGuid}`, address).subscribe({
        next: (res) => {
          observer.next(res as Address);
          observer.complete();
        },
        error: (e) => {
          const errMessage = 'Error creating address';
          this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 6000 });
          observer.error(e);
          observer.complete();
        }
      }));
    });
  }

  public updateAddress(address: Address): Observable<Address> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.update(`Customer/Address/${this.$customer.customerGuid}`, address).subscribe({
        next: (res) => {
          observer.next(res as Address);
          observer.complete();
        },
        error: (e) => {
          const errMessage = 'Error saving address';
          this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 6000 });
          observer.error(e);
          observer.complete();
        }
      }));
    });
  }

  public deleteAddress(address: Address): Observable<boolean> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.delete(`Customer/Address/${this.$customer.customerGuid}/${address.id}`).subscribe({
        next: (res) => {
          const success = res as boolean;
          if (!success) {
            const errMessage = 'Problem deleting address';
            this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 6000 });
          }
          observer.next(success);
          observer.complete();
        },
        error: () => {
          const errMessage = 'Error deleting address';
          this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 6000 });
          observer.next(false);
          observer.complete();
        }
      }));
    });
  }

  public setShippingAddress(address: Address): Observable<Customer> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.update(`Customer/Address/SetShipping/${this.$customer.customerGuid}`, address).subscribe({
        next: (res) => {
          this.$customer = res as Customer;
          this.customer.next(this.$customer);
          observer.next(this.$customer);
          observer.complete();
        },
        error: (e) => {
          const errMessage = 'Error setting shipping address';
          this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 6000 });
          observer.error(e);
          observer.complete();
        }
      }));
    });
  }

  public setBillingAddress(address: Address): Observable<Customer> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.update(`Customer/Address/SetBilling/${this.$customer.customerGuid}`, address).subscribe({
        next: (res) => {
          this.update(res as Customer);
          observer.next(this.$customer);
          observer.complete();
        },
        error: (e) => {
          const errMessage = 'Error setting shipping address';
          this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 6000 });
          observer.error(e);
          observer.complete();
        }
      }));
    });
  }

  public updateCustomerShipping(customerGuid: string, shippingAddress: Address): Observable<Address> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.update(`Customer/Shipping/${customerGuid}`, shippingAddress).subscribe({
        next: (res) => {
          this.load();
          observer.next(res as Address);
          observer.complete();
        },
        error: (e) => {
          const errMessage = 'Error saving customer shipping address. ' + e;
          this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 6000 });
          observer.error(null);
          observer.complete();
        }
      }));
    });
  }

  public updateCustomerBilling(customerGuid: string, shippingAddress: Address): Observable<Address> {
    return new Observable((observer) => {
      if (!this.$customer.billingAddress) {
        this.$customer.billingAddress = new Address();
        this.$customer.billingAddress.country = null; // We will be resetting the billing address
      }
      this.subscriptions.push(this.repoService.update(`Customer/Billing/${customerGuid}`, shippingAddress).subscribe({
        next: (res) => {
          this.load();
          observer.next(res as Address);
          observer.complete();
        },
        error: (e) => {
          const errMessage = 'Error saving customer billing address';
          this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 6000 });
          observer.error(e);
          observer.complete();
        }
      }));
    });
  }

  public resetCustomerBilling(): Observable<boolean> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.update(`Customer/BillingReset/${this.$customer.customerGuid}`, '{}').subscribe({
        next: (res) => {
          this.$customer = res as Customer;
          this.customer.next(this.$customer);
          observer.next(true);
          observer.complete();
        },
        error: () => {
          const errMessage = 'Error saving customer billing address';
          this.snackBar.open(errMessage, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 6000 });
          observer.next(false);
          observer.complete();
        }
      }));
    });
  }

  public backInStock(customerGuid: string, product: ProductDetail): Observable<boolean> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.update(`Customer/BackInStock/${customerGuid}`, product).subscribe({
        next: (res) => {
          const result = res as boolean;
          observer.next(result);
          observer.complete();
        },
        error: (err) => {
          console.log(err);
          observer.next(false);
          observer.complete();
        }
      }));
    });
  }

  public addProductToCart(product: any, quantity: number): void { // Type any so we can pass Product or Detail Product
    if (this.customer && this.$customer) {
      const cartProduct = new Product();
      cartProduct.cartQuantity = quantity;
      cartProduct.name = `Please wait, checking price and stock for ${product.name}`;
      cartProduct.newPriceDisplay = "Wait";
      cartProduct.oldPriceDisplay = "Wait";
      cartProduct.images = product.images;
      cartProduct.defaultImages = product.defaultImages;
      this.$customer.cartList.push(cartProduct);
      this.$customer.totalCartCount += quantity;
      this.$customer.updatingCart = true;
      this.customer.next(this.$customer);
    }
  }

}
