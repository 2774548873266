import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { Artist } from "src/app/_models/artist.model";
import { ElfaService } from "./elfa.service";

@Injectable()
export class ArtistService implements OnDestroy {
  public artists: BehaviorSubject<Artist[]> = new BehaviorSubject<Artist[]>(null);
  private $artists: Artist[] = null;
  private subscriptions: Subscription[] = [];

  constructor(public elfaService: ElfaService) { }

  public load(init?: Artist[]): void {
    if (init) {
      this.$artists = init;
      this.artists.next(this.$artists);
      return;
    }
    if (!this.$artists) {
      this.subscriptions.push(this.elfaService.getArtists().subscribe({
        next: (res: Artist[]) => {
          this.$artists = res;
          this.artists.next(this.$artists);
        },
        error: () => {
          this.$artists = null;
          this.artists.next(this.$artists);
        }
      }));
    } else{
      this.artists.next(this.$artists);
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe() });
    }
  }
}
