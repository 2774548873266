export class SiteMap {
  public items: SiteMapItem[];
}
export class SiteMapItem {
  public categoryId: number;
  public parentCategoryId: number;
  public id: string;
  public slug: string;
  public title: string;
  public routerLink: string;
  public link: string;
  public href: string;
  public target: string;
  public hasSubMenu: boolean;
  public parentId: string;
  public templateId: number;
  public level: number;
  public footerOne :boolean;
  public footerTwo :boolean;
  public footerThree :boolean;
}
