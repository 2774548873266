import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Product, ProductDetail } from 'src/@omnial/_models/catalog/product.model';
import { Order, OrderItem } from 'src/@omnial/_models/order/order.model';
import { GA4Item } from 'src/@omnial/_models/ga.ecomm/ga4.item.model';
import { Customer } from 'src/@omnial/_models/customer/customer.model';
import { Category } from 'src/@omnial/_models/catalog/category.model';
import { CategoryService } from '../catalog/category.service';
import { ShippingOption } from 'src/@omnial/_models/order/shipping-option.model';
import {
  GA4AddPaymentInfo,
  GA4AddShippingInfo,
  GA4AddToCart,
  GA4AddToWishList,
  GA4BeginCheckout,
  GA4EarnVirtualCurrency,
  GA4GenerateLead,
  GA4JoinGroup,
  GA4LevelEnd,
  GA4LevelStart,
  GA4LevelUp,
  GA4Login,
  GA4PostScore,
  GA4Purchase,
  GA4Refund,
  GA4RemoveFromCart,
  GA4Search,
  GA4SelectContent,
  GA4SelectItem,
  GA4SelectPromotion,
  GA4Share,
  GA4SignUp,
  GA4SpendVirtualCurrency,
  GA4UnlockAchievement,
  GA4ViewCart,
  GA4ViewItem,
  GA4ViewItemList,
  GA4ViewPromotion
} from 'src/@omnial/_models/ga.ecomm/ga4.events.model';
import { AppSettings } from 'src/app/app.settings';
import { environment } from 'src/environments/environment';

@Injectable()
export class GoogleGA4Service implements OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor(private gtmService: GoogleTagManagerService, public categoryService: CategoryService, public settings: AppSettings) { }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe() });
    }
  }

  public push(event: string, object: any) {
    try {
      if (object) {
        const tag = { event: event, ecommerce: object };
        this.gtmService.pushTag(tag);
      } else {
        const tag = { event: event };
        this.gtmService.pushTag(tag);
      }
    } catch (error) {
      console.log('!!! GA4 Error !!!!');
      console.log(error);
      console.log(object);
    }
  }

  public pushAddPaymentInfo(customer: Customer, paymentType: string) {
    const ga4Object = new GA4AddPaymentInfo();
    ga4Object.coupon = customer?.discountCodes?.length > 0 ? customer.discountCodes[0] : '';
    ga4Object.currency = customer?.cartList?.length > 0 ? customer.cartList[0].currency : environment.currencyCode;
    this.subscriptions.push(this.categoryService.categories.subscribe(cats => {
      if (cats) {
        const ga4Object = new GA4AddPaymentInfo();
        ga4Object.coupon = customer?.discountCodes?.length > 0 ? customer.discountCodes[0] : '';
        ga4Object.currency = customer?.cartList?.length > 0 ? customer.cartList[0].currency : environment.currencyCode;
        ga4Object.value = customer?.orderTotals?.orderTotal;
        customer?.cartList?.forEach((item) => {
          ga4Object.items.push(this.convertSimpleProduct(item, 1, item.cartQuantity, cats));
        });
        ga4Object.payment_type = paymentType;
        this.push('add_payment_info', ga4Object);
      }
    }));
  }

  public pushAddShippingInfo(customer: Customer, shippingOption: ShippingOption): void {
    this.subscriptions.push(this.categoryService.categories.subscribe(cats => {
      const ga4Object = new GA4AddShippingInfo();
      ga4Object.coupon = customer?.discountCodes?.length > 0 ? customer.discountCodes[0] : '';
      ga4Object.currency = customer?.cartList?.length > 0 ? customer.cartList[0].currency : environment.currencyCode;
      ga4Object.shipping_tier = shippingOption?.name;
      ga4Object.value = customer?.orderTotals?.orderTotal;
      if (cats) {
        customer?.cartList?.forEach((item) => {
          ga4Object.items.push(this.convertSimpleProduct(item, 1, item.cartQuantity, cats));
        });
        this.push('add_shipping_info', ga4Object);
      }
    }));
  }

  public pushAddToCart(product: Product, quantity: number): void {
    this.subscriptions.push(this.categoryService.categories.subscribe(cats => {
      if (cats) {
        const ga4Object = new GA4AddToCart();
        ga4Object.currency = product?.currency ?? environment.currencyCode;
        ga4Object.value = product?.valuePrice;
        ga4Object.items.push(this.convertSimpleProduct(product, 1, quantity, cats));
        this.push('add_to_cart', ga4Object);
      }
    }));
  }

  public pushAddToCartDetail(product: ProductDetail, quantity: number): void {
    this.subscriptions.push(this.categoryService.categories.subscribe(cats => {
      if (cats) {
        const ga4Object = new GA4AddToCart();
        ga4Object.currency = product?.currency ?? environment.currencyCode;
        ga4Object.value = product?.valuePrice;
        ga4Object.items.push(this.convertProduct(product, 1, quantity, cats));
        this.push('add_to_cart', ga4Object);
      }
    }));
  }

  public pushAddToWishList(product: Product): void {
    this.subscriptions.push(this.categoryService.categories.subscribe(cats => {
      if (cats) {
        const ga4Object = new GA4AddToWishList();
        ga4Object.currency = product?.currency ?? environment.currencyCode;
        ga4Object.value = product?.valuePrice;
        ga4Object.items.push(this.convertSimpleProduct(product, 1, 1, cats));
        this.push('add_to_wishlist', ga4Object);
      }
    }));
  }

  public pushBeginCheckout(customer: Customer): void {
    this.subscriptions.push(this.categoryService.categories.subscribe(cats => {
      if (cats) {
        const ga4Object = new GA4BeginCheckout();
        ga4Object.coupon = customer?.discountCodes?.length > 0 ? customer.discountCodes[0] : '';
        ga4Object.currency = customer?.cartList?.length > 0 ? customer.cartList[0].currency : environment.currencyCode;
        ga4Object.value = customer?.orderTotals?.orderTotal;
        customer?.cartList?.forEach((item) => {
          ga4Object.items.push(this.convertSimpleProduct(item, 1, item.cartQuantity, cats));
        });
        this.push('begin_checkout', ga4Object);
      }
    }));
  }

  public pushEarnVirtualCurrency(value: number, currencyCode: string): void {
    const ga4Object = new GA4EarnVirtualCurrency();
    ga4Object.value = value;
    ga4Object.virtual_currency_name = currencyCode;
    this.push('earn_virtual_currency', ga4Object);
  }

  public pushGenerateLead(value: number, currencyCode: string): void {
    const ga4Object = new GA4GenerateLead();
    ga4Object.currency = currencyCode;
    ga4Object.value = value;
    this.push('generate_lead', ga4Object);
  }

  public pushJoinGroup(group: string): void {
    const ga4Object = new GA4JoinGroup();
    ga4Object.group_id = group;
    this.push('join_group', ga4Object);
  }

  public pushLevelEnd(levelName: string, success: boolean): void {
    const ga4Object = new GA4LevelEnd();
    ga4Object.level_name = levelName;
    ga4Object.success = success;
    this.push('level_end', ga4Object);
  }

  public pushLevelStart(levelName: string): void {
    const ga4Object = new GA4LevelStart();
    ga4Object.level_name = levelName;
    this.push('level_start', ga4Object);
  }

  public pushLevelUp(character: string, level: number): void {
    const ga4Object = new GA4LevelUp();
    ga4Object.character = character;
    ga4Object.level = level;
    this.push('level_up', ga4Object);
  }

  public pushLogin(method: string): void {
    const ga4Object = new GA4Login();
    ga4Object.method = method;
    this.push('login', ga4Object);
  }

  public pushPostScore(character: string, level: number, score: number): void {
    const ga4Object = new GA4PostScore();
    ga4Object.character = character;
    ga4Object.level = level;
    ga4Object.score = score;
    this.push('post_score', ga4Object);
  }

  public pushPurchase(order: Order): void {
    this.subscriptions.push(this.categoryService.categories.subscribe(cats => {
      if (cats) {
        const ga4Object = new GA4Purchase();
        ga4Object.affiliation = this.settings.companyName;
        ga4Object.coupon = this.getCoupon(order);
        ga4Object.currency = order.customerCurrencyCode ?? environment.currencyCode;
        ga4Object.shipping = order.orderShippingExclTax;
        ga4Object.tax = order.orderTax;
        ga4Object.transaction_id = order.customOrderNumber;
        ga4Object.value = order.orderTotal;
        let itemCounter = 0;
        order?.items?.forEach((item) => {
          ga4Object.items.push(this.convertOrderItem(item, itemCounter, cats));
          itemCounter++;
        });
        this.push('purchase', ga4Object);
        if (order.redeemedRewardPoints) {
          this.pushSpendVirtualCurrency(order.customOrderNumber, order.redeemedRewardPointsAmount, order.customerCurrencyCode);
        }
        // TODO - Earn Virtual Currency??
      }
    }));
  }

  public pushRefund(order: Order, companyName: string): void {
    this.subscriptions.push(this.categoryService.categories.subscribe(cats => {
      if (cats) {
        const ga4Object = new GA4Refund();
        ga4Object.affiliation = companyName;
        ga4Object.coupon = this.getCoupon(order);
        ga4Object.currency = order.customerCurrencyCode ?? environment.currencyCode;
        ga4Object.shipping = order.orderShippingExclTax;
        ga4Object.tax = order.orderTax;
        ga4Object.transaction_id = order.customOrderNumber;
        ga4Object.value = order.orderTotal;
        let itemCounter = 0;
        order?.items?.forEach((item) => {
          ga4Object.items.push(this.convertOrderItem(item, itemCounter, cats));
          itemCounter++;
        });
        this.push('refund', ga4Object);
      }
    }));
  }

  public pushRemoveFromCart(product: Product, quantity: number): void {
    this.subscriptions.push(this.categoryService.categories.subscribe(cats => {
      if (cats) {
        const ga4Object = new GA4RemoveFromCart();
        ga4Object.currency = product?.currency ?? environment.currencyCode;
        ga4Object.value = product?.valuePrice;
        product.listName = "Shopping Cart";
        product.listId = "cart"
        ga4Object.items.push(this.convertSimpleProduct(product, 1, quantity, cats));
        this.push('remove_from_cart', ga4Object);
      }
    }));
  }

  public pushSearch(term: string): void {
    const ga4Object = new GA4Search();
    ga4Object.search_term = term;
    this.push('search', ga4Object);
  }

  public pushSelectContent(contentType: string, itemId: string): void {
    const ga4Object = new GA4SelectContent();
    ga4Object.content_type = contentType;
    ga4Object.item_id = itemId;
    this.push('select_content', ga4Object);
  }

  public pushSelectItem(product: Product, listName: string, listId: string, position: number): void {
    this.subscriptions.push(this.categoryService.categories.subscribe(cats => {
      if (cats) {
        const ga4Object = new GA4SelectItem();
        ga4Object.item_list_id = listId;
        ga4Object.item_list_name = listName;
        product.listId = null;
        product.listName = null;
        ga4Object.items.push(this.convertSimpleProduct(product, position, 1, cats));
        this.push('select_item', ga4Object);
      }
    }));
  }

  public pushSelectPromotion(): void {
    const ga4Object = new GA4SelectPromotion();
    // TODO
    this.push('select_promotion', ga4Object);
  }

  public pushShare(method: string, contentType: string, itemId: string): void {
    const ga4Object = new GA4Share();
    ga4Object.content_type = contentType;
    ga4Object.method = method;
    ga4Object.item_id = itemId;
    this.push('share', ga4Object);
  }

  public pushSignUp(method: string): void {
    const ga4Object = new GA4SignUp();
    ga4Object.method = method;
    this.push('sign_up', ga4Object);
  }

  public pushSpendVirtualCurrency(itemName: string, value: number, currencyCode: string): void {
    const ga4Object = new GA4SpendVirtualCurrency();
    ga4Object.item_name = itemName;
    ga4Object.value = value;
    ga4Object.virtual_currency_name = currencyCode;
    this.push('spend_virtual_currency', ga4Object);
  }

  public pushTutorialBegin(): void {
    this.push('tutorial_begin', null);
  }

  public pushTutorialComplete(): void {
    this.push('tutorial_complete', null);
  }

  public pushUnlockAchievement(achievementId: string): void {
    const ga4Object = new GA4UnlockAchievement();
    ga4Object.achievement_id = achievementId;
    this.push('unlock_achievement', ga4Object);
  }

  public pushViewCart(customer: Customer): void {
    this.subscriptions.push(this.categoryService.categories.subscribe(cats => {
      if (cats) {
        const ga4Object = new GA4ViewCart();
        ga4Object.currency = customer?.cartList?.length > 0 ? customer.cartList[0].currency : environment.currencyCode;
        ga4Object.value = customer?.orderTotals?.orderTotal;
        customer?.cartList?.forEach((item) => {
          ga4Object.items.push(this.convertSimpleProduct(item, 1, item.cartQuantity, cats));
        });
        this.push('view_cart', ga4Object);
      }
    }));
  }

  public pushViewItem(product: ProductDetail, listName: string, listId: string, position: number): void {
    this.subscriptions.push(this.categoryService.categories.subscribe(cats => {
      if (cats) {
        const ga4Object = new GA4ViewItem();
        ga4Object.currency = product?.currency;
        ga4Object.value = product.valuePrice;
        product.listName = listName;
        product.listId = listId;
        ga4Object.items.push(this.convertProduct(product, position, 1, cats));
        this.push('view_item', ga4Object);
      }
    }));
  }

  public pushViewItemList(products: Product[], listName: string, listId: string, position: number): void {
    this.subscriptions.push(this.categoryService.categories.subscribe(cats => {
      if (cats) {
        const ga4Object = new GA4ViewItemList();
        ga4Object.item_list_id = listName;
        ga4Object.item_list_name = listId;
        products?.forEach((product) => {
          product.listId = null;
          product.listName = null;
          ga4Object.items.push(this.convertSimpleProduct(product, position, 1, cats));
        });
        this.push('view_item_list', ga4Object);
      }
    }));
  }

  public pushViewPromotion(): void {
    const ga4Object = new GA4ViewPromotion();
    // TODO
    this.push('view_promotion', ga4Object);
  }

  private getCoupon(order: Order): string {
    let coupon = null;
    order?.usedDiscounts?.forEach((discount) => {
      // We are only interested in the ones with Coupon Codes ... right?
      if (discount.couponCode) {
        if (coupon) {
          coupon = `${coupon}|${discount.couponCode}`;
        } else {
          coupon = discount.couponCode;
        }
      }
    });
    return coupon;
  }

  private convertProduct(product: ProductDetail, position: number, quantity: number, categories: Category[]): GA4Item {
    const ga4Product = new GA4Item();
    ga4Product.item_id = product.sku;
    ga4Product.item_name = product.name;
    ga4Product.affiliation = this.settings.companyName;
    ga4Product.currency = product.currency ?? environment.currencyCode;
    ga4Product.discount = product.discount;
    ga4Product.index = position;
    ga4Product.item_brand = product.manufacturer?.name ?? '';
    ga4Product.item_list_id = product.listId;
    ga4Product.item_list_name = product.listName;
    ga4Product.item_variant = product.attributeSummary ?? 'none';
    ga4Product.location_id = 'omnial';
    ga4Product.price = product.newPrice;
    ga4Product.quantity = quantity
    this.populateCategories(ga4Product, product.canonicalCategoryId, product.categoryIds, categories);
    return ga4Product;
  }

  private convertSimpleProduct(product: Product, position: number, quantity: number, categories: Category[]): GA4Item {
    const ga4Product = new GA4Item();
    ga4Product.item_id = product.sku;
    ga4Product.item_name = product.name;
    ga4Product.affiliation = this.settings.companyName;
    ga4Product.currency = product.currency ?? environment.currencyCode;
    ga4Product.discount = product.discount;
    ga4Product.index = position;
    ga4Product.item_brand = product.manufacturer?.name ?? '';
    ga4Product.item_list_id = product.listId;
    ga4Product.item_list_name = product.listName;
    ga4Product.item_variant = product.attributeSummary ?? 'none';
    ga4Product.location_id = 'omnial'; // TODO
    ga4Product.price = product.newPrice;
    ga4Product.quantity = quantity
    this.populateCategories(ga4Product, product.canonicalCategoryId, product.categoryIds, categories);
    return ga4Product;
  }

  private convertOrderItem(orderItem: OrderItem, index: number, categories: Category[]): GA4Item {
    const ga4Product = new GA4Item();
    ga4Product.item_id = orderItem?.product?.sku;
    ga4Product.item_name = orderItem?.product?.name;
    ga4Product.affiliation = this.settings.companyName;
    ga4Product.currency = orderItem?.product?.currency ?? environment.currencyCode;
    ga4Product.discount = orderItem.discountAmountInclTax;
    ga4Product.index = index;
    ga4Product.item_brand = orderItem?.product?.manufacturer?.name ?? '';
    ga4Product.item_list_id = orderItem?.listId;
    ga4Product.item_list_name = orderItem?.listName;
    ga4Product.item_variant = orderItem.attributeSummary ?? 'none';
    ga4Product.location_id = 'omnial';
    ga4Product.price = orderItem.unitPriceInclTax; // Should be without discount
    ga4Product.quantity = orderItem.quantity
    this.populateCategories(ga4Product, orderItem?.product?.canonicalCategoryId, orderItem?.product?.categoryIds, categories);
    return ga4Product;
  }

  private populateCategories(ga4Product: GA4Item, canonicalCategoryId: number, categoryIds: number[], categories: Category[]) {
    if (canonicalCategoryId) {
      const canonical = categories?.find(c => c.id === canonicalCategoryId);
      if (canonical) {
        ga4Product.item_category = canonical.name;
      }
    }
    categoryIds.forEach((categoryId) => {
      if (categoryId != canonicalCategoryId) {
        const category = categories?.find(c => c.id === categoryId);
        if (category) {
          if (!ga4Product.item_category) {
            ga4Product.item_category = category.name;
          } else if (!ga4Product.item_category2) {
            ga4Product.item_category2 = category.name;
          } else if (!ga4Product.item_category3) {
            ga4Product.item_category3 = category.name;
          } else if (!ga4Product.item_category4) {
            ga4Product.item_category4 = category.name;
          } else if (!ga4Product.item_category5) {
            ga4Product.item_category5 = category.name;
          }
        }
      }
    });
  }
}
