import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { NgxSpinnerModule } from "ngx-spinner";
import { MaterialBaseModule } from "src/@omnial/material.base.module";
import { BreadcrumbModule } from "src/@omnial/navigation/breadcrumbs/breadcrumb.module";
import { SidenavMegaMenuModule } from "src/@omnial/navigation/sidenav-mega/sidenav-mega.module";
import { SidenavMenuModule } from "src/@omnial/navigation/sidenav-menu/sidenav-menu.module";
import { ConfirmDialogComponent } from "src/@omnial/shared/confirm-dialog/confirm-dialog.component";
import { FooterModule } from "src/@omnial/shared/footer/footer.module";
import { HeaderDesktopModule } from "src/@omnial/shared/header-desktop/header-desktop.module";
import { HeaderMobileModule } from "src/@omnial/shared/header-mobile/header-mobile.module";
import { PagesComponent } from "./pages.component";

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        RouterModule,
        NgxSpinnerModule,
        MaterialBaseModule,
        SidenavMegaMenuModule,
        SidenavMenuModule,
        HeaderMobileModule,
        HeaderDesktopModule,
        FooterModule,
        BreadcrumbModule
    ],
    declarations: [PagesComponent, ConfirmDialogComponent]
})
export class PagesModule { }
