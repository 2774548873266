export class OmnialImage {
  constructor(public tiny: string,
    public small: string,
    public medium: string,
    public large: string,
    public full: string,
    public pictureId: number,
    public seoFileName: string,
    public title: string,
    public altText: string,
    public virtualPath: string) {
  }
}

export class ImageLibraryResponse {
  totalCount: number;
  images: OmnialImage[];
}
