import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Category } from "src/@omnial/_models/catalog/category.model";
import { ProductsPaged } from "src/@omnial/_models/catalog/products.paged.model";

@Injectable()
export class ProductCategoryService {
  public category: BehaviorSubject<Category> = new BehaviorSubject<Category>(null);
  private $category: Category = null;
  public categoryProducts: BehaviorSubject<ProductsPaged> = new BehaviorSubject<ProductsPaged>(null);
  private $products: ProductsPaged = null;

  constructor() { }

  public load(): void {
    this.$category = null;
    this.category.next(null);
    this.$products = null;
    this.categoryProducts.next(null);
  }

  public replaceCategory(category: Category): void {
    this.$category = category;
    this.category.next(this.$category);
  }

  public replaceProducts(products: ProductsPaged): void {
    this.$products = products;
    this.categoryProducts.next(this.$products);
  }

  public seName(): string {
    return this.$products?.seName;
  }
}
