import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { SocialLinks } from "src/@omnial/_models/settings.model";
import { ElfaService } from "./elfa.service";


@Injectable()
export class SocialService implements OnDestroy {
  public socials: BehaviorSubject<SocialLinks> = new BehaviorSubject<SocialLinks>(null);
  private $socials: SocialLinks = null;
  private subscriptions: Subscription[] = [];

  constructor(public elfaService: ElfaService) { }

  public load(init?: SocialLinks): void {
    if (init) {
      this.$socials = init;
      this.socials.next(this.$socials);
      return;
    }
    if (!this.$socials) {
      this.subscriptions.push(this.elfaService.getSocials().subscribe({
        next: (res: SocialLinks) => {
          this.$socials = res;
          this.socials.next(this.$socials);
        },
        error: () => {
          this.$socials = null;
          this.socials.next(this.$socials);
        }
      }));
    } else{
      this.socials.next(this.$socials);
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe() });
    }
  }
}
