import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Product, ProductDetail } from 'src/@omnial/_models/catalog/product.model';
import { Order, OrderItem } from 'src/@omnial/_models/order/order.model';

@Injectable()
export class GoogleGA3Service {

  constructor(private gtmService: GoogleTagManagerService) { }

  public push(gtmString: string) {
    try {
      this.gtmService.pushTag(JSON.parse(gtmString));
    } catch (error) {
      console.log('!!! GA3 Error !!!!');
      console.log(error);
      console.log(gtmString);
    }
  }

  private sanitise(input: string) : string {
    if (input) {
      return input.replace(new RegExp(this.escapeRegExp('\"'), 'g'), '\\\"');
    }
    return input;
  }
  private escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  public pushImpression(product: Product, listId: string, listName: string, position: number): void {
    const gtmString = `
              {
              "event": "eec.impression",
              "ecommerce": {
                "impressions": [{
                  "name":"${this.sanitise(product.name)}",
                  "id": "${this.sanitise(product.sku)}",
                  "price": ${product.valuePrice},
                  "brand": "${this.sanitise(product.manufacturer?.name)}",
                  "category": "${listId}",
                  "list": "${this.sanitise(listName)}",
                  "position": ${position}
                }]
              }
            }`;
    this.push(gtmString);
  }

  public pushProductView(product: ProductDetail, listId: string): void {
    const gtmString = `
              {
                "event": "eec.productView",
                "ecommerce": {
                "detail": {
                  "products": [{
                    "name":"${this.sanitise(product.name)}",
                    "id": "${this.sanitise(product.sku)}",
                    "price": ${product.valuePrice},
                    "brand": "${this.sanitise(product.manufacturer?.name)}",
                    "category": "${listId}"
                    }]
                  }
                }
              }`;
    this.push(gtmString);
  }

  public pushProductClick(product: Product, listId: string, listName: string, position: number): void {
    const gtmString = `
              {
              "event": "eec.productClick",
              "ecommerce": {
                "click": {
                  "actionField": {"list": "${this.sanitise(listName)}"},
                  "products": [{
                    "name":"${this.sanitise(product.name)}",
                    "id": "${this.sanitise(product.sku)}",
                    "price": ${product.valuePrice},
                    "brand": "${this.sanitise(product.manufacturer?.name)}",
                    "category": "${listId}",
                    "position": ${position}
                    }]
                  }
                }
              }`;
    this.push(gtmString);
  }

  public pushCheckout(step: number, products: Product[]): void {
    const gtmString = `
            {
            "event": "eec.checkout",
            "ecommerce": {
              "checkout": {
                "actionField": {
                    "step": "${step}"
                },
                "products":[${this.products(products)}]
                }
              }
            }`;
    this.push(gtmString);
  }

  public pushCheckoutOption(step: number, option: string): void {
    const gtmString = `
            {
            "event": "eec.checkoutOption",
            "ecommerce": {
              "checkout_option": {
                "actionField": {
                    "step": "${step}",
                    "option": "${option}"
                  }
                }
              }
            }`;
    this.push(gtmString);
  }

  public pushPurchase(order: Order, companyName: string): void {
    const gtmString = `
        {
        "event": "eec.purchase",
        "ecommerce": {
            "currencyCode": "AUD",
            "purchase": {
              "actionField": {
                "id": "${order.customOrderNumber}",
                "affiliation": "${companyName}",
                "revenue": "${order.orderTotal}",
                "tax": "${order.orderTax}",
                "shipping": "${order.orderShippingInclTax}",
                "coupon": ""
                  },
              "products":[${this.orderLines(order.items, companyName)}]
              }
            }
        }`;
    this.push(gtmString);
  }

  public pushCartAdd(product: Product, quantity: number): void {
    const gtmString = `
        {
        "event": "eec.add",
        "ecommerce": {
          "currencyCode": "AUD",
          "add": {
            "actionField": {
              "list": "${product.listId}"
                },
            "products":[${this.productSimple(product, quantity)}]
            }
          }
        }`;
    this.push(gtmString);
  }

  public pushCartAddDetail(product: ProductDetail, quantity: number): void {
    const gtmString = `
        {
        "event": "eec.add",
        "ecommerce": {
          "currencyCode": "AUD",
          "add": {
            "actionField": {
              "list": "${product.listName}"
                },
            "products":[${this.productDetail(product, quantity)}]
            }
          }
        }`;
    this.push(gtmString);

  }

  public pushCartRemove(product: Product, quantity: number): void {
    const gtmString = `
        {
            "event": "eec.remove",
            "ecommerce": {
              "currencyCode": "AUD",
              "remove": {
                "products":[${this.productSimple(product, quantity)}]
                }
              }
            }`;
    this.push(gtmString);

  }

  private productSimple(product: Product, quantity: number): string {
    const productStr = `{
            "name": "${this.sanitise(product.name)}",
            "id": "${this.sanitise(product.sku)}",
            "price": "${product.newPrice}",
            "brand": "${this.sanitise(product.manufacturer?.name)}",
            "category": "${product.listId}",
            "quantity": ${quantity}
        }`;
    return productStr;
  }

  private productDetail(product: ProductDetail, quantity: number): string {
    const productStr = `{
            "name": "${this.sanitise(product.name)}",
            "id": "${this.sanitise(product.sku)}",
            "price": ${product.newPrice},
            "brand": "${this.sanitise(product.manufacturer?.name)}",
            "category": "${product.listId}",
            "quantity": ${quantity}
        }`;
    return productStr;
  }

  private products(products: Product[]): string {
    let productStr = '';
    let count = 1;
    // TODO - Category Name
    for (const product of products) {
      productStr += `{
                "name": "${this.sanitise(product.name)}",
                "id": "${this.sanitise(product.sku)}",
                "price": ${product.newPrice},
                "brand": "${this.sanitise(product.manufacturer?.name)}",
                "category": "${product.listId}",
                "quantity": ${product.cartCount}
            }`;
      if (count < products.length) {
        productStr += ',';
      }
      count++;
    }
    return productStr;
  }

  private orderLines(items: OrderItem[], companyName: string): string {
    let productStr = '';
    let count = 1;
    for (const item of items) {
      productStr += `{
                "name": "${this.sanitise(item.product.name)}",
                "id": "${this.sanitise(item.product.sku)}",
                "price": ${item.unitPriceInclTax},
                "brand": "${this.sanitise(companyName)}",
                "category": "${this.sanitise(item.product.listId)}",
                "quantity": ${item.quantity}
            }`;
      if (count < items.length) {
        productStr += ',';
      }
      count++;
    }
    return productStr;
  }
}
