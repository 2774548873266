import { Customer } from './customer.model';

export class RegRequest {
    customer: Customer;
    email: string;
    password: string;
    newsLetter: boolean;
    isApproved: boolean;
    token: string;
}

export class RegResult {
    customer: Customer;
    success: boolean;
    errors: string[];
    jwtToken: string;
    expires: string;
    claims: string[];
}

export class LogonRequest {
    email: string;
    password: string;
    token: string;
    checkout: boolean;
}

export class LogonResult {
    customer: Customer;
    success: boolean;
    errors: string[];
    jwtToken: string;
    expires: string;
    claims: string[];
}

export class ResetRequest {
    password: string;
    token: string;
    nopToken: string;
    nopGuid: string;
}

export class ResetResult {
    customer: Customer;
    success: boolean;
    errors: string[];
    jwtToken: string;
    expires: string;
    claims: string[];
}

export class ChangePasswordRequest {
    oldPassword: string;
    newPassword: string;
    token: string;
}

export class ChangePasswordResult {
    success: boolean;
    errors: string[];
}

export class ChangeDetailsRequest {
    firstName: string;
    lastName: string;
    email: string;
    token: string;
}

export class ChangeDetailsResult {
    success: boolean;
    errors: string[];
}

export class DetailsRequest {
    customer: Customer;
    newsLetter: boolean;
}

export class DetailsResult {
    customer: Customer;
    success: boolean;
    errors: string[];
}

export class PasswordRequest {
    customer: Customer;
    oldPassword: string;
    newPassword: string;
}

export class PasswordResult {
    customer: Customer;
    success: boolean;
    errors: string[];
}

export class RefreshResponse {
  jwtToken: string;
  isGuest: boolean;
  expires: string;
  claims: string[];
}
