import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { Category } from "src/@omnial/_models/catalog/category.model";
import { RepositoryStaticService } from "../repository-static.service";
import { RepositoryService } from "../repository.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "src/environments/environment";

@Injectable()
export class CategoryService implements OnDestroy {
  public categories: BehaviorSubject<Category[]> = new BehaviorSubject<Category[]>(null);
  private $categories: Category[] = null;
  private subscriptions: Subscription[] = [];
  private useCache = environment.useCache;

  constructor(public staticService: RepositoryStaticService,
    public repoService: RepositoryService,
    public snackBar: MatSnackBar) { }

  public load(init?: Category[]): void {
    if (init) {
      this.$categories = init;
      this.categories.next(this.$categories);
      return;
    }
    if (!this.$categories) {
      this.subscriptions.push(this.getCategories().subscribe({
        next: (res: Category[]) => {
          this.$categories = res;
          this.categories.next(this.$categories);
        },
        error: () => {
          this.$categories = null;
          this.categories.next(this.$categories);
        }
      }));
    } else{
      this.categories.next(this.$categories);
    }
  }

  public getCategory(slug: string, bypassCache?: boolean): Observable<Category> {
    return new Observable((observer) => {
      if (this.useCache && !bypassCache) {
        this.subscriptions.push(this.staticService.getBySeName('Category', slug).subscribe({
          next: (staticRes) => {
            if (staticRes) {
              observer.next(staticRes as Category);
              observer.complete();
            } else {
              this.subscriptions.push(this.repoService.getData(`Category/${slug}`).subscribe({
                next: (apiRes) => {
                  observer.next(apiRes as Category);
                  observer.complete();
                },
                error: (e) => {
                  const message = 'Sorry we could not get the products for this page, please try again later';
                  this.snackBar.open(message, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 3000 });
                  observer.error(e);
                  observer.complete();
                }
              }));
            }
          },
          error: () => {
            this.subscriptions.push(this.repoService.getData(`Category/${slug}`).subscribe({
              next: (apiRes) => {
                observer.next(apiRes as Category);
                observer.complete();
              },
              error: (e) => {
                const message = 'Sorry we could not get the products for this page, please try again later';
                this.snackBar.open(message, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 3000 });
                observer.error(e);
                observer.complete();
              }
            }));
          }
        }));
      } else {
        this.subscriptions.push(this.repoService.getData(`Category/${slug}`).subscribe({
          next: (resNoCache) => {
            observer.next(resNoCache as Category);
            observer.complete();
          },
          error: (e) => {
            observer.error(e);
            observer.complete();
          }
        }));
      }
    });
  }

  public getCategories(bypassCache?: boolean): Observable<Category[]> {
    return new Observable((observer) => {
      if (this.useCache && !bypassCache) {
        this.subscriptions.push(this.staticService.get('Category').subscribe({
          next: (staticRes) => {
            if (staticRes) {
              observer.next(staticRes as Category[]);
              observer.complete();
            } else {
              this.subscriptions.push(this.repoService.getData('Category').subscribe({
                next: (apiRes) => {
                  observer.next(apiRes as Category[]);
                  observer.complete();
                },
                error: (e) => {
                  observer.error(e);
                  observer.complete();
                }
              }));
            }
          },
          error: () => {
            this.subscriptions.push(this.repoService.getData('Category').subscribe({
              next: (apiRes) => {
                observer.next(apiRes as Category[]);
                observer.complete();
              },
              error: (e) => {
                observer.error(e);
                observer.complete();
              }
            }));
          }
        }));
      } else {
        this.subscriptions.push(this.repoService.getData('Category').subscribe({
          next: (resNoCache) => {
            observer.next(resNoCache as Category[]);
            observer.complete();
          },
          error: (e) => {
            observer.error(e);
            observer.complete();
          }
        }));
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe() });
    }
  }
}
