import { State, Country } from "../order/country.model";

export class Address {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  city: string;
  address1: string;
  address2: string;
  zipPostalCode: string;
  phoneNumber: string;
  faxNumber: string;
  state: State;
  stateName: string;
  country: Country;
}

export class GoogleAddress {
  suggestedAddress: string;
  formattedAddress: string;
  subpremise: string;
  subpremiseShort: string;
  streetNumber: string;
  streetNumberShort: string;
  route: string;
  routeShort: string;
  locality: string;
  localityShort: string;
  administrativeAreaLevel2: string;
  administrativeAreaLevel2Short: string;
  administrativeAreaLevel1: string;
  administrativeAreaLevel1Short: string;
  country: string;
  countryShort: string;
  postalCode: string;
  postalCodeShort: string;
}
