import { Country } from '../../_models/order/country.model';
import { Injectable } from '@angular/core';
import { GoogleAddress, Address } from 'src/@omnial/_models/customer/address.model';

import PlaceResult = google.maps.places.PlaceResult;

@Injectable()
export class GoogleAddressService {

  constructor() { }

  public formatAddress(googleAddress: GoogleAddress, countryCode: string, countries: Country[]): Address {
    const address = new Address();
    const country = countries.find(c => c.twoLetterIsoCode === countryCode);
    if (country === undefined || country === null) {
      return null;
    }
    address.country = country;
    if (googleAddress.subpremise !== undefined && googleAddress.subpremise) {
      address.address1 = googleAddress.subpremise;
    }
    if (googleAddress.streetNumber !== undefined && googleAddress.streetNumber) {
      if (address.address1 !== undefined && address.address1) {
        address.address1 += ` ${googleAddress.streetNumber}`;
      } else {
        address.address1 = googleAddress.streetNumber;
      }
    }
    if (googleAddress.route !== undefined && googleAddress.route) {
      if (address.address1 !== undefined && address.address1) {
        address.address1 += ` ${googleAddress.route}`;
      } else {
        address.address1 = googleAddress.route;
      }
    }
    if (googleAddress.locality !== undefined && googleAddress.locality) {
      address.city = googleAddress.locality;
    }
    if (googleAddress.administrativeAreaLevel2 !== undefined && googleAddress.administrativeAreaLevel2) {
      address.state = country.states?.find(s => s.abbreviation === googleAddress.administrativeAreaLevel2Short);
      if (!address.state) {
        address.stateName = googleAddress.administrativeAreaLevel2;
      } else {
        address.stateName = '';
      }
    }
    if (googleAddress.administrativeAreaLevel1 !== undefined && googleAddress.administrativeAreaLevel1) {
      if (address.city !== undefined && address.city) {
        address.city += ` ${googleAddress.administrativeAreaLevel2}`;
      } else {
        address.city = googleAddress.administrativeAreaLevel2;
      }
    }
    if (googleAddress.postalCode !== undefined && googleAddress.postalCode) {
      address.zipPostalCode = googleAddress.postalCode;
    }
    return address;
  }

  public formatAustralianAddress(googleAddress: GoogleAddress, countries: Country[]): Address {
    const australia = countries.find(c => c.twoLetterIsoCode === 'AU');
    if (australia === undefined || australia === null) {
      return null;
    }
    const address = new Address();
    if (googleAddress.subpremise !== undefined && googleAddress.subpremise) {
      address.address1 = googleAddress.subpremise;
    }
    if (googleAddress.streetNumber !== undefined && googleAddress.streetNumber) {
      if (address.address1 !== undefined && address.address1) {
        address.address1 += ` ${googleAddress.streetNumber}`;
      } else {
        address.address1 = googleAddress.streetNumber;
      }
    }
    if (googleAddress.route !== undefined && googleAddress.route) {
      if (address.address1 !== undefined && address.address1) {
        address.address1 += ` ${googleAddress.route}`;
      } else {
        address.address1 = googleAddress.route;
      }
    }
    if (googleAddress.locality !== undefined && googleAddress.locality) {
      address.city = googleAddress.locality;
    }
    if (googleAddress.administrativeAreaLevel2 !== undefined && googleAddress.administrativeAreaLevel2) {
      address.stateName = googleAddress.administrativeAreaLevel2;
      address.state = australia.states.find(s => s.abbreviation === googleAddress.administrativeAreaLevel2Short);
    }
    if (googleAddress.administrativeAreaLevel1 !== undefined && googleAddress.administrativeAreaLevel1) {
      if (address.city !== undefined && address.city) {
        address.city += ` ${googleAddress.administrativeAreaLevel2}`;
      } else {
        address.city = googleAddress.administrativeAreaLevel2;
      }
    }
    if (googleAddress.postalCode !== undefined && googleAddress.postalCode) {
      address.zipPostalCode = googleAddress.postalCode;
    }
    address.country = australia;
    return address;
  }

  public getGoogleAddress(result: PlaceResult): GoogleAddress {
    const googleAddress = new GoogleAddress();
    if (result && result.address_components) {
      for (const component of result.address_components) {
        if (component.types) {
          for (const componentType of component.types) {
            switch (componentType) {
              case 'subpremise':
                googleAddress.subpremiseShort = component.short_name;
                googleAddress.subpremise = component.long_name;
                break;
              case 'street_number':
                googleAddress.streetNumberShort = component.short_name;
                googleAddress.streetNumber = component.long_name;
                break;
              case 'route':
                googleAddress.routeShort = component.short_name;
                googleAddress.route = component.long_name;
                break;
              case 'locality':
                googleAddress.localityShort = component.short_name;
                googleAddress.locality = component.long_name;
                break;
              case 'administrative_area_level_2':
                googleAddress.administrativeAreaLevel2Short = component.short_name;
                googleAddress.administrativeAreaLevel2 = component.long_name;
                break;
              case 'administrative_area_level_1':
                googleAddress.administrativeAreaLevel2Short = component.short_name;
                googleAddress.administrativeAreaLevel2 = component.long_name;
                break;
              case 'country':
                googleAddress.countryShort = component.short_name;
                googleAddress.country = component.long_name;
                break;
              case 'postal_code':
                googleAddress.postalCodeShort = component.short_name;
                googleAddress.postalCode = component.long_name;
                break;
            }
          }
        }
      }
    }
    return googleAddress;
  }
}
