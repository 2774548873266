import { Injectable, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { RegRequest, RegResult, LogonRequest, LogonResult, ResetRequest, ResetResult, DetailsRequest, DetailsResult, PasswordRequest, PasswordResult, RefreshResponse } from '../../_models/customer/account.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Customer } from 'src/@omnial/_models/customer/customer.model';
import { KlaviyoService } from '../external/klaviyo.service';
import { CustomerService } from './customer.service';
import { RepositoryService } from '../repository.service';
import { ClaimsService } from './claims.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService implements OnDestroy {
  public customer: Customer;
  private subscriptions: Subscription[] = [];

  constructor(
    public repoService: RepositoryService,
    public snackBar: MatSnackBar,
    public klaviyoService: KlaviyoService,
    public spinner: NgxSpinnerService,
    public customerService: CustomerService,
    public claimsService: ClaimsService) { }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe(); });
    }
  }

  public register(regRequest: RegRequest): Observable<RegResult> {
    this.spinner.show();
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.create('Account/Register', regRequest).subscribe({
        next: (res) => {
          this.spinner.hide();
          const result = res as RegResult;
          if (result?.success && result?.jwtToken) {
            localStorage.setItem('token', result.jwtToken);
            this.claimsService.set(result.claims);
            this.customerService.saveCustomerCookie(result.customer);
          }
          if (result?.customer) {
            this.customer = result.customer;
            this.klaviyoService.identify(this.customer);
          }
          observer.next(result);
          observer.complete();
        },
        error: (e) => {
          this.spinner.hide();
          const regResult = new RegResult();
          regResult.errors = [];
          regResult.errors.push('General Server error');
          observer.next(regResult);
          observer.complete();
        }
      }));
    });
  }

  public registerGuest(regRequest: RegRequest): Observable<RegResult> {
    this.spinner.show();
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.create('Account/RegisterGuest', regRequest).subscribe({
        next: (res) => {
          this.spinner.hide();
          const result = res as RegResult;
          if (result?.customer) {
            this.customer = result.customer;
            this.customerService.customer.next(this.customer);
            this.customerService.saveCustomerCookie(this.customer);
            if (result?.success && result?.jwtToken) {
              localStorage.setItem('token', result.jwtToken);
              this.claimsService.set(result.claims);
            }
          }
          observer.next(result);
          observer.complete();
        },
        error: (e) => {
          this.spinner.hide();
          const regResult = new RegResult();
          regResult.errors = [];
          regResult.errors.push('General Server error');
          observer.next(regResult);
          observer.complete();
        }
      }));
    });
  }

  public logon(request: LogonRequest): Observable<LogonResult> {
    return new Observable((observer) => {
      this.spinner.show();
      const currentGuid = this.customerService.getCustomerGuid();
      this.subscriptions.push(this.repoService.update('Account/Logon', request).subscribe({
        next: (res) => {
          const result = res as LogonResult;
          if (result && result?.customer) {
            if (result?.success && result?.jwtToken) {
              localStorage.setItem('token', result.jwtToken);
              this.claimsService.set(result.claims);
              // Do we have a clash of Guids ?
              // Qualified decision to either: -
              // 1. Join Carts - preferred as they must have more in their cart ... right?
              // 2. Keep the "current" cart
              // 3. Switch to the logged on customer cart
              if (currentGuid && currentGuid !== result.customer.customerGuid) {
                this.subscriptions.push(this.repoService.getData(`Customer/${currentGuid}`).subscribe({
                  next: (resCurrent) => {
                    if (resCurrent) {
                      this.customer = resCurrent as Customer;
                      if (request.checkout) { // Here we HAVE to take the current user's as they have logged on in the checkout so expect to keep their items
                        this.subscriptions.push(this.switchCarts(this.customer, result.customer).subscribe({
                          next: (resSwap) => {
                            this.spinner.hide();
                            this.customer = resSwap as Customer;
                            result.customer = this.customer;
                            this.customerService.saveCustomerCookie(this.customer);
                            this.klaviyoService.identify(this.customer);
                            observer.next(result);
                            observer.complete();
                          },
                          error: (e) => {
                            this.spinner.hide();
                            observer.next(result);
                            observer.complete();
                          }
                        }));
                      } else {
                        // Merge the carts ... in an intelligent way
                        this.subscriptions.push(this.mergeCarts(this.customer, result.customer).subscribe({
                          next: (resMerge) => {
                            this.spinner.hide();
                            this.customer = resMerge as Customer;
                            result.customer = this.customer;
                            this.customerService.saveCustomerCookie(this.customer);
                            this.klaviyoService.identify(this.customer);
                            observer.next(result);
                            observer.complete();
                          },
                          error: (e) => {
                            this.spinner.hide();
                            observer.next(result);
                            observer.complete();
                          }
                        }));
                      }
                    } else {
                      observer.next(null);
                      observer.complete();
                    }
                  },
                  error: (e) => {
                    observer.error(e);
                    observer.complete();
                  }
                }));
              } else { // Same Customer Guid so all should be good
                this.spinner.hide();
                this.customer = result.customer;
                this.customerService.saveCustomerCookie(this.customer);
                this.klaviyoService.identify(this.customer);
                observer.next(result);
                observer.complete();
              }
            } else { // Logon Failure
              this.spinner.hide();
              localStorage.removeItem('token');
              this.claimsService.set([]);
              observer.next(result);
              observer.complete();
            }
          } else { // Logon Failure
            this.spinner.hide();
            localStorage.removeItem('token');
            this.claimsService.set([]);
            observer.next(result);
            observer.complete();
          }
        },
        error: (e) => {
          localStorage.removeItem('token');
          this.claimsService.set([]);
          this.spinner.hide();
          const result = new LogonResult();
          result.errors = [];
          result.errors.push('General Server error');
          observer.next(result);
          observer.complete();
        }
      }));
    });
  }

  public getByEmail(email: string): Observable<Customer> {
    this.spinner.show();
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.getData(`Customer/Email/${email}`).subscribe({
        next: (res) => {
          this.spinner.hide();
          this.customer = res as Customer;
          this.customerService.saveCustomerCookie(this.customer);
          observer.next(res as Customer);
          observer.complete();
        },
        error: (e) => {
          this.spinner.hide();
          observer.error(e);
          observer.complete();
        }
      }));
    });
  }

  public reset(request: LogonRequest): Observable<LogonResult> {
    this.spinner.show();
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.update('Account/Reset', request).subscribe({
        next: (res) => {
          this.spinner.hide();
          observer.next(res as LogonResult);
          observer.complete();
        },
        error: (e) => {
          this.spinner.hide();
          const result = new LogonResult();
          result.errors = [];
          result.errors.push('Sorry, could not reset your password. Please try again.');
          observer.next(result);
          observer.complete();
        }
      }));
    });
  }

  public  resetPassword(request: ResetRequest): Observable<ResetResult> {
    this.spinner.show();
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.update('Account/ResetPassword', request).subscribe({
        next: (res) => {
          this.spinner.hide();
          observer.next(res as ResetResult);
          observer.complete();
        },
        error: (e) => {
          this.spinner.hide();
          const result = new ResetResult();
          result.errors = [];
          result.errors.push('Sorry, could not reset your password. Maybe the reset link is not correct or it has expired.');
          observer.next(result);
          observer.complete();
        }
      }));
    });
  }

  public subscribeCustomer(customerGuid: string): Observable<boolean> {
    this.spinner.show();
    return new Observable((observer) => {
      if (!customerGuid) {
        this.spinner.hide();
        observer.next(false);
        observer.complete();
        return;
      }
      this.subscriptions.push(this.repoService.create(`Account/Subscribe/${customerGuid}`, null).subscribe({
        next: (res) => {
          this.spinner.hide();
          observer.next(res as boolean);
          observer.complete();
        },
        error: (e) => {
          this.spinner.hide();
          observer.next(false);
          observer.complete();
        }
      }));
    });
  }

  public isSubscribed(email: string): Observable<boolean> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.getData(`Account/Subscribed/${email}`).subscribe({
        next: (res) => {
          observer.next(res as boolean);
          observer.complete();
        },
        error: (e) => {
          observer.next(false);
          observer.complete();
        }
      }));
    });
  }

  public isSubscribedGuid(customerGuid: string): Observable<boolean> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.getData(`Account/SubscribedGuid/${customerGuid}`).subscribe({
        next: (res) => {
          observer.next(res as boolean);
          observer.complete();
        },
        error: (e) => {
          observer.next(false);
          observer.complete();
        }
      }));
    });
  }

  public saveCustomerDetails(request: DetailsRequest): Observable<DetailsResult> {
    this.spinner.show();
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.update('Account/Details', request).subscribe({
        next: (res) => {
          this.spinner.hide();
          observer.next(res as DetailsResult);
          observer.complete();
        },
        error: (e) => {
          this.spinner.hide();
          const message = 'Sorry we could not update your customer account, please try again later';
          this.snackBar.open(message, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 3000 });
        }
      }));
    });
  }

  public changePassword(request: PasswordRequest): Observable<PasswordResult> {
    this.spinner.show();
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.update('Account/Password', request).subscribe({
        next: (res) => {
          this.spinner.hide();
          observer.next(res as PasswordResult);
          observer.complete();
        },
        error: (e) => {
          const message = 'Sorry we could not update your password, please try again later';
          this.snackBar.open(message, 'X', { panelClass: ['error'], verticalPosition: 'top', duration: 3000 });
          observer.error(e);
          observer.complete();
        }
      }));
    });
  }

  public refresh(token: string, customerGuid: string): Observable<RefreshResponse> {
    return new Observable((observer) => {
      try {
        if (!customerGuid) {
          localStorage.removeItem('token');
          this.claimsService.set([]);
          observer.next(null);
          observer.complete();
          return;
        }
        this.subscriptions.push(this.repoService.create(`Account/Refresh/${customerGuid}`, token).subscribe({
          next: (res) => {
            const refreshResult = res as RefreshResponse;
            if (refreshResult?.jwtToken) {
              localStorage.setItem('token', refreshResult.jwtToken);
              this.claimsService.set([]);
            } else {
              localStorage.removeItem('token');
              this.claimsService.set([]);
            }
            observer.next(res as RefreshResponse);
            observer.complete();
          },
          error: (e) => {
            localStorage.removeItem('token');
            this.claimsService.set([]);
            observer.error(e);
            observer.complete();
          }
        }));
      }
      catch (e) {
        observer.error(e);
        observer.complete();
      }
    });
  }

  public isGuest(customerGuid: string): Observable<boolean> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.getData(`Account/IsGuest/${customerGuid}`).subscribe({
        next: (res) => {
          observer.next(res as boolean);
          observer.complete();
        },
        error: (e) => {
          observer.next(false);
          observer.complete();
        }
      }));
    });
  }

  public isRegistered(email: string, reCaptchaToken: string): Observable<boolean> {
    return new Observable((observer) => {
      const request = new RegRequest();
      request.email = email;
      request.token = reCaptchaToken;
      this.subscriptions.push(this.repoService.create('Account/Registered', request).subscribe({
        next: (res) => {
          observer.next(res as boolean);
          observer.complete();
        },
        error: (e) => {
          observer.error(e);
          observer.complete();
        }
      }));
    });
  }

  private switchCarts(currentUser: Customer, loggedOnUser: Customer): Observable<Customer> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.update(`Cart/Empty/${loggedOnUser.customerGuid}`, loggedOnUser.cartList).subscribe({
        next: (emptyRes) => {
          const emptyCustomer = emptyRes as Customer;
          if (emptyCustomer.cartList.length === 0) {
            this.subscriptions.push(this.repoService.update(`Cart/All/${loggedOnUser.customerGuid}`, currentUser.cartList).subscribe({
              next: (fillRes) => {
                observer.next(fillRes as Customer);
                observer.complete();
              },
              error: (e) => {
                observer.error(e);
                observer.complete();
              }
            }));
          } else {
            observer.next(null);
            observer.complete();
          }
        },
        error: (e) => {
          observer.error(e);
          observer.complete();
        }
      }));
    });
  }

  private mergeCarts(currentUser: Customer, loggedOnUser: Customer): Observable<Customer> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.update(`Cart/Merge/${loggedOnUser.customerGuid}`, currentUser.cartList).subscribe({
        next: (mergeRes) => {
          observer.next(mergeRes as Customer);
          observer.complete();
        }
      }));
    });
  }
}
