export const environment = {
  versionCheck: 5,
  versionCheckUrl: 'https://www.elfa.com.au/version.json',
  renderCheckUrl: 'https://www.elfa.com.au/render.json',
  storeId: 1,
  rootCatId: 1,
  pageSize: 40,
  currencyCode: "AUD",
  megaMenu: true,
  manufacturers: true,
  production: true,
  frontEnd: 'https://www.elfa.com.au',
  searchView: 'list',
  productView: 'gridSmall',
  productColumns: 20,
  productColumnsMobile: 50,
  topMenuBackground: 'rgba(39, 39, 41, 1.0)', // For Sticky Top Nav
  topMenuStaticBackground: 'rgb(39, 39, 41, 0.8)', // For Sticky Top Nav
  endPoint: 'https://fg.elfa.com.au',
  endPointBackup: 'https://api.elfa.com.au',
  staticEndPoint: 'https://fg.elfa.com.au',
  searchEndPoint: 'https://fg.elfa.com.au',
  eWayKey: '',
  reCaptchaKey: '6LdJgL8hAAAAAJActFSyNGTVtGsWtcS_LxQnKUoh', // Omnial-Commerce.com
  klaviyoPreLoad: false,
  klaviyoApiKey: '',
  klayvioActive: false,
  baseUrl: 'https://www.elfa.com.au',
  useCache: true,
  cookieSecure: true,
  gtmId: 'GTM-NDWRDS4',
  gtmActive: true,
  ga3: true,
  ga4: true,
  paymentSandbox: false,
  stripeKey: '',
  devImages: null,
  reviewCount: 5,
  customerCookieKey: '_omnial_ELFA_Customer',
  filterTimer: 2000,
  facebookActive: true,
  facebookChat: false,
  facebookAppId: '4643316489064968',
  facebookPageId: ''
};
