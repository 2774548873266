import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { DataFilter } from 'src/@omnial/_models/catalog/data-filter.model';
import { PaginationService } from './pagination.service';

@Injectable({
  providedIn: 'root'
})
export class RepositoryService implements OnDestroy {
  private subscriptions: Subscription[] = [];
  constructor(private http: HttpClient,
    private paginationService: PaginationService,
    private cookieService: CookieService) { }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe(); });
    }
  }

  public create(route: string, body: any): Observable<any> {
    return new Observable((observer) => {
      this.subscriptions.push(this.http.post(this.createCompleteRoute(route, environment.endPoint), body, this.generateHeaderOptions(environment.endPoint)).subscribe({
        next: (result: any) => {
          observer.next(result);
          observer.complete();
        },
        error: () => {
          this.subscriptions.push(this.http.post(this.createCompleteRoute(route, environment.endPointBackup), body, this.generateHeaderOptions(environment.endPointBackup)).subscribe({
            next: (result: any) => {
              observer.next(result);
              observer.complete();
            },
            error: (e: HttpErrorResponse) => {
              observer.error(e?.error);
              observer.complete();
            }
          }));
        }
      }));
    });
  }

  public update(route: string, body: any): Observable<any> {
    return new Observable((observer) => {
      this.subscriptions.push(this.http.put(this.createCompleteRoute(route, environment.endPoint), body, this.generateHeaderOptions(environment.endPoint)).subscribe({
        next: (result: any) => {
          observer.next(result);
          observer.complete();
        },
        error: () => {
          this.subscriptions.push(this.http.put(this.createCompleteRoute(route, environment.endPointBackup), body, this.generateHeaderOptions(environment.endPointBackup)).subscribe({
            next: (result: any) => {
              observer.next(result);
              observer.complete();
            },
            error: (e: HttpErrorResponse) => {
              observer.error(e?.error);
              observer.complete();
            }
          }));
        }
      }));
    });
  }

  public delete(route: string): Observable<any> {
    return new Observable((observer) => {
      this.subscriptions.push(this.http.delete(this.createCompleteRoute(route, environment.endPoint), this.generateHeaderOptions(environment.endPoint)).subscribe({
        next: (result: any) => {
          observer.next(result);
          observer.complete();
        },
        error: () => {
          this.subscriptions.push(this.http.delete(this.createCompleteRoute(route, environment.endPointBackup), this.generateHeaderOptions(environment.endPointBackup)).subscribe({
            next: (result: any) => {
              observer.next(result);
              observer.complete();
            },
            error: (e: HttpErrorResponse) => {
              observer.error(e?.error);
              observer.complete();
            }
          }));
        }
      }));
    });
  }

  public getData(route: string): Observable<any> {
    try {
      return new Observable((observer) => {
        this.subscriptions.push(this.http.get(this.createCompleteRoute(route, environment.endPoint), this.generateHeaderOptions(environment.endPoint)).subscribe({
          next: (result: any) => {
            observer.next(result);
            observer.complete();
          },
          error: () => {
            this.subscriptions.push(this.http.get(this.createCompleteRoute(route, environment.endPointBackup), this.generateHeaderOptions(environment.endPointBackup)).subscribe({
              next: (result: any) => {
                observer.next(result);
                observer.complete();
              },
              error: (e: HttpErrorResponse) => {
                observer.error(e?.error);
                observer.complete();
              }
            }));
          }
        }));
      });
    }
    catch {
      console.log(`Error getting ${route}`);
      return null;
    }
  }

  public getDataWithQuery(route: string, params: HttpParams): Observable<any> {
    if (params !== undefined) {
      route += '?' + params.toString();
    }
    return this.getData(route);
  }

  public getDataWithParams(route: string, params: HttpParams): Observable<any> {
    return new Observable((observer) => {
      this.subscriptions.push(this.http.get(this.createCompleteRoute(route, environment.endPoint), { headers: this.generateHeaders(environment.endPoint), params }).subscribe({
        next: (result: any) => {
          observer.next(result);
          observer.complete();
        },
        error: () => {
          this.subscriptions.push(this.http.get(this.createCompleteRoute(route, environment.endPointBackup), { headers: this.generateHeaders(environment.endPointBackup), params }).subscribe({
            next: (result: any) => {
              observer.next(result);
              observer.complete();
            },
            error: (e: HttpErrorResponse) => {
              observer.error(e?.error);
              observer.complete();
            }
          }));
        }
      }));
    });
  }

  public getPageParams(filters: DataFilter[]): any {
    var obj = {};
    filters.forEach(function (filter) {
      obj[filter.filterKey] = filter.filterValue;
    });
    obj['pageIndex'] = this.paginationService.pageIndex;
    obj['pageSize'] = this.paginationService.pageSize;
    return obj;
  }

  public getPagedData(route: string, filters: DataFilter[]): Observable<HttpResponse<any>> {
    route += `?pageIndex=${this.paginationService.pageIndex}`;
    route += `&pageSize=${this.paginationService.pageSize}`;
    if (filters !== undefined) {
      filters.forEach((filter) => {
        if (filter !== undefined) {
          route += `&${filter.filterKey}=${filter.filterValue}`;
        }
      });
    }
    return this.http.get(this.createCompleteRoute(route, environment.endPoint), { observe: 'response' });
  }

  public updateData(route: string, body: any): Observable<any> {
    return new Observable((observer) => {
      this.subscriptions.push(this.http.put(this.createCompleteRoute(route, environment.endPoint), body, this.generateHeaderOptions(environment.endPoint)).subscribe({
        next: (result: any) => {
          observer.next(result);
          observer.complete();
        },
        error: () => {
          this.subscriptions.push(this.http.put(this.createCompleteRoute(route, environment.endPointBackup), body, this.generateHeaderOptions(environment.endPointBackup)).subscribe({
            next: (result: any) => {
              observer.next(result);
              observer.complete();
            },
            error: (e: HttpErrorResponse) => {
              observer.error(e?.error);
              observer.complete();
            }
          }));
        }
      }));
    });
  }

  public getFile(route: string): Observable<any> {
    return new Observable((observer) => {
      this.subscriptions.push(this.http.get<Blob>(this.createCompleteRoute(route, environment.endPoint), { headers: this.generateHeaders(environment.endPoint), responseType: 'blob' as 'json' }).subscribe({
        next: (result: any) => {
          observer.next(result);
          observer.complete();
        },
        error: () => {
          this.subscriptions.push(this.http.get<Blob>(this.createCompleteRoute(route, environment.endPointBackup), { headers: this.generateHeaders(environment.endPoint), responseType: 'blob' as 'json' }).subscribe({
            next: (result: any) => {
              observer.next(result);
              observer.complete();
            },
            error: (e: HttpErrorResponse) => {
              observer.error(e?.error);
              observer.complete();
            }
          }));
        }
      }));
    });
  }

  public getFilteredFile(route: string, filters: DataFilter[]): Observable<Blob> {
    route += '?';
    if (filters !== undefined) {
      filters.forEach((filter) => {
        if (filter !== undefined) {
          route += `&${filter.filterKey}=${filter.filterValue}`;
        }
      });
    }
    return this.http.get<Blob>(this.createCompleteRoute(route, environment.endPoint), { headers: this.generateHeaders(environment.endPoint), responseType: 'blob' as 'json' });
  }

  public getFileWithPostPayload(route: string, payload: any): Observable<Blob> {
    return new Observable((observer) => {
      this.subscriptions.push(this.http.post<Blob>(this.createCompleteRoute(route, environment.endPoint), payload, { headers: this.generateHeaders(environment.endPoint), responseType: 'blob' as 'json' }).subscribe({
        next: (result: any) => {
          observer.next(result);
          observer.complete();
        },
        error: () => {
          this.subscriptions.push(this.http.post<Blob>(this.createCompleteRoute(route, environment.endPointBackup), payload, { headers: this.generateHeaders(environment.endPointBackup), responseType: 'blob' as 'json' }).subscribe({
            next: (result: any) => {
              observer.next(result);
              observer.complete();
            },
            error: (e) => {
              observer.error(e?.error);
              observer.complete();
            }
          }));
        }
      }));
    });
  }

  public getFileWithParams(route: string, params: HttpParams): Observable<Blob> {
    return new Observable((observer) => {
      this.subscriptions.push(this.http.post<Blob>(this.createCompleteRoute(route, environment.endPoint), params, { headers: this.generateHeaders(environment.endPoint), responseType: 'blob' as 'json' }).subscribe({
        next: (result: any) => {
          observer.next(result);
          observer.complete();
        },
        error: () => {
          this.subscriptions.push(this.http.post<Blob>(this.createCompleteRoute(route, environment.endPointBackup), params, { headers: this.generateHeaders(environment.endPointBackup), responseType: 'blob' as 'json' }).subscribe({
            next: (result: any) => {
              observer.next(result);
              observer.complete();
            },
            error: (e) => {
              observer.error(e?.error);
              observer.complete();
            }
          }));
        }
      }));
    });
  }

  private createCompleteRoute = (route: string, envAddress: string) => {
    return `${envAddress}/${route}`;
  }

  private generateHeaderOptions = (endPoint: string) => {
    if (localStorage.getItem('token') != null) {
      return {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': endPoint,
          'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
          'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, content-type',
          'omnial': this.omnialHeader()
        })
      };
    } else {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': endPoint,
          'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
          'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, content-type',
          'omnial': this.omnialHeader()
        })
      };
    }
  }

  public generateHeaders = (endPoint: string) => {
    if (localStorage.getItem('token') != null) {
      return new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': endPoint,
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, content-type',
        'omnial': this.omnialHeader()
      });
    } else {
      return new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': endPoint,
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, content-type',
        'omnial': this.omnialHeader()
      });
    }
  }

  public generateFileHeaders = (endPoint: string) => {
    if (localStorage.getItem('token') != null) {
      return new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        'Access-Control-Allow-Origin': endPoint,
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, content-type',
        'omnial': this.omnialHeader()
      });
    } else {
      return new HttpHeaders({
        'Access-Control-Allow-Origin': endPoint,
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, content-type',
        'omnial': this.omnialHeader()
      });
    }
  }

  public omnialHeader(): string {
    let customerGuid = this.getCustomerGuid();
    if (!customerGuid) {
      customerGuid = null;
    }
    const omnialValues = {
      'cacheWorker': false,
      'storeId': environment.storeId,
      'customerGuid': customerGuid,
      'pageSize': environment.pageSize,
      'topLevelCatId': environment.rootCatId,
      'includeManufacturers': environment.manufacturers,
      'currencyCode': environment.currencyCode
    };
    return JSON.stringify(omnialValues);
  }

  public getCustomerGuid(): string {
    try {
      if (this.cookieService.check(environment.customerCookieKey)) {
        localStorage.setItem(environment.customerCookieKey, this.cookieService.get(environment.customerCookieKey));
        return this.cookieService.get(environment.customerCookieKey);
      } else {
        setTimeout(() => {
          if (this.cookieService.check(environment.customerCookieKey)) {
            localStorage.setItem(environment.customerCookieKey, this.cookieService.get(environment.customerCookieKey));
          }
        }, 500);
        return localStorage.getItem(environment.customerCookieKey);
      }
    } catch (e) {
      return '';
    }
  }
}
